import store from '@/store';
import { ViewMode } from '@/store/modules/ui/ui.store';
import { RouteConfig } from 'vue-router';

const productionRoutes: RouteConfig[] = [
  {
    path: '/',
    name: 'main',
    component: () => import(
      /* webpackChunkName: "Main" */
      '@/pages/Main/Main.page.vue'
    )
  },
  {
    path: '/details/:id',
    name: 'details',
    component: () => import(
      /* webpackChunkName: "Details" */
      '@/pages/Details/Details.page.vue'
    ),
  },
  {
    path: '/media-preview/:id',
    name: 'media-preview',
    component: () => import(
      /* webpackChunkName: "Media preview" */
      '@/pages/MediaPreview/MediaPreview.page.vue'
    ),
  },
  {
    path: '/publisher/:id',
    name: 'publisher',
    component: () => import(
      /* webpackChunkName: "Publisher" */
      '@/pages/Publisher/Publisher.page.vue'
    ),
  },
  {
    path: '/publishers',
    name: 'publishers',
    component: () => import(
      /* webpackChunkName: "Publishers" */
      '@/pages/Publishers/Publishers.page.vue'
    ),
  },
  {
    path: '/source/:id',
    name: 'source',
    component: () => import(
      /* webpackChunkName: "Source" */
      '@/pages/Source/Source.page.vue'
    ),
  },
  {
    path: '/search/:pageNumber?',
    name: 'search',
    component: () => import(
      /* webpackChunkName: "Search" */
      '@/pages/Search/Search.page.vue'
    ),
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(
      /* webpackChunkName: "About" */
      '@/pages/About/About.page.vue'
    ),
  },
  {
    path: '/imprint',
    name: 'imprint',
    component: () => import(
      /* webpackChunkName: "Imprint" */
      '@/pages/Imprint/Imprint.page.vue'
    ),
  },
  {
    path: '/data-protection',
    name: 'dataProtection',
    component: () => import(
      /* webpackChunkName: "DataProtection" */
      '@/pages/DataProtection/DataProtection.page.vue'
    ),
  },
  {
    path: '/termsofuse',
    name: 'termsofuse',
    component: () => import(
      /* webpackChunkName: "TermsOfUse" */
      '@/pages/TermsOfUse/TermsOfUse.page.vue'
    ),
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import(
      /* webpackChunkName: "FAQ" */
      '@/pages/FAQ/FAQ.page.vue'
    ),
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(
      /* webpackChunkName: "Contact" */
      '@/pages/Contact/Contact.page.vue'
    ),
  },
  {
    path: '/suggest',
    component: () => import(
      /* webpackChunkName: "SuggestPage" */
      '@/pages/Suggest/Suggest.page.vue'
    ),
    children: [
      {
        path: '',
        name: 'suggest',
        component: () => import(
          /* webpackChunkName: "SuggestMain" */
          '@/pages/Suggest/Suggest.main.vue'
        )
      },
      {
        path: 'record',
        name: 'suggest-record',
        component: () => import(
          /* webpackChunkName: "SuggestRecord" */
          '@/pages/Suggest/Suggest.record.vue'
        )
      },
      {
        path: 'add',
        name: 'suggest-add',
        component: () => import(
          /* webpackChunkName: "SuggestAdd" */
          '@/pages/Suggest/Suggest.add.vue'
        ),
      },
    ]
  },
  {
    path: '/entry',
    component: () => import(
      /* webpackChunkName: "EntryPage" */
      '@/pages/Entry/Entry.page.vue'
    ),
    children: [
      {
        path: '',
        name: 'signin',
        component: () => import(
          /* webpackChunkName: "EntrySignin" */
          '@/pages/Entry/Entry.signin.vue'
        ),
        meta: {
          step: 1,
          feature: { name: 'entry' }
        }
      },
      {
        path: 'signin-login',
        name: 'signin-login',
        component: () => import(
          /* webpackChunkName: "EntrySigninLogin" */
          '@/pages/Entry/Entry.signin.login.vue'
        ),
        meta: {
          step: 2,
          feature: { name: 'entry' }
        }
      },
      {
        path: 'password-forgot',
        name: 'password-forgot',
        component: () => import(
          /* webpackChunkName: "EntryPasswordForgot" */
          '@/pages/Entry/Entry.password.forgot.vue'
          ),
        meta: {
          step: 3,
          feature: { name: 'entry' }
        }
      },
      {
        path: 'password-reset',
        name: 'password-reset',
        component: () => import(
          /* webpackChunkName: "EntryPasswordReset" */
          '@/pages/Entry/Entry.password.reset.vue'
          ),
        meta: {
          step: 4,
          feature: { name: 'entry' }
        }
      },
      {
        path: 'signup',
        name: 'signup',
        component: () => import(
          /* webpackChunkName: "EntrySignup" */
          '@/pages/Entry/Entry.signup.vue'
        ),
        meta: {
          step: 2,
          feature: { name: 'entry' }
        }
      },
      {
        path: 'signup-success',
        name: 'signup-success',
        component: () => import(
          /* webpackChunkName: "EntrySignupSuccess" */
          '@/pages/Entry/Entry.signup.success.vue'
        ),
        meta: {
          step: 3,
          feature: { name: 'entry' }
        }
      },
      {
        path: 'confirm-email',
        name: 'confirm-email',
        component: () => import(
          /* webpackChunkName: "EntryConfirmEmail" */
          '@/pages/Entry/Entry.confirm.email.vue'
        ),
        meta: {
          feature: { name: 'entry' }
        }
      },
      {
        path: 'confirm-change-email',
        name: 'confirm-change-email',
        component: () => import(
          /* webpackChunkName: "EntryConfirmChangeEmail" */
          '@/pages/Entry/Entry.confirm.changeEmail.vue'
        ),
        meta: {
          feature: { name: 'entry' }
        }
      }
    ]
  },
  {
    path: '/user',
    component: {
      render(c) { return c('router-view'); },
    },
    children: [
      {
        path: '',
        name: 'user',
        component: () => import(
          /* webpackChunkName: "User" */
          '@/pages/User/User.vue'
        ),
        meta: {
          auth: true,
          feature: { name: 'user' }
        }
      },
      {
        path: 'welcome',
        name: 'user-welcome',
        component: () => import(
          /* webpackChunkName: "UserWelcome" */
          '@/pages/User/User.welcome.vue'
        ),
        meta: {
          auth: true,
          feature: { name: 'user' }
        }
      },
      {
        path: 'edit',
        component: {
          render(c) { return c('router-view'); }
        },
        children: [
          {
            path: '',
            name: 'user-edit',
            component: () => import(
              /* webpackChunkName: "UserEdit" */
              '@/pages/User/User.edit.vue'
            ),
            meta: {
              auth: true,
              feature: { name: 'user' }
            },
          },
          {
            path: 'login',
            name: 'user-edit-login',
            component: () => import(
              /* webpackChunkName: "UserEditLogin" */
              '@/pages/User/User.edit.login.vue'
            ),
            meta: {
              auth: true,
              feature: { name: 'user' }
            },
          }
        ]
      },
      {
        path: 'records',
        component: {
          render(c) { return c('router-view'); }
        },
        children: [
          {
            path: '',
            name: 'user-records',
            component: () => import(
              /* webpackChunkName: "UserRecords" */
              '@/pages/UserRecords/User.records.vue'
            ),
            meta: {
              auth: true,
              feature: { name: 'user' }
            },
          },
          {
            path: 'add',
            name: 'user-record-add',
            component: () => import(
              /* webpackChunkName: "UserRecord" */
              '@/pages/UserRecords/User.records.record.vue'
            ),
            meta: {
              auth: true,
              feature: { name: 'user' }
            },
          },
          {
            path: 'edit/:id',
            name: 'user-record-edit',
            component: () => import(
              /* webpackChunkName: "UserRecord" */
              '@/pages/UserRecords/User.records.record.vue'
            ),
            meta: {
              auth: true,
              feature: { name: 'user' }
            },
          }
        ]
      },
      {
        path: 'bookmarks',
        component: () => import(
          /* webpackChunkName: "UserBookmarks" */
          '@/pages/UserBookmarks/User.bookmarks.vue'
        ),
        children: [
          {
            path: ':id?',
            name: 'user-bookmarks',
            props: true,
            component: () => import(
              /* webpackChunkName: "UserBookmarks" */
              '@/pages/UserBookmarks/User.bookmarks.content.vue'
            ),
            meta: {
              auth: true,
              feature: { name: 'bookmarks' }
            },
          },
          {
            path: ':id/:edit',
            name: 'user-bookmarks-edit',
            props: true,
            component: () => import(
              /* webpackChunkName: "UserBookmarks" */
              '@/pages/UserBookmarks/User.bookmarks.content.vue'
            ),
            meta: {
              auth: true,
              feature: { name: 'bookmarks' }
            },
          }
        ]
      }
    ]
  },
  {
    path: '/profile/:id',
    name: 'user-public',
    component: () => import(
      /* webpackChunkName: "UserPublic" */
      '@/pages/User/User.public.vue'
    ),
    meta: {
      auth: false,
      feature: { name: 'user' }
    }
  },
  {
    path: '/bookmark/:id',
    name: 'bookmark-public',
    props: true,
    component: () => import(
      /* webpackChunkName: "BookmarksPublic" */
      '@/pages/UserBookmarks/User.bookmarks.public.vue'
    ),
    meta: {
      auth: false,
      feature: { name: 'bookmarks' }
    },
  },
  {
    path: '/lti-details/:id',
    name: 'lti-details',
    component: () => import(
      /* webpackChunkName: "Details" */
      '@/pages/Details/Details.page.vue'
    ),
    beforeEnter: (to, from, next) => {
      store.dispatch('switchViewMode', ViewMode.LTI)
        .then(() => next());
    }
  },
  {
    path: '/lti-search/:pageNumber?',
    name: 'lti-search',
    component: () => import(
      /* webpackChunkName: "Search" */
      '@/pages/Search/Search.page.vue'
    ),
    beforeEnter: (to, from, next) => {
      store.dispatch('switchViewMode', ViewMode.LTI)
        .then(() => next());
    }
  },
  {
    path: '/lti-media/:id',
    name: 'lti-media',
    component: () => import(
      /* webpackChunkName: "MediaViewer" */
      '@/pages/MediaViewer/MediaViewer.vue'
    ),
    beforeEnter: (to, from, next) => {
      store.dispatch('switchViewMode', ViewMode.LTI)
        .then(() => next());
    },
    meta: {
      layout: 'frame'
    }
  },
  {
    path: '/export-details/:id',
    name: 'export-details',
    component: () => import(
      /* webpackChunkName: "Details" */
      '@/pages/Details/Details.page.vue'
    ),
    beforeEnter: (to, from, next) => {
      store.dispatch('switchViewMode', ViewMode.EXPORT)
        .then(() => next());
    }
  },
  {
    path: '/export-search/:pageNumber?',
    name: 'export-search',
    component: () => import(
      /* webpackChunkName: "Search" */
      '@/pages/Search/Search.page.vue'
    ),
    beforeEnter: (to, from, next) => {
      store.dispatch('switchViewMode', ViewMode.EXPORT)
        .then(() => next());
    }
  },
  {
    path: '/cms/:id',
    name: 'cms',
    component: () => import(
      /* webpackChunkName: "CmsStaticPage" */
      '@/pages/Cms/Cms.page.vue'
    ),
    meta: {
      analyticsIgnore: true
    }
  },
  {
    path: '*',
    name: 'not-found',
    props: (route) => ({ errorCode: route.params.errorCode || 404 }),
    component: () => import(
      /* webpackChunkName: "ErrorPage" */
      '@/pages/Error/Error.page.vue'
    ),
    meta: {
      analyticsIgnore: true
    }
  },
];

export default productionRoutes;
