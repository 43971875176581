import { ActionTree } from 'vuex';
import { RootState } from '@/store';
import { CookieService } from '@/services/data/cookie.service';

export enum CookieConsentStatus {
  ALLOW = 'allow',
  DENY = 'deny',
}

export interface IAnalyticState {
  isCookieBanner: boolean;
}

const initialState = (): IAnalyticState => ({
  isCookieBanner: false,
});
const state = initialState();

const getters = {
  isConsentMatomo: () => {
    return CookieService.get('cookieConsentStatus') === CookieConsentStatus.ALLOW;
  },
  isCookieBanner: (state: IAnalyticState) => state.isCookieBanner
};

const mutations = {
  ['SET_IS_COOKIE_BANNER'](state: IAnalyticState, payload: boolean) {
    state.isCookieBanner = payload;
  },
};

const actions: ActionTree<IAnalyticState, RootState> = {
  showCookieBanner({commit}, payload) {
    commit('SET_IS_COOKIE_BANNER', payload);
  },
};

export default {
  state,
  getters,
  mutations,
  actions
};
