



























import { Vue, Component, Prop } from 'vue-property-decorator';
import CloseIcon from '@/assets/icons/close.svg';
import { BModal } from 'bootstrap-vue';

@Component({
  components: { CloseIcon, BModal }
})
export default class Modal extends Vue {
  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  public visible!: boolean;

  @Prop({
    type: String,
    required: false,
    default: ''
  })
  private returnFocus!: string;

  @Prop({
    type: String,
    required: false,
    default: ''
  })
  private footerClass!: string;

  public closeModal(): void {
    this.$emit('close-modal');
  }

  public onHide(evt: any): void {
    if (evt.trigger === 'backdrop') {
      evt.preventDefault();
      this.closeModal();
    }
  }
}
