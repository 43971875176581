import { api } from '@/services/api/api.service';
import store from '@/store';
import { AxiosResponse } from 'axios';

export interface IFileData {
  mediaUrl: string;
  fileName: string;
  mediaType: string;
}

export interface IUploadApiService {
  uploadUserPhoto: (file: FormData) => Promise<string>;
  uploadUserRecordFile: (file: FormData, progress?: boolean, controller?: AbortController) => Promise<IFileData>;
}

const uploadUserPhoto = (file: FormData): Promise<string> => api
  .post('mundo/storage/photo', file)
  .then((response: AxiosResponse<Promise<string>>) => response.data)
  .catch((error) => Promise.reject(error));

const uploadUserRecordFile = (
  file: FormData, 
  progress?: boolean, 
  controller?: AbortController
): Promise<IFileData> => api
  .post('suggest/file/upload', file, {
    signal: controller ? controller.signal : undefined,
    onUploadProgress: (progressEvent) => {
      if (!progress) { return; }
      const percentCompleted = () => Math.round((progressEvent.loaded * 100) / progressEvent.total);
      store.commit('SET_USER_RECORD_FILE_UPLOAD_PROGRESS', 
        percentCompleted() === 100 ? 99 : percentCompleted()
      );
    }
  })
  .then((response: AxiosResponse<Promise<IFileData>>) => response.data)
  .catch((error) => Promise.reject(error));

const UploadApiService: IUploadApiService = {
  uploadUserPhoto,
  uploadUserRecordFile,
};

export default UploadApiService;
