









import { Vue, Component} from 'vue-property-decorator';
import {createHelpers} from 'vuex-map-fields';

import ErrorPage from '@/pages/Error/Error.page.vue';
import { ErrorCode } from '@/store/modules/ui/ui.store';

const { mapFields } = createHelpers({
  getterType: 'getUiField',
  mutationType: 'updateUiField',
});

@Component({
  components: {
    ErrorPage
  },
  computed: {...mapFields([ 'errorCode' ])},
})
export default class DefaultLayout extends Vue {
  private errorCode!: null | ErrorCode;

  public get scrollbarOptions() {
    return {
      className: 'os-theme-primary',
      overflowBehavior: {
        x: 'hidden',
        y: this.$store.getters.getAppScrollStatus
      },
    };
  }
}
