import { ActionTree, GetterTree } from 'vuex';
import { RootState } from '@/store';
import SuggestApiService, { ISuggestMedia } from '@/services/api/suggest.service';
import router from '@/router';

export interface ISuggestState {
  suggestMedia: ISuggestMedia | null;
}

const initialState = (): ISuggestState => ({
  suggestMedia: null
});

const state = initialState();

const getters: GetterTree<ISuggestState, RootState> = {
  getSuggestMedia: (state: ISuggestState) => state.suggestMedia ? state.suggestMedia : null
};

const mutations = {
  ['SET_SUGGEST_MEDIA'](state: ISuggestState, payload: ISuggestMedia) {
    state.suggestMedia = payload;
  },
  ['RESET_SUGGEST_MEDIA'](state: ISuggestState) {
    state.suggestMedia = null;
  },
};

const actions: ActionTree<ISuggestState, RootState> = {
  requestSuggestMedia: ({commit}, parameters: ISuggestMedia) => {
    commit('SET_LOADING', {recommendedList: true});
    return SuggestApiService.suggestMedia(parameters)
      .then((data) => {
        commit('SET_SUGGEST_MEDIA', data);
        return router.push({name: 'suggest-record'});
      })
      .finally(() => commit('SET_LOADING', {recommendedList: false}));
  },
  resetSuggestMedia: ({commit}) => {
    commit('RESET_SUGGEST_MEDIA');
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
