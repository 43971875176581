















































import { Vue, Component, PropSync, Prop } from 'vue-property-decorator';
import CloseIcon from '@/assets/icons/close.svg';
import UploadIcon from '@/assets/icons/upload.svg';
import SpinIcon from '@/assets/icons/spin.svg';
import UploadApiService from '@/services/api/upload.service';
import { ValidationProvider } from 'vee-validate';
import '@/validators/vee-validate.ts';
import { ValidationResult } from 'vee-validate/dist/types/types';

@Component({
  components: {
    UploadIcon,
    CloseIcon,
    SpinIcon,
    ValidationProvider
  }
})
export default class UserAvatar extends Vue {
  private UploadIcon = UploadIcon;
  private CloseIcon = CloseIcon;
  private SpinIcon = SpinIcon;

  private file: File | null = null;
  private loading: boolean = false;

  @PropSync('fileId', { 
    type: String, 
    default: null 
  })
  private id!: string | null;

  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  private readonly!: boolean;

  @Prop({
    type: Number,
    required: false,
    default: 96
  })
  private size!: number;

  @Prop({
    type: String,
    required: false,
    default: ''
  })
  private imageClass!: string;

  private get image() {
    return this.id 
      ? `/api/mundo/storage/${this.id}`
      : null;
  }

  private get style() {
    return {
      '--size': `${this.size}px`
    };
  }

  private setLoading(value: boolean) {
    this.loading = value;
  }

  private reset() {
    this.id = null;
  }

  private upload() {
    if (!this.file) { return; }
    const formdata = new FormData();
    formdata.append('file', this.file!);
    this.setLoading(true);
    UploadApiService.uploadUserPhoto(formdata)
      .then((id) => this.id = id)
      .finally(() => this.setLoading(false));
  }

  private input(file: File, validate: (file: File) => Promise<ValidationResult>) {
    this.file = file;
    validate(this.file).then((result) => {
      if (result.valid) {
        this.upload();
      }
    });
  }
}
