export interface IEducationalDependenciesTreeItem {
  educationalLevels: string;
  typeOfSchools: string;
  classLevel: string[];
}

export interface IEducationalDependenciesPayload {
  educationalLevels: string[];
  typeOfSchools: string[];
  classLevel: string[];
}

export interface IEducationalDependenciesService {
  getData: (tree: IEducationalDependenciesTreeItem[]) => IEducationalDependenciesPayload;
  getAvailableData: (tree: IEducationalDependenciesTreeItem[], payload: IEducationalDependenciesPayload) => IEducationalDependenciesPayload;
  getUpdatedData: (payload: IEducationalDependenciesPayload, deps: IEducationalDependenciesPayload) => IEducationalDependenciesPayload;
}

const getData = (tree: IEducationalDependenciesTreeItem[]): IEducationalDependenciesPayload => {
  return tree
    .reduce((acc, val) => {
      return {
        educationalLevels: [...new Set([...acc.educationalLevels, val.educationalLevels])],
        typeOfSchools: [...new Set([...acc.typeOfSchools, val.typeOfSchools])]
          .filter((item) => item),
        classLevel: [...new Set([...acc.classLevel, ...val.classLevel])]
          .sort((a, b) => Number(a) - Number(b))
      };
    }, { educationalLevels: [], typeOfSchools: [], classLevel: [] } as IEducationalDependenciesPayload);
};

const getAvailableData = (
  tree: IEducationalDependenciesTreeItem[], 
  payload: IEducationalDependenciesPayload
): IEducationalDependenciesPayload => {
  const { educationalLevels, typeOfSchools } = payload;
  return tree
    .reduce((acc, val) => {
      // [EducationalLevels] is a FIRST LEVEL and doesn't depend on other levels
      const getCurrentEducationalLevels = () => [...new Set([...acc.educationalLevels, val.educationalLevels])];

      // [TypeOfSchools] is a SECOND LEVEL and depends on [EducationalLevels]
      const getCurrentTypeOfSchools = () => val.typeOfSchools && educationalLevels.includes(val.educationalLevels)
            ? [...new Set([...acc.typeOfSchools, val.typeOfSchools])]
            : acc.typeOfSchools;

      // [ClassLevel] is a THIRD LEVEL and depends on:
      // 1) [EducationalLevels]
      // 2) [TypeOfSchools] if any of them is selected
      const getCurrentClassLevel = () => typeOfSchools.length
          ? educationalLevels.includes(val.educationalLevels)
            && typeOfSchools.includes(val.typeOfSchools)
              ? [...new Set([...acc.classLevel, ...val.classLevel])]
              : acc.classLevel
          : educationalLevels.includes(val.educationalLevels)
            ? [...new Set([...acc.classLevel, ...val.classLevel])]
            : acc.classLevel;

      return {
        educationalLevels: getCurrentEducationalLevels(),
        typeOfSchools: getCurrentTypeOfSchools(),
        classLevel: getCurrentClassLevel()
      };
    }, { educationalLevels: [], typeOfSchools: [], classLevel: [] } as IEducationalDependenciesPayload);
};

const getUpdatedData = (
  payload: IEducationalDependenciesPayload,
  deps: IEducationalDependenciesPayload
): IEducationalDependenciesPayload => {
  const { educationalLevels, typeOfSchools, classLevel } = payload;
  return {
    educationalLevels: educationalLevels.filter((item) => deps.educationalLevels.includes(item)),
    typeOfSchools: typeOfSchools.filter((item) => deps.typeOfSchools.includes(item)),
    classLevel: classLevel.filter((item) => deps.classLevel.includes(item))
  };
};

const EducationalDependenciesService: IEducationalDependenciesService = {
  getData,
  getAvailableData,
  getUpdatedData
};
export default EducationalDependenciesService;