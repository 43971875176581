

























import { Vue, Watch, Component } from 'vue-property-decorator';
import CloseIcon from '@/assets/icons/close.svg';

@Component({})

export default class Lightbox extends Vue {
  private CloseIcon = CloseIcon;

  private get visible(): boolean {
    return this.$store.getters.lightboxes.length;
  }

  @Watch('$route')
  private onRouteChanged() {
    this.$store.commit('CLOSE_LIGHTBOX');
  }

  private closeLightbox() {
    this.$store.commit('CLOSE_LIGHTBOX');
    this.$store.dispatch('closePdfLoading');
  }
}
