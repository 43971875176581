



























import { Vue, Component } from 'vue-property-decorator';
import { CookieService } from '@/services/data/cookie.service';
import { CookieConsentStatus } from '@/store/modules/analytic.store';
import CookieIllustration from '@/assets/icons/cookie-illustration.svg';
import CloseIcon from '@/assets/icons/close.svg';
import CheckIcon from '@/assets/icons/checkmark.svg';

@Component({
  components: {
    CookieIllustration,
    CloseIcon,
    CheckIcon
  } })
export default class CookieBanner extends Vue {
  public onDeny(): void {
    CookieService.set('cookieConsentStatus', CookieConsentStatus.DENY);
    this.$store.dispatch('showCookieBanner', false);

    // @ts-ignore
    return this.$matomo && this.$matomo.forgetCookieConsentGiven();
  }

  public onAllow(): void {
    CookieService.set('cookieConsentStatus', CookieConsentStatus.ALLOW);
    this.$store.dispatch('showCookieBanner', false);

    // @ts-ignore
    return this.$matomo && this.$matomo.rememberCookieConsentGiven();
  }

  public openDataProtection() {
    const routeData = this.$router.resolve({name: 'dataProtection'});
    window.open(routeData.href, '_blank');
  }
}
