



































import Vue, { VueConstructor } from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import { ICard, ICardInfo } from '@/interfaces/interfaces';
import { getIconByLearnResourceType } from '@/dictionaries/icons/icons.learnResourceType.dictionary';

import CardCollectionCover from './CardCollectionCover.vue';
import CardInfo from '@/components/UI/Card/CardInfo.vue';
import CardBorderBottom from '@/components/UI/Card/CardBorderBottom.vue';
import Chevron from '@/components/UI/Chevron.vue';
import CardActive from '@/components/UI/Card/CardActive.vue';

import ItemsIcon from '@/assets/icons/card/items.svg';
import ProviderIcon from '@/assets/icons/card/provider.svg';

@Component({
  components: {
    CardActive,
    CardCollectionCover,
    CardInfo,
    CardBorderBottom,
    Chevron
  }
})
export default class CardCollection extends Vue {
  private defaultCoverImage = '/images/card-cover.svg';

  @Prop({
    type: Object,
    required: true
  })
  private item!: ICard;

  @Prop({
    type: Boolean,
    required: false
  })
  private inline!: boolean;

  @Prop({
    type: String,
    required: false
  })
  private size!: 'sm' | 'flex';

  @Prop({
    type: Boolean,
    required: false
  })
  private active!: boolean;

  private get learnResourceTypeIcon(): VueConstructor {
    return getIconByLearnResourceType(this.item.learnResourceType![0].toLowerCase());
  }

  private get infoList(): ICardInfo[] {
    return [
      ...(
        this.inline && this.item.learnResourceType![0] === 'sammlung'
          ? [{
              title: this.item.learnResourceType![0],
              icon: this.learnResourceTypeIcon
            } as ICardInfo]
          : []
      ),
      {
        title: 17 + ' Medien',
        icon: ItemsIcon
      },
      ...(
        this.item.publisher
          ? [{
              title: this.item.publisher,
              icon: ProviderIcon
            } as ICardInfo]
          : []
      ),
    ];
  }

  private get classWrapObject(): object {
    return {
      [`${this.size}`]: !!this.size,
      'inline': this.inline,
    };
  }

  private get classObject(): object {
    return {
      'card-collection-inline': this.inline,
    };
  }
}
