type scrollBehavior = 'smooth' | 'auto';

export const scrollTo = (
  selector: string,
  behavior: scrollBehavior = 'auto'
) => {
  const element = document.querySelector(selector);

  if (element !== null) {
    element.scrollIntoView({ behavior });
  }
};
