



























import { Vue, Component, Prop } from 'vue-property-decorator';
import { UISizeType, ICardInfo } from '@/interfaces/interfaces';

import CardThumbnailCover from './CardThumbnailCover.vue';
import CardInfo from '@/components/UI/Card/CardInfo.vue';
import CardBorderBottom from '@/components/UI/Card/CardBorderBottom.vue';

import ItemsIcon from '@/assets/icons/card/items.svg';
import ProviderIcon from '@/assets/icons/card/provider.svg';

@Component({
  components: {
    CardThumbnailCover,
    CardInfo,
    CardBorderBottom
  },
})
export default class CardThumbnail extends Vue {
  private defaultCoverImage = '/images/card-cover.svg';

  @Prop({
    type: String,
    required: false,
    default: ''
  })
  private name!: string;

  @Prop({
    type: String,
    required: false,
    default: ''
  })
  private imageUrl!: string;

  @Prop({
    type: String,
    required: false
  })
  private size!: UISizeType;

  @Prop({
    type: Number,
    required: false
  })
  private count!: number;

  @Prop({
    type: String,
    required: false
  })
  private provider!: string;

  @Prop({
    type: String,
    required: false,
    default: ''
  })
  private href!: string;

  private get infoList(): ICardInfo[] {
    return [
      {
        title: this.count + ' Medien',
        icon: ItemsIcon,
        alt: 'Icon: Medien'
      },
      ...(
        this.provider
          ? [{
              title: this.provider,
              icon: ProviderIcon,
              alt: 'Icon: ' + this.provider
            } as ICardInfo]
          : []
      ),
    ];
  }

  private get classObject(): object {
    return {
      [`${this.size}`]: !!this.size,
    };
  }
}
