import { api } from '@/services/api/api.service';
import { AxiosResponse } from 'axios';
import { ISearchResponse } from '@/services/api/search.service';

export interface ISliderApiService {
  getRecommendedList: (mediaIds: string[]) => Promise<ISearchResponse>;
}

const getRecommendedList = (mediaIds: string[]): Promise<ISearchResponse> => api
  .post(
    'mundo/recommended',
    mediaIds
  )
  .then((response: AxiosResponse<Promise<ISearchResponse>>) => response.data)
  .catch((error) => Promise.reject(error));

const SliderApiService: ISliderApiService = {
  getRecommendedList
};

export default SliderApiService;
