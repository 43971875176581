











import { Vue, Component, Prop } from 'vue-property-decorator';
import { ArrowDirection, IArrowDirection } from '@/interfaces/interfaces';

@Component({})
export default class Chevron extends Vue {
  @Prop({
    type: String,
    required: true,
    default: 'left'
  })
  public direction!: ArrowDirection;

  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  public animated!: boolean;

  public directionAngleDictionary: IArrowDirection<number> = {
    left: 180,
    right: 0,
    up: 270,
    down: 90,
  };

  public get angle(): number {
    return this.directionAngleDictionary[this.direction as ArrowDirection];
  }
}
