const analyticDictionary = {
  production: {
    host: 'https://stats.fwu.de',
    id: 6
  },

  /* Disabled test matomo instance */

  /* 
  test: {
    host: 'https://sodix.matomo.cloud',
    id: 1
  },
  */
};

export const analytic = analyticDictionary['production'];
