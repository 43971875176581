import store from '@/store';
import { IRouterGuard } from '@/interfaces/interfaces';

export interface IMetaGuard { 
  name: string;
  redirect?: string;
  not?: boolean;
}

export const featureGuard = (params: IRouterGuard) => {
  const {from, to, next, nextGuard} = params;

  if (!store.state.features.featuresLoaded) {
    return store.dispatch('setFeatures')
      .then(() => {
        if (to.meta.feature) {
          const { name, redirect = {name: 'not-found'}, not = false } = to.meta.feature as IMetaGuard;
          if (not !== !store.getters.featureEnabled(name)) {
            return next(redirect);
          }
        }
        return nextGuard ? nextGuard({from, to, next}) : next();
      })
      .catch(() => {
        return nextGuard!({from, to, next});
      });
  }
  return nextGuard ? nextGuard({from, to, next}) : next();
};
