import { ActionTree, GetterTree } from 'vuex';
import { RootState } from '@/store';
import CmsApiService, {
  ICmsResponse,
  ICmsResponseImage
} from '@/services/api/cms.service';

export interface IMainSection extends ICmsResponse {
  identificator: MainSectionType;
  title: string;
  subtitle: string;
  text: string;
  image: ICmsResponseImage[] | null;
  imageLink: string;
  buttonTitle: string;
  buttonLink: string;
  buttonVisible: boolean;
}

export type MainSectionType
  = 'head'
  | 'teaser'
  | 'actual'
  | 'media'
  | 'subject'
  | 'recommended'
  | 'collection'
  | 'communicate_up'
  | 'communicate_center'
  | 'communicate_center_user'
  | 'communicate_down'
  | 'communicate_down_user'
  | 'about'
  | 'source';

export enum MainSectionId {
  HEAD = 'head',
  TEASER = 'teaser',
  ACTUAL = 'actual',
  MEDIA = 'media',
  SUBJECT = 'subject',
  RECOMMENDED = 'recommended',
  COLLECTION = 'collection',
  COMMUNICATE_UP = 'communicate_up',
  COMMUNICATE_CENTER = 'communicate_center',
  COMMUNICATE_CENTER_USER = 'communicate_center_user',
  COMMUNICATE_DOWN = 'communicate_down',
  COMMUNICATE_DOWN_USER = 'communicate_down_user',
  ABOUT = 'about',
  SOURCE = 'source'
}

export interface ICmsMainState {
  mainSections: IMainSection[];
}

const initialState = (): ICmsMainState => ({
  mainSections: [],
});

const state = initialState();

const getters: GetterTree<ICmsMainState, RootState> = {
  getMainSection: (state: ICmsMainState) => {
   return (identificator: MainSectionType) => state.mainSections.length
     ? state.mainSections
        .filter((item: IMainSection) => {
          return item.identificator === identificator;
        })[0] || null
     : [];
  }
};

const mutations = {
  ['SET_MAIN_SECTIONS'](state: ICmsMainState, payload: IMainSection[]) {
    state.mainSections = payload;
  },
};

const actions: ActionTree<ICmsMainState, RootState> = {
  getCmsMainSections: ({commit}) => {
    return CmsApiService.getCmsMainSections()
      .then((data: IMainSection[]) => commit('SET_MAIN_SECTIONS', data));
  },
};

export default {
  state,
  getters,
  mutations,
  actions
};
