import { ActionTree, GetterTree } from 'vuex';
import { RootState } from '@/store';
import CmsApiService, { ICmsResponse, ICmsQueryParameters } from '@/services/api/cms.service';
import queryString from 'query-string';

export interface ICmsPage extends ICmsResponse {
  identifier: string;
  title: string;
  subtitle: string;
  text: HTMLElement;
}


export interface ICmsRecommendedMedia extends ICmsResponse {
  order: number;
  identifier: string;
}

export interface ICmsPageQueryParameters extends ICmsQueryParameters {
  identifier: ICmsIdentifierType;
}

export enum CmsPageIdentifier {
  ABOUT = 'about',
  CONTACT = 'contact',
  DATA_PROTECTION = 'data-protection',
  TERMS_OF_USE = 'data-protection',
  FAQ = 'faq',
  IMPRINT = 'imprint',
  PUBLISHERS = 'publishers',
}

export type ICmsIdentifierType
  = 'about'
  | 'contact'
  | 'data-protection'
  | 'termsofuse'
  | 'faq'
  | 'imprint'
  | 'publishers';

export interface ICmsState {
  cmsPage: ICmsPage | null;
  cmsPageIdentifier: ICmsIdentifierType | null;
}

const initialState = (): ICmsState => ({
  cmsPage: null,
  cmsPageIdentifier: null,
});

const state = initialState();

const getters: GetterTree<ICmsState, RootState> = {
  getCmsPage: (state: ICmsState) => state.cmsPage ? state.cmsPage : null,
  isCmsPage: (state: ICmsState, getters) => getters.getCmsPage
    ? state.cmsPageIdentifier === getters.getCmsPage.identifier
    : false,
  getCmsPageTitle: (state: ICmsState) => state.cmsPage ? state.cmsPage.title : '',
};

const mutations = {
  ['SET_CMS_PAGE'](state: ICmsState, payload: ICmsPage) {
    state.cmsPage = payload;
  },
  ['SET_CMS_PAGE_IDENTIFIER'](state: ICmsState, payload: ICmsIdentifierType | null) {
    state.cmsPageIdentifier = payload;
  },
};

const actions: ActionTree<ICmsState, RootState> = {
  getCmsPage: ({commit}, queryParameters: ICmsPageQueryParameters) => {
    commit('SET_LOADING', {cmsPage: true});
    const query = queryString.stringify(queryParameters);
    const { identifier } = queryParameters;

    commit('SET_CMS_PAGE_IDENTIFIER', null);

    return CmsApiService.getCmsPage(query)
      .then((data: ICmsPage[]) => {
        commit('SET_CMS_PAGE', data[0]);
        commit('SET_CMS_PAGE_IDENTIFIER', identifier);
      })
      .finally(() => commit('SET_LOADING', {cmsPage: false}));
  },
  getCmsRecommendedMedia: ({commit}) => {
    commit('SET_LOADING', {cmsRecommendedMedia: true});

    return CmsApiService.getCmsRecommendedMedia()
      .then((data: ICmsRecommendedMedia[]) => {
        return data.length ? data.map((item: ICmsRecommendedMedia) => item)
          : [];
      })
      .finally(() => commit('SET_LOADING', {cmsRecommendedMedia: false}));
  },
};

export default {
  state,
  getters,
  mutations,
  actions
};
