import { ILtiEmbedResponse, IMedia, IMediaRating } from '@/interfaces/interfaces';
import { api } from '@/services/api/api.service';
import {AxiosRequestConfig, AxiosResponse} from 'axios';

export interface IMediaApiService {
  getMediaByUrl: (url: string, config: AxiosRequestConfig) => Promise<IMedia>;
  getMediaById: (id: string) => Promise<IMedia>;
  getMediaPreviewById: (id: string) => Promise<IMedia>;
  getRecommendedMediaById: (id: string) => Promise<Record<string, IMedia[]>>;
  getLinkedMediaByIdList: (idList: string[]) => Promise<Record<string, IMedia[]>>;
  rateMedia: (id: string, value: number) => Promise<IMediaRating>;
  embedMedia: (id: string) => Promise<void>;
}

const getMediaByUrl = (url: string, config: AxiosRequestConfig): Promise<IMedia> => api
  .get(url, config)
  .then((response: AxiosResponse<IMedia>) => response.data)
  .catch((error) => Promise.reject(error));

const getMediaById = (id: string): Promise<IMedia> => api
  .get(`mundo/search/${id}`)
  .then((response: AxiosResponse<IMedia>) => response.data)
  .catch((error) => Promise.reject(error));

const getMediaPreviewById = (id: string): Promise<IMedia> => api
  .get(`mundo/user-suggestion/preview/${id}`)
  .then((response: AxiosResponse<IMedia>) => response.data)
  .catch((error) => Promise.reject(error));

const getLinkedMediaByIdList = (idList: string[]): Promise<Record<string, IMedia[]>> => api
  .post('mundo/search/linkedObjects', idList)
  .then((response: AxiosResponse<Record<string, IMedia[]>>) => response.data)
  .catch((error) => Promise.reject(error));

const getRecommendedMediaById = (id: string): Promise<Record<string, IMedia[]>> => api
  .get(`mundo/search/like/${id}`)
  .then((response: AxiosResponse<Record<string, IMedia[]>>) => response.data)
  .catch((error) => Promise.reject(error));

const rateMedia = (id: string, value: number): Promise<IMediaRating> => api
  .post('mundo/rating', {
    mediaId: id,
    value
  })
  .then((response: AxiosResponse<IMediaRating>) => response.data)
  .catch((error) => Promise.reject(error));

const embedMedia = (id: string) => api
  .post(`mundo/ltimanager/${id}`)
  .then(
    (response: AxiosResponse<ILtiEmbedResponse>) => {
      const {url, payload} = response.data;
      const form = document.createElement('form') as HTMLFormElement;
      form.action = url;
      form.method = 'POST';
      for (const name of Object.keys(payload)) {
        const node = document.createElement('input') as HTMLInputElement;
        node.name = name;
        node.type = 'hidden';
        node.value = payload[name];
        form.appendChild(node);
      }
      document.body.appendChild(form);
      form.submit();
    }
  )
  .catch((error) => Promise.reject(error));

const MediaApiService: IMediaApiService = {
  getMediaByUrl,
  getMediaById,
  getMediaPreviewById,
  getLinkedMediaByIdList,
  getRecommendedMediaById,
  rateMedia,
  embedMedia
};

export default MediaApiService;
