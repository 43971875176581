









































































import Vue, { VueConstructor } from 'vue';
import { Component } from 'vue-property-decorator';

import Logo from '@/assets/icons/logo.svg';
import TwitterIcon from '@/assets/icons/socials/twitter.svg';
import InstagramIcon from '@/assets/icons/socials/instagram.svg';
import LinkedinIcon from '@/assets/icons/socials/linkedin.svg';

interface IListItem {
  title?: string;
  path?: string;
  alt?: string;
  url?: string;
  component?: VueConstructor;
}

@Component({
  components: { Logo }
})
export default class Footer extends Vue {
  private navList: IListItem[] = [
    {
      title: 'Startseite',
      path: '/'
    },
    {
      title: 'Suche',
      path: '/search'
    },
    {
      title: 'Über das Projekt',
      path: '/about'
    },
    {
      title: 'Über unsere Quellen',
      path: '/publishers'
    },
    {
      title: 'Medium vorschlagen',
      path: '/suggest'
    },
    {
      title: 'Hilfe / FAQs',
      path: '/faq'
    },
    {
      title: 'Kontakt',
      path: '/contact'
    },
    // {
    //   title: 'Login / Konto',
    //   path: '#'
    // },
  ];
  private socialList: IListItem[] = [
    {
      title: 'MUNDO bei Twitter',
      alt: 'Twitter-Logo',
      url: 'https://twitter.com/mundoschule?lang=de',
      component: TwitterIcon,
    },
    {
      title: 'MUNDO bei Instagram',
      alt: 'Instagram-Logo',
      url: 'https://www.instagram.com/mundo.schule/',
      component: InstagramIcon,
    },
    {
      title: 'MUNDO bei LinkedIn',
      alt: 'LinkedIn-Logo',
      url: 'https://www.linkedin.com/showcase/mundo-schule/',
      component: LinkedinIcon,
    },
  ];
  private companionList: IListItem[] = [
    {
      title: 'media-institute',
      alt: 'Logo - FWU',
      url: 'https://www.fwu.de'
    },
    {
      title: 'digital-pact',
      alt: 'Logo - Digitalpakt Schule'
    }
  ];
  private partnerList: string[] = [
    'st', 'hb', 'hh', 'ni', 'nw', 'be', 'he', 'sn',
    'bw', 'by', 'mv', 'sl', 'rp', 'th', 'bb', 'sh'
  ];
  private subNavList: IListItem[] = [
    {
      title: 'Datenschutz',
      path: '/data-protection'
    },
    {
      title: 'Impressum',
      path: '/imprint'
    },
  ];
}
