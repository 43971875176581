import { AxiosError } from 'axios';
import router from '@/router';
import store from '@/store';

const errorInterceptor = (error: AxiosError) => {
  if (error.response && error.response.status === 400) {
    return Promise.reject(error);
  }
  
  else if (error.response && error.response.status === 401) {
    store.dispatch('logout').then(() => window.location.replace('/'));
    return Promise.reject(error);
  }
  
  else if (error.response && error.response.status === 409) {
    console.warn(error);
    return;
  }
  
  else if (error.response && error.response.status) {
    router.push({
      name: 'not-found',
      params: {
        errorCode: String(error.response.status)
      }
    });
  }
  return Promise.reject(error);
};

export default errorInterceptor;
