import { ActionTree } from 'vuex';
import { RootState } from '@/store';
import { getField, updateField } from 'vuex-map-fields';
import { IDictionary } from '@/interfaces/interfaces';
// @ts-ignore
import platform from 'platform-detect';
import router from '@/router';

export enum AdaptiveMode {
  XS = 'XS',
  SM = 'SM',
  MD = 'MD',
  LG = 'LG',
  XLG = 'XLG'
}

export enum ViewMode {
  NORMAL = 'NORMAL',
  LTI = 'LTI',
  EXPORT = 'EXPORT'
}

export type ErrorCode = 404 | 500 | 'maintenance';

export type OverlayTuple = [boolean, boolean];

export interface IUiState {
  adaptiveMode: AdaptiveMode;
  viewMode: ViewMode;
  loadingDictionary: IDictionary<boolean>;
  lightboxes: string[];
  isHeaderMenu: boolean;
  filtersVisibility: boolean;
  filtersPanelVisibility: boolean;
  filtersChanged: boolean;
  allowSearch: boolean;
  errorCode: null | ErrorCode;
  viewPort: number;
  isOverlay: OverlayTuple;
  isBlurDisabled: boolean | null;
  isDropdownOpened: boolean;
  entryTransitionName: string | null;
}

export interface ILoadingData {
  [key: string]: boolean;
}

const initialState = (): IUiState => ({
  adaptiveMode: AdaptiveMode.LG,
  viewMode: ViewMode.NORMAL,
  loadingDictionary: {},
  lightboxes: [],
  isHeaderMenu: false,
  filtersVisibility: false,
  filtersPanelVisibility: false,
  filtersChanged: false,
  allowSearch: false,
  errorCode: null,
  viewPort: 0,
  isOverlay: [false, false],
  isDropdownOpened: false,
  isBlurDisabled: null,
  entryTransitionName: null,
});
const state = initialState();

const getters = {
  getUiField: (state: IUiState) => getField(state),
  loadingDictionary: (state: IUiState) => state.loadingDictionary,
  lightboxes: (state: IUiState) => state.lightboxes,
  isLoading: (state: IUiState) => Object.keys(state.loadingDictionary)
    .map((key: string) => state.loadingDictionary[key])
    .some((isLoading: boolean) => isLoading),
  isSmallMobileAdaptiveMode: (state: IUiState) => state.adaptiveMode === AdaptiveMode.XS,
  isMobileAdaptiveMode: (state: IUiState) => {
    return state.adaptiveMode === AdaptiveMode.XS
      || state.adaptiveMode === AdaptiveMode.SM;
  },
  isTabletAdaptiveMode: (state: IUiState) => state.adaptiveMode === AdaptiveMode.MD,
  isDesktopAdaptiveMode: (state: IUiState) => {
    return state.adaptiveMode !== AdaptiveMode.XS
      && state.adaptiveMode !== AdaptiveMode.SM
      && state.adaptiveMode !== AdaptiveMode.MD;
  },
  getFiltersVisibility: (state: IUiState) => state.filtersVisibility,
  getFiltersChanged: (state: IUiState) => state.filtersChanged,
  allowSearch: (state: IUiState) => state.allowSearch,
  getAppScrollStatus: (state: IUiState) => state.lightboxes.length
    || (state.isDropdownOpened && state.adaptiveMode === AdaptiveMode.XS)
      ? 'hidden'
      : 'scroll',
  isOverlay: (state: IUiState) => state.isOverlay,
  isBlurDisabled: (state: IUiState) => state.isBlurDisabled,
  getDropdownOpened: (state: IUiState) => state.isDropdownOpened,
  viewMode: (state: IUiState) => state.viewMode,
  isLtiViewMode: (state: IUiState) => state.viewMode === ViewMode.LTI,
  isExportViewMode: (state: IUiState) => state.viewMode === ViewMode.EXPORT
};

const mutations = {
  ['SET_ADAPTIVE_MODE'](state: IUiState, payload: AdaptiveMode) {
    state.adaptiveMode = payload;
  },
  ['SET_VIEW_MODE'](state: IUiState, payload: ViewMode) {
    state.viewMode = payload;
  },
  ['SET_LOADING'](state: IUiState, payload: ILoadingData) {
    state.loadingDictionary = {...state.loadingDictionary, ...payload};
  },
  ['OPEN_LIGHTBOX'](state: IUiState, payload: string) {
    state.lightboxes = [...state.lightboxes, payload];
  },
  ['CLOSE_LIGHTBOX'](state: IUiState) {
    state.lightboxes = [];
  },
  ['SET_IS_HEADER_MENU'](state: IUiState, payload: boolean) {
    state.isHeaderMenu = payload;
  },
  ['SET_FILTERS_VISIBILITY'](state: IUiState, payload: boolean) {
    state.filtersVisibility = payload;
  },
  ['SET_FILTERS_CHANGED'](state: IUiState, payload: boolean) {
    state.filtersChanged = payload;
  },
  ['SET_ALLOW_SEARCH'](state: IUiState, payload: boolean) {
    state.allowSearch = payload;
  },
  ['SET_ERROR_CODE'](state: IUiState, payload: ErrorCode) {
    state.errorCode = payload;
  },
  ['SET_VIEW_PORT'](state: IUiState, payload: number) {
    state.viewPort = payload;
  },
  ['SET_IS_OVERLAY'](state: IUiState, payload: OverlayTuple) {
    state.isOverlay = payload;
  },
  ['SET_IS_BLUR_DISABLED'](state: IUiState) {
    state.isBlurDisabled = platform.ios || platform.safari;
  },
  ['SET_DROPDOWN_OPENED'](state: IUiState, payload: boolean) {
    state.isDropdownOpened = payload;
  },
  ['SET_ENTRY_TRANSITION_NAME'](state: IUiState, payload: string | null) {
    state.entryTransitionName = payload;
  },

  updateUiField(state: IUiState, field: string) {
    return updateField(state, field);
  }
};

const actions: ActionTree<IUiState, RootState> = {
  isHeaderMenu({commit}, payload) {
    commit('SET_IS_HEADER_MENU', payload);
  },
  showFilters({commit}, payload: boolean) {
    commit('SET_FILTERS_VISIBILITY', payload);
  },
  showOverlay({commit}, payload: boolean|OverlayTuple) {
    const overlay: OverlayTuple = Array.isArray(payload)
      ? [payload[0], payload[1] || false]
      : [payload, false];
    commit('SET_IS_OVERLAY', overlay);
  },
  allowHeaderSearch: ({commit}, payload: boolean) => {
    commit('SET_ALLOW_SEARCH', payload);
  },
  goBack: (_) => {
    const isRedirectNeeded = window.history.length <= 2;

    isRedirectNeeded
      ? router.push({name: 'main'})
      : router.go(-1);
  },
  switchViewMode({commit}, mode: ViewMode) {
    commit('SET_VIEW_MODE', mode);
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
