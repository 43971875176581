export interface ILicenseDependenciesPayload {
  useRights: string;
  ccVersion: string;
  ccCountry: string;
}

export interface ILicenseDependenciesData {
  useRights: string[];
  ccVersion: string[];
  ccCountry: string[];
}

export interface ILicenseDependenciesService {
  getAvailableData: (tree: ILicenseDependenciesPayload[], payload: ILicenseDependenciesPayload) => ILicenseDependenciesData;
  getUpdatedData: (payload: ILicenseDependenciesPayload, deps: ILicenseDependenciesData) => ILicenseDependenciesPayload;
}

const getAvailableData = (tree: ILicenseDependenciesPayload[], payload: ILicenseDependenciesPayload): ILicenseDependenciesData => {
  const { useRights, ccVersion } = payload;
  return tree
    .reduce((acc, val) => ({
      useRights: [...new Set(['', ...acc.useRights, val.useRights])],
      ccVersion: useRights
        ? val.useRights.includes(useRights)
          ? [...new Set([...acc.ccVersion, val.ccVersion])]
          : acc.ccVersion
        : [''],
      ccCountry: useRights && ccVersion
        ? val.useRights.includes(useRights)
          && val.ccVersion.includes(ccVersion)
            ? [...new Set([...acc.ccCountry, val.ccCountry])]
            : acc.ccCountry
        : ['']
    }), { useRights: [] as string[], ccVersion: [] as string[], ccCountry: [] as string[] });
};

const getUpdatedData = (payload: ILicenseDependenciesPayload, deps: ILicenseDependenciesData): ILicenseDependenciesPayload => {
  const { useRights, ccVersion, ccCountry } = payload;
  return {
    useRights,
    ccVersion: deps.ccVersion.includes(ccVersion)
      ? ccVersion
      : '',
    ccCountry: deps.ccCountry.includes(ccCountry)
      ? ccCountry
      : '',
  };
};

const LicenseDependenciesService: ILicenseDependenciesService = {
  getAvailableData,
  getUpdatedData
};
export default LicenseDependenciesService;
