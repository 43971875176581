







































import { Vue, Component, Prop } from 'vue-property-decorator';
import { VueConstructor } from 'vue/types/umd';
import { UISizeType } from '@/interfaces/interfaces';

import SearchIcon from '@/assets/icons/search.svg';
import ClearIcon from '@/assets/icons/cross.svg';
import InputSuggest from '@/components/UI/Input/InputSuggest.vue';
import { ISuggest } from '@/services/api/search.service';

interface IInputActionDictionary {
  [key: string]: {
    component: VueConstructor,
    action: object,
  };
}

@Component({
  components: { InputSuggest },
  inheritAttrs: false
})
export default class Input extends Vue {
  private focused: boolean = false;
  private inputActionDictionary: IInputActionDictionary = {
    search: {
      component: SearchIcon,
      action: this.transitionSearch
    },
    clear: {
      component: ClearIcon,
      action: this.clearInput
    }
  };

  @Prop({
    type: String,
    required: false
  })
  private variant!: 'light';

  @Prop({
    type: String,
    required: false
  })
  private size!: UISizeType;

  @Prop({
    type: String,
    required: false,
    default: ''
  })
  private mode!: 'search';

  @Prop({
    type: String,
    required: false,
    default: ''
  })
  private wrapperClass!: string;

  @Prop({
    type: String,
    required: false,
    default: ''
  })
  private inputClass!: string;

  @Prop({
    type: String,
    required: false
  })
  private mark!: string;

  @Prop({
    type: Array,
    required: false,
    default: () => []
  })
  private suggestList!: ISuggest[];

  @Prop({
    type: Number,
    required: false,
    default: 1000
  })
  private maxLength!: number;

  private getValue(): string {
    return this.$attrs.value;
  }

  private clearInput() {
    this.$emit('input', '');
  }

  private transitionSearch() {
    this.$emit('search', '');
  }

  private get classObject(): object {
    return {
      [`input-${this.variant}`]: !!this.variant,
      [`input-${this.size}`]: !!this.size,
      [`input-${this.mode}`]: !!this.mode,
      'is-invalid': (this.$attrs.state != null && !this.$attrs.state),
      [`${this.inputClass}`]: true
    };
  }

  private get isSearchMode() {
    return this.mode === 'search';
  }

  private get action() {
    const key = this.getValue() ? 'clear' : 'search';
    return this.inputActionDictionary[key];
  }
}
