import { ISource, ISourceData } from '@/interfaces/interfaces';
import { api } from '@/services/api/api.service';
import { AxiosResponse } from 'axios';

export interface ISourceApiService {
  getSourceById: (id: string) => Promise<ISource>;
  getSourceData: (id: string, query: string) => Promise<ISourceData>;
  getLatestSources: () => Promise<ISource[]>;
}

const getSourceById = (id: string): Promise<ISource> => api
  .get(`mundo/source/${id}`)
  .then((response: AxiosResponse<ISource>) => response.data)
  .catch((error) => Promise.reject(error));

const getSourceData = (id: string, query: string): Promise<ISourceData> => api
  .get(`mundo/search/source/${id}?${query}`)
  .then((response: AxiosResponse<ISourceData>) => response.data)
  .catch((error) => Promise.reject(error));

const getLatestSources = (): Promise<ISource[]> => api
  .get('mundo/source/newest')
  .then((response: AxiosResponse<ISource[]>) => response.data)
  .catch((error) => Promise.reject(error));

const SourceApiService: ISourceApiService = {
  getSourceById,
  getSourceData,
  getLatestSources
};

export default SourceApiService;
