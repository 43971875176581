import {api} from '@/services/api/api.service';
import {AxiosResponse} from 'axios';
import {IUser} from '@/interfaces/interfaces';

export interface IJWTToken {
  access_token?: string;
  refresh_token?: string;
  redirect_url?: string;
}

export interface ICredentials {
  login: string;
  password: string;
} 

export interface IAuthResponse {
  access_token: string | null;
  refresh_token: string | null;
  error: string | null;
  error_description: string | null;
}

export interface IUserRegister {
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  email: string;
  redirectUrl?: string;
}

export interface IResetPassword {
  password: string;
  reEnteredPassword: string;
  token: string;
}

export interface IUpdatePassword {
  oldPassword: string;
  newPassword: string;
  reEnteredPassword: string;
}

export interface IUpdateEmail {
  newEmail: string;
  reEnteredEmail: string;
}

export interface IUpdateUsername {
  newUsername: string;
  reEnteredUsername: string;
}

export interface ICheckEmail {
  emailAvailable: boolean;
}

export interface IAuthorizationService {
  login: (credentials: ICredentials) => Promise<IAuthResponse>;
  register: (data: IUserRegister) => Promise<IUser>;
  confirmEmail: (token: string) => Promise<IJWTToken>;
  confirmChangeEmail: (token: string) => Promise<IJWTToken>;
  checkEmail: (email: string) => Promise<ICheckEmail>;
  forgetPassword: (email: string) => Promise<null>;
  resetPassword: (data: IResetPassword) => Promise<null>;
  checkTokenValidity: (token: String) => Promise<null>;
  updatePassword: (data: IUpdatePassword) => Promise<null>;
  updateEmail: (data: IUpdateEmail) => Promise<null>;
  updateUsername: (data: IUpdateUsername) => Promise<null>;
  getCurrentUser: () => Promise<IUser>;
  setUser: (user: IUser) => Promise<IUser>;
}

const login = (credentials: ICredentials): Promise<IAuthResponse> => api
  .post('mundo/auth/login', credentials)
  .then((response: AxiosResponse<IAuthResponse>) => response.data)
  .catch((error) => Promise.reject(error));

const register = (data: IUserRegister): Promise<IUser> => api
  .post(
    'mundo/auth/user/register',
    data
  )
  .then((response: AxiosResponse<IUser>) => response.data)
  .catch((error) => Promise.reject(error));

const confirmEmail = (token: string): Promise<IJWTToken> => api
  .get(`mundo/auth/user/confirm-email?token=${token}`)
  .then((response: AxiosResponse<IJWTToken>) => response.data)
  .catch((error) => Promise.reject(error));

const confirmChangeEmail = (token: string): Promise<IJWTToken> => api
  .get(`mundo/auth/user/confirm-change-email?token=${token}`)
  .then((response: AxiosResponse<IJWTToken>) => response.data)
  .catch((error) => Promise.reject(error));

const checkEmail = (email: string): Promise<ICheckEmail> => api
  .post(
    'mundo/auth/user/is-email-available-for-registration',
    {email}
  )
  .then((response: AxiosResponse<ICheckEmail>) => response.data)
  .catch((error) => Promise.reject(error));

const forgetPassword = (email: string): Promise<null> => api
  .post(
    'mundo/auth/user/forgot-password',
    { email }
  )
  .then((response: AxiosResponse<null>) => response.data)
  .catch((error) => Promise.reject(error));

const resetPassword = (data: IResetPassword): Promise<null> => api
  .post(
    'mundo/auth/user/reset-password',
    data
  )
  .then((response: AxiosResponse<null>) => response.data)
  .catch((error) => Promise.reject(error));

const updatePassword = (data: IUpdatePassword): Promise<null> => api
  .post(
    'mundo/auth/user/update-password',
    data
  )
  .then((response: AxiosResponse<null>) => response.data)
  .catch((error) => Promise.reject(error));

const checkTokenValidity = (token: String): Promise<null> => api
  .get(`mundo/auth/user/validate-update-password-token?token=${token}`)
  .then((response: AxiosResponse<null>) => response.data)
  .catch((error) => Promise.reject(error));

const updateEmail = (data: IUpdateEmail): Promise<null> => api
  .post(
    'mundo/auth/user/update-email',
    data
  )
  .then((response: AxiosResponse<null>) => response.data)
  .catch((error) => Promise.reject(error));

const updateUsername = (data: IUpdateUsername): Promise<null> => api
  .post(
    'mundo/auth/user/update-username',
    data
  )
  .then((response: AxiosResponse<null>) => response.data)
  .catch((error) => Promise.reject(error));

const getCurrentUser = (): Promise<IUser> => api
  .get('mundo/auth/user/current')
  .then((response: AxiosResponse<Promise<IUser>>) => response.data)
  .catch((error) => Promise.reject(error));

const setUser = (user: IUser): Promise<IUser> => api
  .post('mundo/auth/user', user)
  .then((response: AxiosResponse<Promise<IUser>>) => response.data)
  .catch((error) => Promise.reject(error));

const AuthService: IAuthorizationService = {
  login,
  register,
  confirmEmail,
  confirmChangeEmail,
  checkEmail,
  forgetPassword,
  resetPassword,
  updatePassword,
  checkTokenValidity,
  updateEmail,
  updateUsername,
  getCurrentUser,
  setUser
};

export default AuthService;
