import { api } from '@/services/api/api.service';
import { AxiosResponse } from 'axios';

export interface IEditorProjectPlayerResponse {
  playUrl: string;
}

export interface IEditorApiService {
  getEditorAuthCode: (url: string) => Promise<Record<string, string>>;
  getEditorProjectPlayerUrl: (id: string) => Promise<IEditorProjectPlayerResponse>;
  getEditorPublishedProjectPlayerUrl: (id: string) => Promise<IEditorProjectPlayerResponse>;
}

const getEditorAuthCode = (url: string): Promise<Record<string, string>> => api
  .post(`mundo/auth/authorization-code?redirect_url=${url}`)
  .then((response: AxiosResponse<Record<string, string>>) => response.data)
  .catch((error) => Promise.reject(error));

const getEditorProjectPlayerUrl = (id: string): Promise<IEditorProjectPlayerResponse> => api
  .get(`mundo/user-suggestion/play/${id}`)
  .then((response: AxiosResponse<IEditorProjectPlayerResponse>) => response.data)
  .catch((error) => Promise.reject(error));

const getEditorPublishedProjectPlayerUrl = (id: string): Promise<IEditorProjectPlayerResponse> => api
  .post(`mundo/play/${id}`)
  .then((response: AxiosResponse<IEditorProjectPlayerResponse>) => response.data)
  .catch((error) => Promise.reject(error));  

const EditorApiService: IEditorApiService = {
  getEditorAuthCode,
  getEditorProjectPlayerUrl,
  getEditorPublishedProjectPlayerUrl
};

export default EditorApiService;
// ${process.env.VUE_APP_DEV_PUBLIC_PATH}