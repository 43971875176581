







import { Vue, Component, Prop } from 'vue-property-decorator';
import { IFeature } from '@/interfaces/interfaces';

@Component
export default class FeatureToggle extends Vue {
  @Prop({
    type: String,
    required: false,
    default: ''
  })
  public name!: IFeature;
}
