

















































































import { Vue, Component, Prop } from 'vue-property-decorator';
import Modal from '@/components/UI/Modal.vue';
import { createHelpers } from 'vuex-map-fields';
import { IBookmark, ISetBookmarksMediaParams } from '@/services/api/bookmark.service';
import { ActionType, BookmarkColor } from '@/interfaces/interfaces';
import MerklisteIcon from '@/assets/icons/merkliste.svg';
import PlusIcon from '@/assets/icons/plus.svg';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import store from '@/store';
import { validationMessageDictionary, validationMessageDefaultLanguage } from '@/dictionaries/validation.dictionary';

extend('bookmarkNameAlreadyExist', {
  message: validationMessageDictionary[105][validationMessageDefaultLanguage],
  validate: (value) => !store.state.bookmark.bookmarksStats
    .map((item) => item.name)
    .includes(value)
});

const { mapFields: mapBookmarkField } = createHelpers({
  getterType: 'getBookmarkField',
  mutationType: 'updateBookmarkField',
});

@Component({
  components: { 
    Modal,
    MerklisteIcon,
    PlusIcon,
    ValidationObserver,
    ValidationProvider
  },
  computed: {
    ...mapBookmarkField([
      'bookmarksStats',
      'bookmarksContainsMediaId',
      'bookmarksMediaAction',
      'selectedBookmarks'
    ])
  }
})
export default class UserBookmarksSetMediaDialog extends Vue {
  public bookmarksStats!: IBookmark[];
  public bookmarksContainsMediaId!: string;
  public bookmarksMediaAction!: keyof typeof ActionType | null;
  public selectedBookmarks!: string[];
  public BookmarkColor = BookmarkColor;

  public visibility: boolean = false;
  public createAvailable: boolean = false;
  public newBookmarkName: string = '';
  public duplicate = '123';

  @Prop({
    type: String,
    required: false,
    default: ''
  })
  public returnFocus!: string;

  public get title() {
    switch (this.bookmarksMediaAction) {
      case 'ADD':
        return 'Zu Merkliste hinzufügen';
      case 'MOVE': 
        return 'In Merkliste verschieben';
      default:
        return null;
    }
  }
  public get description() {
    switch (this.bookmarksMediaAction) {
      case 'ADD':
        return 'Wohin soll dieses Medium gespeichert werden?';
      case 'MOVE': 
        return 'Wohin soll dieses Medium verschoben werden?';
      default:
        return null;
    }
  }
  public get confirmTilte() {
    switch (this.bookmarksMediaAction) {
      case 'ADD':
        return this.createAvailable ? 'Erstellen & hinzufügen' : 'Hinzufügen';
      case 'MOVE': 
        return this.createAvailable ? 'Erstellen & verschieben' : 'Verschieben';
      default:
        return null;
    }
  }
  public get filteredBookmarks() {
    return this.bookmarksStats
      .slice()
      .filter((bookmark) => !bookmark.containsMedia)
      .sort((a, b) => {
        if (a.userDefault) {
          return -1;
        }
        if (b.userDefault) {
          return 1;
        }
        return Date.parse(b.createdAt) - Date.parse(a.createdAt);
      });
  }

  public show(mediaId: string, action: keyof typeof ActionType) {
    this.$store.dispatch('getBookmarksContainsMedia', mediaId)
      .then(() => {
        this.visibility = true;
        this.$store.commit('SET_BOOKMARKS_MEDIA_ACTION', action);
      });
  }

  public hide() {
    this.visibility = false;
    this.createAvailable = false;
    this.newBookmarkName = '';
    this.$store.commit('SET_BOOKMARKS_MEDIA_ACTION', null);
  }

  public action() {
    const params: ISetBookmarksMediaParams = {
      mediaId: this.bookmarksContainsMediaId,
      bookmarksIds: this.selectedBookmarks,
      newBookmarkName: this.createAvailable 
        ? this.newBookmarkName || this.$store.getters.getDefaultBookmarkName(this.bookmarksStats) 
        : undefined
    };

    if (this.bookmarksMediaAction === 'ADD') {
      this.$store.dispatch('addMediaToBookmarks', params);
    }
    if (this.bookmarksMediaAction === 'MOVE') {
      this.$store.dispatch('moveMediaToBookmarks', params);
    }

    this.hide();
  }

  public submit() {
    const observer = (this.$refs.observer as InstanceType<typeof ValidationObserver>);
    observer.validate()
      .then((success) => success && this.action());
  }

  public mounted() {
    this.$root.$on('openAddMediaToBookmarksDialog', (mediaId: string) => this.show(mediaId, 'ADD'));
    this.$root.$on('openMoveMediaToBookmarksDialog', (mediaId: string) => this.show(mediaId, 'MOVE'));
  }
}
