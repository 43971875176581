





























import Vue from 'vue';
import Component from 'vue-class-component';
import {ErrorCode} from '@/store/modules/ui/ui.store';
import {createHelpers} from 'vuex-map-fields';

import UISidebar from '@/pages/UI/UI.sidebar.vue';
import UIPage from '@/pages/UI/UI.vue';
import ErrorPage from '@/pages/Error/Error.page.vue';
import Loading from '@/components/Loading.vue';

import CollectionIcon from '@/assets/icons/types/collection.svg';
import ItemsIcon from '@/assets/icons/card/items.svg';

const { mapFields } = createHelpers({
  getterType: 'getUiField',
  mutationType: 'updateUiField',
});

@Component({
  components: {
    UISidebar,
    UIPage,
    Loading,
    ErrorPage
  },
  computed: {
    ...mapFields([
      'errorCode'
    ])
  }
})
export default class UILayout extends Vue {
  private errorCode!: null | ErrorCode;
  private fullWidth: boolean = Boolean(JSON.parse(localStorage.getItem('UIfullWidth') || 'false'));
  private CollectionIcon = CollectionIcon;
  private ItemsIcon = ItemsIcon;

  private mounted() {
    this.$root.$on('toggleUIFullWidth', this.toggleFullWidth);
  }
  private toggleFullWidth() {
    this.$store.commit('SET_LOADING', {filters: true});
    this.fullWidth = !this.fullWidth;
    localStorage.setItem('UIfullWidth', JSON.stringify(this.fullWidth));
    setTimeout(() => {
      location.reload();
    }, 200);
  }
}
