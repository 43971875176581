import { getField, updateField } from 'vuex-map-fields';
import { ActionTree, GetterTree } from 'vuex';
import { RootState } from '@/store';
import {
  IMedia,
  IPublisher,
  IPublishersData,
  IPublisherStat,
  ISource,
  RouteQueryParams } from '@/interfaces/interfaces';
import PublisherApiService from '@/services/api/publisher.service';
import queryString from 'query-string';
import router from '@/router';

export interface IPublisherState {
  publisher: IPublisher | null;
  publishers: IPublisher[];
  publisherStat: IPublisherStat | null;
  publisherSources: ISource[];
  latestPublishers: IPublisher[];
  publisherPopularMedia: IMedia[];
  publishersData: IPublishersData | null;
  publishersParams: Record<string, any>;
}

const defaultParams = {
  page: 1,
  size: 21,
  title: ''
};

const queryParams = (): RouteQueryParams => router.currentRoute.query;

const queryParamsToParams = (queryParams: RouteQueryParams) => {
  const numericKeys = ['page', 'size'];
  return Object.keys(queryParams)
    .reduce((acc, curr) => {
      acc[curr] = numericKeys.includes(curr)
        ? Number(queryParams[curr])
        : queryParams[curr];
      return acc;
    }, {} as Record<string, any>);
};

const initialState = (): IPublisherState => ({
  publisher: null,
  publishers: [],
  publisherStat: null,
  publisherSources: [],
  publisherPopularMedia: [],
  latestPublishers: [],
  publishersData: null,
  publishersParams: {}
});

const state = initialState();

const getters: GetterTree<IPublisherState, RootState> = {
  getPublisherField: (state: IPublisherState) => getField(state),
  getPublisherTitle: (state: IPublisherState) => state.publisher ? state.publisher.title : '',

  getPublisherRootParameters: (state: IPublisherState) => {
    return { ...defaultParams, ...state.publishersParams };
  },
  getPublisherQueryParameters: (state: IPublisherState, getters) => {
    const { page } = getters.getPublisherRootParameters;
    return {
      ...getters.getPublisherRootParameters,
      page: page - 1 // Fix request page parameter
    };
  },
};

const mutations = {
  ['SET_PUBLISHER'](state: IPublisherState, payload: IPublisher) {
    state.publisher = payload;
  },
  ['SET_PUBLISHERS'](state: IPublisherState, payload: IPublisher[]) {
    state.publishers = payload;
  },
  ['SET_PUBLISHER_STAT'](state: IPublisherState, payload: IPublisherStat) {
    state.publisherStat = payload;
  },
  ['SET_PUBLISHER_SOURCES'](state: IPublisherState, payload: ISource[]) {
    state.publisherSources = payload;
  },
  ['SET_LATEST_PUBLISHERS'](state: IPublisherState, payload: IPublisher[]) {
    state.latestPublishers = payload;
  },
  ['SET_PUBLISHER_POPULAR_MEDIA'](state: IPublisherState, payload: IMedia[]) {
    state.publisherPopularMedia = payload;
  },
  ['SET_PUBLISHERS_DATA'](state: IPublisherState, payload: IPublishersData | null) {
    state.publishersData = payload;
  },
  ['SET_PUBLISHERS_PARAMS'](state: IPublisherState, payload: RouteQueryParams) {
    state.publishersParams = {...state.publishersParams, ...payload};
  },
  ['RESET_PUBLISHERS_PARAMS'](state: IPublisherState) {
    state.publishersParams = {};
  },

  updatePublisherField(state: IPublisherState, field: string) {
    return updateField(state, field);
  }
};

const actions: ActionTree<IPublisherState, RootState> = {
  getPublisherById({commit}, id: string) {
    commit('SET_PUBLISHER', null);
    commit('SET_LOADING', {publisher: true});

    return PublisherApiService.getPublisherById(id)
      .then((data) => {
        commit('SET_PUBLISHER', data);
      })
      .catch((error) => console.error(error))
      .finally(() => {
        commit('SET_LOADING', {publisher: false});
      });
  },
  getPublisherStat({commit}, id: string) {
    commit('SET_PUBLISHER_STAT', null);
    commit('SET_LOADING', {publisher: true});

    return PublisherApiService.getPublisherStat(id)
      .then((data) => {
        commit('SET_PUBLISHER_STAT', data);
      })
      .catch((error) => console.error(error))
      .finally(() => {
        commit('SET_LOADING', {publisher: false});
      });
  },
  getPublisherSources({commit}, id: string) {
    commit('SET_PUBLISHER_SOURCES', null);
    return PublisherApiService.getPublisherSources(id)
      .then((data) => {
        commit('SET_PUBLISHER_SOURCES', data);
      })
      .catch((error) => console.error(error));
  },
  getPublisherPopularMedia({commit}, id: string) {
    commit('SET_PUBLISHER_POPULAR_MEDIA', null);
    return PublisherApiService.getPublisherPopularMedia(id)
      .then((data) => {
        commit('SET_PUBLISHER_POPULAR_MEDIA', data);
      })
      .catch((error) => console.error(error));
  },

  async getPublishersByIdList({commit}, idList: string[]) {
    commit('SET_PUBLISHERS', []);
    commit('SET_LOADING', {publisher: true});

    const promises = [];
    for (const id of idList) {
      const model = await PublisherApiService.getPublisherById(id);
      const stat = await PublisherApiService.getPublisherStat(id);
      promises.push({...model, stat});
    }

    return Promise.all(promises)
      .then((data) => {
        commit('SET_PUBLISHERS', data);
      })
      .catch((error) => console.error(error))
      .finally(() => {
        commit('SET_LOADING', {publisher: false});
      });      
  },

  getLatestPublishers({commit}) {
    commit('SET_LATEST_SOURCES', []);

    return PublisherApiService.getLatestPublishers()
      .then((data) => {
        commit('SET_LATEST_PUBLISHERS', data);
        return data;
      })
      .catch((error) => console.error(error));
  },

  getPublishersData({getters, commit}) {
    commit('SET_LOADING', {publishers: true});
    const query = queryString.stringify(getters.getPublisherQueryParameters);

    return PublisherApiService.getPublishersData(query)
      .then((data) => {
        commit('SET_PUBLISHERS_DATA', data);
      })
      .finally(() => {
        commit('SET_LOADING', {publishers: false});
      });
  },
  parsePublishersParams({commit, dispatch}) {
    if (!queryString.stringify(queryParams())
      || queryString.stringify(queryParams())
      === queryString.stringify(defaultParams)) {
      commit('RESET_PUBLISHERS_PARAMS');
    } else {
      commit('SET_PUBLISHERS_PARAMS', queryParamsToParams(queryParams()));
    }

    return dispatch('getPublishersData');
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
