import {RootState} from '@/store';
import {ActionTree, GetterTree, MutationTree} from 'vuex';
import DictionaryApiService, {
  IGlobalDictionary, IFacetSubjectDictionary, IFacetSubject
} from '@/services/api/dictionary.service';
import { ILicenseDependenciesPayload } from '@/services/dependencies/license.service';
import EducationalDependenciesService, { IEducationalDependenciesTreeItem } from '@/services/dependencies/educational.service';
import UserRecordApiService from '@/services/api/user-record.service';

const locale = 'de';

export interface IDictionaryState {
  globalDictionaryList: IGlobalDictionary[];
  facetSubjectDictionary: IFacetSubjectDictionary;
  facetSchoolTypesDictionary: Record<string, string>;
  facetLicensesDictionary: Record<string, string>;
  metadataLicenseDictionary: ILicenseDependenciesPayload[];
  metadataEducationalDictionary: any[];
}

const initialState = (): IDictionaryState => ({
  globalDictionaryList: [],
  facetSubjectDictionary: {},
  facetSchoolTypesDictionary: {},
  facetLicensesDictionary: {},
  metadataLicenseDictionary: [],
  metadataEducationalDictionary: []
});

const state = initialState();

const getters: GetterTree<IDictionaryState, RootState> = {
  globalDictionary: (state: IDictionaryState) => {
    return (name: string) => {
      if (state.globalDictionaryList.length) {
        const item = state.globalDictionaryList.filter((item) => item.name === name);
        const reducedList = (item?.[0]?.dictionaryValueMap ?? {});
        const {'AUDIOVISUELLES': _, ...rest} = reducedList;
        return Object.keys(rest).length > 0 ? rest : [];
      }
      return [];
    };
  },
  globalDictionaryKeys: (state: IDictionaryState, getters) => {
    return (name: string) => {
      return Object.keys( getters.globalDictionary( name ) ).length
        ? Object
          .keys( getters.globalDictionary( name ) )
        : [];
    };
  },
  sortedGlobalDictionaryKeys: (state: IDictionaryState, getters) => {
    return (name: string) => {
      return Object.keys( getters.globalDictionary( name ) ).length
        ? Object
          .keys( getters.globalDictionary( name ) )
          .map((key) => ({
            key,
            value: getters.globalDictionary( name )[key]
              ? getters.globalDictionary( name )[key][`${ locale }Value`]
              : ''
          }))
          .sort((a, b) => a.value.localeCompare(b.value))
          .map((item) => item.key)
        : [];
    };
  },
  globalDictionaryValue: (state: IDictionaryState, getters) => {
    return (value: string, name: string) => {
      return Object.keys(getters.globalDictionary( name )).length
        ? getters.globalDictionary(name)[value][`${ locale }Value`]
        : '';
    };
  },

  facetSubjectDictionary: (state: IDictionaryState) => state.facetSubjectDictionary,
  facetSubjectDictionaryValues: (state: IDictionaryState) => {
    const dictionary = state.facetSubjectDictionary;
    const value = Object.keys(dictionary)
      .reduce((acc, curr) => {
        const category = dictionary[curr].category!;
        acc[category] = [
          ...acc[category] || [],
          {...dictionary[curr], $subjectId: curr}
        ];
        return acc;
      }, {} as Record<string, IFacetSubject[]>);
    const result = Object.keys(value)
      .map((key: string , index: number) => ({
        category: key,
        items: Object.values(value)[index].reduce((acc, curr) => {
          const isNameUnique = !acc.map((item) => item.name).includes(curr.subCategory);
          if (isNameUnique) {
            acc = [...acc.sort(), {
              name: curr.subCategory,
              subjectName: curr.subCategory,
              id: curr.$subjectId,
              level: 1,
              childSubjectMap: {[curr.$subjectId as string]: curr.subCategory!}
            }];
          }
          return acc.sort((a, b) => a.name!.localeCompare(b.name!));
        }, [] as IFacetSubject[])
      }))
      .sort((a, b) => a.category!.localeCompare(b.category!));
    return result;
  },

  facetSchoolTypesDictionary: (state: IDictionaryState) => state.facetSchoolTypesDictionary,
  facetSchoolTypesValues: (state: IDictionaryState) => (types: string[] = []) => {
    const keys = Object.keys(state.facetSchoolTypesDictionary);
    return keys.length
      ? keys.map((item) => item)
          .filter((item) => types.length ? types.includes(item) : true)
      : [];
  },

  facetLicensesDictionary: (state: IDictionaryState) => state.facetLicensesDictionary,
  facetLicensesDictionaryValues: (state: IDictionaryState) => {
    return new Set(Object.values(state.facetLicensesDictionary));
  },
};

const mutations: MutationTree<IDictionaryState> = {
  ['ADD_GLOBAL_DICTIONARY'](state: IDictionaryState, payload: IGlobalDictionary) {
    state.globalDictionaryList.push(payload);
  },
  ['SET_FACET_SUBJECTS'](state: IDictionaryState, payload: IFacetSubjectDictionary) {
    state.facetSubjectDictionary = payload;
  },
  ['SET_FACET_SCHOOL_TYPES_DICTIONARY'](state: IDictionaryState, payload: Record<string, string>) {
    state.facetSchoolTypesDictionary = payload;
  },
  ['SET_FACET_LICENSES_DICTIONARY'](state: IDictionaryState, payload: Record<string, string>) {
    state.facetLicensesDictionary = payload;
  },
  ['SET_DATA_LICENSE_DATA'](state: IDictionaryState, payload: ILicenseDependenciesPayload[]) {
    state.metadataLicenseDictionary = payload;
  },
  ['SET_DATA_EDUCATIONAL_DATA'](state: IDictionaryState, payload: ILicenseDependenciesPayload[]) {
    state.metadataEducationalDictionary = payload;
  },
};

const actions: ActionTree<IDictionaryState, RootState> = {
  receiveGlobalDictionary: ({commit}, dictionary: string) => {
    commit('SET_LOADING', {[`${dictionary}`]: true});
    return DictionaryApiService.receiveGlobalDictionary(dictionary)
      .then((data) => commit('ADD_GLOBAL_DICTIONARY', data))
      .finally(() => commit('SET_LOADING', {[`${dictionary}`]: false}));
  },
  receiveFacetSubjectsDictionary: ({commit}) => {
    commit('SET_LOADING', {receiveFacetSubjectsDictionary: true});
    return DictionaryApiService.receiveFacetSubjectsDictionary()
      .then((data) => commit('SET_FACET_SUBJECTS', data))
      .finally(() => commit('SET_LOADING', {receiveFacetSubjectsDictionary: false}));
  },
  receiveSuggestionSubjectsDictionary: ({commit}) => {
    commit('SET_LOADING', {receiveSuggestionSubjectsDictionary: true});
    return UserRecordApiService.getUserRecordSubjectsDictionary()
      .then((data) => commit('SET_FACET_SUBJECTS', data))
      .finally(() => commit('SET_LOADING', {receiveSuggestionSubjectsDictionary: false}));
  },
  receiveFacetSchoolTypesDictionary: ({commit}) => {
    commit('SET_LOADING', {receiveFacetSchoolTypesDictionary: true});
    return DictionaryApiService.receiveFacetSchoolTypesDictionary()
      .then((data) => commit('SET_FACET_SCHOOL_TYPES_DICTIONARY', data))
      .finally(() => commit('SET_LOADING', {receiveFacetSchoolTypesDictionary: false}));
  },
  receiveFacetLicensesDictionary: ({commit}) => {
    commit('SET_LOADING', {receiveLicensesTypesDictionary: true});
    return DictionaryApiService.receiveFacetLicensesDictionary()
      .then((data) => commit('SET_FACET_LICENSES_DICTIONARY', data))
      .finally(() => commit('SET_LOADING', {receiveLicensesTypesDictionary: false}));
  },
  receiveMetadataLicenseDictionary: ({commit}) => {
    commit('SET_LOADING', {receiveMetadataLicensesDictionary: true});
    return DictionaryApiService.receiveMetadataLicenseDictionary({
      license: 'MUNDO_SUGGESTION'
    })
      .then((data) => commit('SET_DATA_LICENSE_DATA', data.content))
      .finally(() => commit('SET_LOADING', {receiveMetadataLicensesDictionary: false}));
  },
  receiveMetadataEducationalDictionary: ({commit}) => {
    return DictionaryApiService.receiveMetadataEducationalDictionary()
      .then((data) => {
        const fixedData = data.content
          .map((item) => ({
            educationalLevels: item.educationalLevel,
            typeOfSchools: item.typeOfSchool,
            classLevel: item.classLevel.map(item => String(item))
          })
        ) as IEducationalDependenciesTreeItem[];
        commit('SET_DATA_EDUCATIONAL_DATA', fixedData);
        const mappedData = EducationalDependenciesService.getData(fixedData);
        commit('SET_USER_RECORD_EDUCATIONAL_DATA', mappedData);
        return data;
      });
  },
};

export default {
  state,
  getters,
  mutations,
  actions
};
