import {api} from '@/services/api/api.service';
import { AxiosResponse } from 'axios';
import { IFacetSubject } from './dictionary.service';

export interface IUserProfile {
  firstName: string;
  lastName: string;
  aboutMe: string|null;
  createdAt?: string|null;
  disciplines: IFacetSubject[]|null;
  externalId: string|null;
  id: string|null;
  land: string|null;
  pictureId: string|null;
  position: string|null;
  publicInfo: IUserProfilePublicInfo|null;
  schoolTypes: string[];
}

export interface IUserProfilePublicInfo {
  disciplines: boolean;
  land: boolean;
  position: boolean;
  schoolTypes: boolean;
}

interface IUserApiService {
  receiveUserProfile: () => Promise<IUserProfile>;
  updateUserProfile: (data: IUserProfile) => Promise<IUserProfile>;
  deleteUserProfile: () => Promise<null>;
  getUserPublicProfile: (externalId: string) => Promise<IUserProfile>;
}

const receiveUserProfile = (): Promise<IUserProfile> => api
  .get('mundo/profile')
  .then((response: AxiosResponse<IUserProfile>) => response.data)
  .catch((error) => Promise.reject(error));

const updateUserProfile = (data: IUserProfile): Promise<IUserProfile> => api
  .post(
    'mundo/profile',
    data
  )
  .then((response: AxiosResponse<IUserProfile>) => response.data)
  .catch((error) => Promise.reject(error));

const deleteUserProfile = (): Promise<null> => api
  .delete('mundo/profile')
  .then((response: AxiosResponse<null>) => response.data)
  .catch((error) => Promise.reject(error));

const getUserPublicProfile = (externalId: string): Promise<IUserProfile> => api
  .get(`mundo/profile/public/${externalId}`)
  .then((response: AxiosResponse<IUserProfile>) => response.data)
  .catch((error) => Promise.reject(error));

const UserApiService: IUserApiService = {
  receiveUserProfile,
  updateUserProfile,
  deleteUserProfile,
  getUserPublicProfile
};

export default UserApiService;
