import axios, { AxiosResponse } from 'axios';
import requestInterceptor from '@/services/api/interceptors/request.interceptor';
import errorInterceptor from '@/services/api/interceptors/error.interceptor';
import cancelInterceptor from '@/services/api/interceptors/cancel.interceptor';
import tokenInterceptor from '@/services/api/interceptors/token.interceptor';

export const api = axios.create({
  baseURL: '/api',
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  }
});

api.interceptors
  .request.use(tokenInterceptor);
api.interceptors
  .request.use(requestInterceptor);
api.interceptors
  .request.use(cancelInterceptor);

api.interceptors
  .response.use((response: AxiosResponse) => response, errorInterceptor);
