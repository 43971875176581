import { api } from '@/services/api/api.service';
import { AxiosResponse } from 'axios';
import { ISearchResponse } from '@/services/api/search.service';

export enum SuggestMediaAuthor {
  ME = 'ME',
  SOMEONE_ELSE = 'SOMEONE_ELSE'
}

export interface ISuggestMedia {
  url: string;
  email: string;
  author: SuggestMediaAuthor;
}

export interface ISliderApiService {
  suggestMedia: (parameters: ISuggestMedia) => Promise<ISearchResponse>;
}

const suggestMedia = (parameters: ISuggestMedia): Promise<ISearchResponse> => api
  .post(
    'mundo/suggested-media',
    parameters
  )
  .then((response: AxiosResponse<Promise<ISearchResponse>>) => response.data)
  .catch((error) => Promise.reject(error));

const SuggestApiService: ISliderApiService = {
  suggestMedia
};

export default SuggestApiService;
