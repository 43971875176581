import { VueConstructor } from 'vue';
import { IBubbleSubject } from '@/services/api/search.service';
import { NavigationGuardNext, Route } from 'vue-router';

export interface IDictionary<T> {
  [key: string]: T;
}

export interface INumericDictionary<T> {
  [key: number]: T;
}

export interface ICard {
  id?: string;
  identifier?: string;
  title?: string;
  publisher?: string;
  description?: string;
  authors?: string[];
  learnResourceType?: string;
  schoolLevel?: string;
  subject?: string;
  url?: string;
  thumbUrl?: string;
  duration?: string;
  score?: string | number;

  // COLLECTION CORK
  coverList?: string[];
}

export interface ITile {
  id: number;
  title: string;
  href: string;
  count?: number;
  icon?: object;
}
export interface IUILayout {
  name: string;
  component: VueConstructor;
}

export type UISizeType = 'sm' | 'md' | 'lg' | 'xl';

export type VariantType
  = 'primary'
  | 'secondary'
  | 'light'
  | 'link';

export interface IArrowDirection<T> {
  up: T;
  down: T;
  left: T;
  right: T;
}

export type ArrowDirection = 'left' | 'right' | 'up' | 'down';

export enum ActionType {
  ADD,
  MOVE
}

export interface IModal {
  id: string;
  component: VueConstructor;
}

export interface IOverlayScrollbarInstance extends Vue {
  osInstance: () => OverlayScrollbars;
}

export enum SearchSort {
  RELEVANCE = 'RELEVANCE',
  TITLE_ASC = 'TITLE_ASC',
  TITLE_DESC = 'TITLE_DESC',
  NEWEST = 'NEWEST',
  OLDEST = 'OLDEST'
}

export type SearchSortType = keyof typeof SearchSort;

export type SearchSortDictionary<T> = {
  [key in SearchSortType]: T;
};

export type LearnResourceType
  = 'app'
  | 'arbeitsblatt'
  | 'audio'
  | 'audiovisuelles'
  | 'bild'
  | 'broschüre'
  | 'daten'
  | 'entdeckendes'
  | 'experiment'
  | 'fallstudie'
  | 'glossar'
  | 'handbuch'
  | 'interaktion'
  | 'karte'
  | 'kurs'
  | 'lernkontrolle'
  | 'lernspiel'
  | 'modell'
  | 'offene'
  | 'project'
  | 'quelle'
  | 'radio'
  | 'recherche'
  | 'ressourcentyp'
  | 'rollenspiel'
  | 'sammlung'
  | 'simulation'
  | 'software'
  | 'sonstiges'
  | 'test'
  | 'text'
  | 'ubung'
  | 'unterrichtsbaustein'
  | 'unterrichtsplanung'
  | 'veranschaulichung'
  | 'video'
  | 'webseite'
  | 'webtool'
  | 'präsentation'
  // Collection fake LRT
  // Needed for search filters consistency
  | 'collection';

export type EducationalLevel
  = 'Elementarbereich'
  | 'Primarbereich'
  | 'Sekundarstufe I'
  | 'Sekundarstufe II'
  | 'Fort- und Weiterbildung';

export type TypeOfSchool
  = 'Kindergarten'
  | 'Grundschule'
  | 'Mittel- / Hauptschule'
  | 'Realschule'
  | 'Gymnasium'
  | 'Gesamtschule'
  | 'Förderschule'
  | 'Berufsschule'
  | 'Fachoberschule';

// TODO: SchoolType should be separated because they are Educational Level and Type of School
export type SchoolType = TypeOfSchool & EducationalLevel;

export type Subject
  = 'Deutsch'
  | 'Deutsch als Zweitsprache'
  | 'Englisch'
  | 'Französisch'
  | 'Griechisch'
  | 'Italienisch'
  | 'Latein'
  | 'Russisch'
  | 'Sonstige'
  | 'Spanisch'
  | 'Türkisch'
  | 'Chinesisch'
  | 'Dänisch'
  | 'Esperanto'
  | 'Fremdsprachen'
  | 'Japanisch'
  | 'Niederländisch'
  | 'Polnisch'
  | 'Portugiesisch'
  | 'Sorbisch'
  | 'Tschechisch'
  | 'Weitere außereuropäische Fremdsprachen'
  | 'Weitere europäische Fremdsprachen'
  | 'Biologie'
  | 'Chemie'
  | 'Informatik/ITB'
  | 'Mathematik'
  | 'Physik'
  | 'Sachunterricht'
  | 'Umwelt'
  | 'Geografie'
  | 'Geschichte'
  | 'Politische Bildung'
  | 'Wirtschaftskunde'
  | 'Bildende Kunst'
  | 'Musik'
  | 'Ethik'
  | 'Philosophie'
  | 'Religion'
  | 'Sport'
  | 'Arbeitslehre'
  | 'Gesundheit'
  | 'Interkulturelle Bildung'
  | 'Medienpädagogik'
  | 'Psychologie'
  | 'Pädagogik'
  | 'Sucht und Prävention'
  | 'Verkehrserziehung'
  | 'Übergreifende Themen';

export type SchoolLevel
  = 'Preschool'
  | '1-4'
  | '5-10'
  | '11-13';

export type Language
  = 'GERMAN'
  | 'ENGLISH'
  | 'FRENCH'
  | 'SIGN_LANGUAGE';

export type License
  = 'CC BY'
  | 'CC BY-NC'
  | 'CC BY-NC-ND'
  | 'CC BY-NC-SA'
  | 'CC BY-ND'
  | 'CC BY-SA'
  | 'CC0'
  | 'Gemeinfrei / Public Domain'
  | 'freie Lizenz'
  | 'Copyright, freier Zugang'
  | 'Copyright, lizenzpflichtig'
  | 'keine Angaben (gesetzliche Regelung)'
  | 'Schulfunk (§47)';

export type FilterType
  = LearnResourceType
  | Subject
  | SchoolLevel
  | License
  | TypeOfSchool
  | EducationalLevel
  | Language;

export type FilterGroup
  = 'schoolLevels'
  | 'mediaTypes'
  | 'subjects'
  | 'licensees'
  | 'schoolTypes'
  | 'languages';

export const FilterGroupTitle = {
  schoolLevels: 'Klassenstufe',
  mediaTypes: 'Lernressourcentyp',
  subjects: 'Schulfach',
  licensees: 'Lizenz',
  schoolTypes: 'Schulart',
  languages: 'Sprache'
};

export type PopoverPlacement
  = 'auto'
  | 'auto-start'
  | 'auto-end'
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'right'
  | 'right-start'
  | 'right-end'
  | 'bottom'
  | 'bottom-start'
  | 'bottom-end'
  | 'left'
  | 'left-start'
  | 'left-end';

export interface ICardInfo {
  title: string;
  icon: VueConstructor;
  alt?: string;
}

export type DetailsTemplate
  = 'SINGLE'
  | 'COLLECTION';

export type RouteQueryParams = IDictionary<string | Array<(string | null)>>;

export interface IRouterGuard {
  to: Route;
  from: Route;
  next: NavigationGuardNext;
  nextGuard?: (params?: IRouterGuard) => void;
}

export interface IMedia {
  availableTo?: string;
  authors?: string[];
  cost?: string;
  creationDate?: string;
  dataType?: string;
  description?: string;
  downloadRight?: boolean;
  downloadUrl: string;
  duration?: string;
  id?: string;
  identifier?: string;
  linkedObjects?: string[];
  keywords?: string[];
  language?: string[];
  schoolType?: SchoolType[];
  learnResourceType?: LearnResourceType[];
  license?: IMediaLicense;
  producer?: string;
  publishers?: IPublisher[];
  collectedMedias?: IMedia[];
  schoolLevel?: string;
  score?: number;
  size?: number;
  subject?: string;
  subjects?: string;
  sourceId?: string;
  thumbUrl?: string;
  thumbPreview?: string;
  thumbDetails?: string;
  title?: string;
  url?: string;
  originalUrl?: string;
  externalMedia?: boolean;
  rating?: IMediaRating;
  $rated?: boolean;
  status?: string | null;
  bookmarkInfos?: Array<{
    addedAt: string;
    bookmarkId: string;
    userName: string;
  }>;
  children: IMedia[];
  parent: IMedia | null;
}

export interface IMediaRating {
  id: string;
  score: number;
  roundScore: number;
  totalMarks: number;
}

export interface IMediaLicense {
  name: string;
  version: License;
  country: string;
  url: string;
  text: string;
}

export interface IExportedMedia {
  type: string;
  mediaId: string;
  title: string;
  description: string;
  media: string;
  dataType: string;
  thumbnail: string;
  license: {
    name: string;
    version: string;
    country: string;
    url: string;
    text: string;
  }
}

export interface IRecommendedMediaCategory {
  title: string;
  icon: VueConstructor;
}

export type ViewerTag = 'video' | 'audio' | 'img' | 'h5p';

export interface IResponse<T> {
  content: T;
  empty: boolean;
  first: boolean;
  last: boolean;
  hasContent?: boolean;
  number: number;
  numberOfElements: number;
  pageable: {
    pageNumber: number;
    pageSize: number;
    sort: {
      sorted: boolean;
      unsorted: boolean;
      empty: boolean;
    };
    unpaged: boolean;
    paged: boolean;
    offset: number;
  };
  sort: {
    sorted: boolean;
    unsorted: boolean;
    empty: boolean;
  };
  size: number;
  totalElements: number;
  totalPages: number;
}

export interface ISearchData extends IResponse<any> {}

export interface IPublisher {
  id: string;
  title: string;
  name?: string;
  publisherLogo: string;
  description: string;
  officialWebsite: string;
  linkToGeneralUseRights: string;
  creator: string;
  createdAt: string;
  lastModified: string;
  numberOfAllocatedSources: number;
  imagePreview: string;
  imageDetails: string;
  stat: IPublisherStat;
}

export interface IPublisherStat {
  subjectList: IBubbleSubject[];
  total: 0;
}

export interface ISource {
  id: string;
  name: string;
  description: string;
  imageUrl: string;
  website: string;
  created: string;
  createdBy: string;
  edited: string;
  sourceInformation: string;
  sourceStatus: boolean;
  sourceType: string;
  availableMediaCount: number;
  imagePreview: string;
  imageDetails: string;
  termsOfUse: string;
  publishers: IPublisher[];
}

export interface ISourceData extends Omit<ISearchData, 'content'> {
  content: ISource[];
}

export interface IPublishersData extends Omit<ISearchData, 'content'> {
  content: IPublisher[];
}

export interface IUserRecordsData extends Omit<ISearchData, 'content'> {
  content: IUserRecord[];
}

export interface IWidget {
  visible: boolean;
}

export type IFeature
  = 'login'
  | 'registration'
  | 'bookmarks'
  | 'user'
  | 'entry';

export interface IFeaturesResponse {
  features: Record<IFeature, boolean>;
}

export interface ILtiEmbedResponse {
  url: string;
  payload: IDictionary<string>;
}

export enum UserRole {
  ROLE_USER = 'ROLE_USER'
}

export interface IUser {
  id: string | null;
  username: string;
  password: string | null;
  email: string;
  phone: string | null;
  roles: Array<keyof typeof UserRole> | null;
  enabled: boolean | null;
  externalId?: string | null;
}

export type AbilityAction
  = 'create'
  | 'read'
  | 'update'
  | 'delete'
  | 'manage';

export type AbilitySubject
  = 'all';

export enum BookmarkColor {
  PALETTE_1 = 'palette-1',
  PALETTE_2 = 'palette-2',
  PALETTE_3 = 'palette-3',
  PALETTE_4 = 'palette-4',
  PALETTE_5 = 'palette-5',
  PALETTE_6 = 'palette-6',
  PALETTE_7 = 'palette-7',
  PALETTE_8 = 'palette-8',
  PALETTE_9 = 'palette-9',
  PALETTE_10 = 'palette-10',
  PALETTE_11 = 'palette-11',
  GRAY_LIGHT = 'gray-light',
}

export enum BookmarkContrastColor {
  PALETTE_1 = 'black',
  PALETTE_2 = 'black',
  PALETTE_3 = 'black',
  PALETTE_4 = 'white',
  PALETTE_5 = 'white',
  PALETTE_6 = 'white',
  PALETTE_7 = 'white',
  PALETTE_8 = 'white',
  PALETTE_9 = 'white',
  PALETTE_10 = 'white',
  PALETTE_11 = 'white',
  GRAY_LIGHT = 'white',
}

export enum BookmarkSort {
  TITLE_ASC = 'Alphabetisch A-Z',
  TITLE_DESC = 'Alphabetisch Z-A',
  NEWEST = 'Neueste zuerst',
  OLDEST = 'Älteste zuerst'
}

export interface IBookmarkParams {
  filters: LearnResourceType[];
  sortBy: keyof typeof BookmarkSort;
}

export enum UserRecordStatus {
  DRAFT = 'DRAFT',
  SUBMITTED = 'SUBMITTED',
  REJECTED = 'REJECTED',
  REVIEW = 'REVIEW',
  APPROVED = 'APPROVED'
}

export interface IUserRecord {
  id?: string;
  mediaType: string;
  metadata?: IUserRecordMetadataDTO;
  status?: keyof typeof UserRecordStatus;
  tenant?: string;
  creatorUserName?: string;
  creatorUserId?: string;
  created?: string;
  expirationDate?: string;
  updated?: string;
  comments?: IUserRecordComments;
}

export interface IUserRecordMetadataDTO {
  id?: string;
  sodixId?: string;
  title: string;
  description?: string;
  language?: string[];
  keywords?: string[];
  creator?: string;

  creationTime?: string;
  lastModifiedTime?: string;
  publishedTime?: string;
  availableFromTime?: string;
  availableUntilTime?: string;

  mediaStatus?: string;
  author?: string[];
  authorWebSite?: string[];
  producer?: string[];
  publishers?: IPublisher[];

  size?: number;
  dataType?: string;
  duration?: string;
  thumbnail: string;
  media: string;
  mediaFileStatus?: MediaFileStatus;
  subline?: string;

  targetAudience?: string[];
  learningResourceTypes: string[];
  lrtSubcategories?: string[];

  educationalLevels: string[];
  typeOfSchools: string[];
  classLevel: string[];
  disciplines: ISubjectDTO[];
  license: ILicense;

  cost?: string;
  download?: boolean;

  motherObject?: IUserRecordMetadataDTO[];
  childObject?: IUserRecordMetadataDTO[];
  learningObject?: IUserRecordMetadataDTO[];
  learnObjectVersionDescription?: string;
  learnObjectDescription?: string;

  originalLinkToMedia?: string;

  additionalLicenseInformation?: string;
  learningGroupId?: string;

  externalIds?: string[];
  imported?: boolean;
  importSourceName?: string;
  thumbFile?: string;
  mainDisciplineName?: string;

  land?: string;
}

export type MediaFileStatus = 'INTERNAL' | 'EXTERNAL' | 'UPLOADED' | 'NEW_UPLOAD';

export interface ISubjectDTO {
  id: string;
  subjectName: string;
  level: number;
  childSubjectMap: Record<string, string>;
}

export interface ILicense {
  useRights: string;
  ccVersion: string;
  ccCountry: string;
}

export interface IUserRecordComments {
  date: string;
  authorId: string;
  comments: string[];
  resolved: boolean;
}

export enum DictionaryModuleName {
  license
}

export enum DictionaryModuleType {
  MUNDO_SUGGESTION,
  ADMIN_TOOL,
  EDITOR_UPLOAD,
  SUBMIT_PROJECT,
  PROJECT_IMAGE,
  CREATE_CHAPTER,
  SECTIONS,
  H5P
}
