import { AxiosResponse } from 'axios';
import { api } from '@/services/api/api.service';
import { ICmsPage, ICmsRecommendedMedia } from '@/store/modules/cms/cms.store';
import { IMainSection } from '@/store/modules/cms/cms.main.store';
import { IFAQSection } from '@/store/modules/cms/cms.faq.store';
import { IDictionary } from '@/interfaces/interfaces';
import { ICmsStaticPage } from '@/store/modules/cms/cms.static-pages.store';

export interface ICmsResponse {
  id: number;
  created_at: string;
  created_by: ICmsResponseUser;
  updated_at: string;
  updated_by: ICmsResponseUser;
}

export interface ICmsResponseUser {
  firstname: string;
  id: number;
  lastname: string;
  username: string;
}

export interface ICmsResponseImage {
  id: string | number;
  name: string;
  alternativeText: string;
  caption: string;
  width: string | number;
  height: string | number;
  formats: IDictionary<IDictionary<string | number>>;
  hash: string;
  ext: string;
  mime: string;
  size: number;
  url: string;
  previewUrl: string | null;
  provider: string;
  provider_metadata: null;
  created_by: string;
  updated_by: string;
  created_at: string;
  updated_at: string;
}

export interface ICmsQueryParameters {
  _filters?: CmsFilterType;
  _sort?: CmsSortType;
  _sortByName?: string;
  _limit?: number;
  _start?: number;
}

export type CmsSortType = 'ASC' | 'DESC';

export type CmsFilterType
  = ''
  | 'eq' // Equal
  | 'ne' // Not equal
  | 'lt' // Less than
  | 'gt' // Greater than
  | 'lte' // Less than or equal to
  | 'gte' // Greater than or equal to
  | 'in' // Included in an array
  | 'nin' // 	Not included in an array
  | 'contains' // Contains
  | 'ncontains' // Doesn't contain
  | 'containss' // Contains, case sensitive
  | 'ncontainss' // Doesn't contain, case sensitive
  | 'null'; // Is null or not null

export interface ICmsApiService {
  getCmsPage: (query: string) => Promise<ICmsPage[]>;
  getCmsMainSections: () => Promise<IMainSection[]>;
  getCmsFAQSections: () => Promise<IFAQSection[]>;
  getCmsFAQCount: () => Promise<number>;
  getCmsRecommendedMedia: () => Promise<ICmsRecommendedMedia[]>;
  getCmsStaticPage: (query: string) => Promise<ICmsStaticPage[]>;
}

const getCmsPage = (query: string): Promise<ICmsPage[]> => api
  .get(`cms/pages?${query}`)
  .then((response: AxiosResponse<ICmsPage[]>) => response.data)
  .catch((error) => Promise.reject(error));

const getCmsMainSections = (): Promise<IMainSection[]> => api
  .get('cms/mains')
  .then((response: AxiosResponse<IMainSection[]>) => response.data)
  .catch((error) => Promise.reject(error));

const getCmsFAQSections = (): Promise<IFAQSection[]> => api
  .get('cms/faqs?_sort=order:ASC')
  .then((response: AxiosResponse<IFAQSection[]>) => response.data)
  .catch((error) => Promise.reject(error));

const getCmsFAQCount = (): Promise<number> => api
  .get('cms/faqs/count')
  .then((response: AxiosResponse<number>) => response.data)
  .catch((error) => Promise.reject(error));

const getCmsRecommendedMedia = (): Promise<ICmsRecommendedMedia[]> => api
  .get('cms/suggested-medias')
  .then((response: AxiosResponse<ICmsRecommendedMedia[]>) => response.data)
  .catch((error) => Promise.reject(error));

const getCmsStaticPage = (query: string): Promise<ICmsStaticPage[]> => api
  .get(`cms/static-pages?${query}`)
  .then((response: AxiosResponse<ICmsStaticPage[]>) => response.data)
  .catch((error) => Promise.reject(error));

const CmsApiService: ICmsApiService = {
  getCmsPage,
  getCmsMainSections,
  getCmsFAQSections,
  getCmsFAQCount,
  getCmsRecommendedMedia,
  getCmsStaticPage
};

export default CmsApiService;
