import { extend } from 'vee-validate';
import { required, email, min, length, confirmed } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: 'Dieses Feld ist erforderlich'
});

extend('requiredString', {
  validate(value: any) {
    return !!value && typeof value === 'string';
  },
  message: 'Dieses Feld ist erforderlich'
});

extend('email', {
  ...email,
  message: 'Dieses Feld muss eine gültige E-Mail sein'
});

extend('min', {
  ...min,
  message: 'Dieses Feld ist zu kurz'
});

extend('confirmations', {
  ...length,
  message: 'Bitte bestätigen Sie alle Berechtigungen'
});

extend('confirmedEmail', {
  ...confirmed,
  message: 'Die E-Mail stimmt nicht überein'
});

extend('confirmedPassword', {
  ...confirmed,
  message: 'Das Passwort stimmt nicht überein'
});

extend('confirmedUsername', {
  ...confirmed,
  message: 'Der Anzeigename stimmt nicht überein'
});

extend('url', {
  validate(value: string | null | undefined): boolean {
    if (value) {
      const valueWithoutWWW = value.replace('www.', '');
      return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{1,1000}(:[0-9]{1,1000})?(\/.*)?$/
        .test(valueWithoutWWW);
    }
    return false;
  },
  message: 'Dieser Wert muss eine gültige URL sein'
});

extend('maxFileSize', {
  validate(value: File, args: any) {
    return maxFileSize(value, args);
  },
  message: (name, args) => {
    return `Diese Dateigröße muss kleiner als ${args[0] + ' ' + args[1]} sein`;
  }
});

export const maxFileSize = (value: File, args: any) => {
  if (!value) return false;
  const multiplier = () => {
    const sizeType = args[1] || null;
    switch (sizeType) {
      case 'GB':
        return 1000000000;
      case 'MB':
        return 1000000;
      case 'KB':
        return 1000;
      default:
        return 1000;
    }
  };
  return Boolean(
    value 
    && args[0] 
    && (value.size <= Number(args[0]) * multiplier())
  );
};
