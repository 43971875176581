import { IRouterGuard } from '@/interfaces/interfaces';
import store from '@/store';

export const redirectGuard = (params: IRouterGuard) => {
  const {from, to, next, nextGuard} = params;
  const {editor_redirect_url, redirect_url} = to.query;

  if (redirect_url) {
    store.commit('SET_REDIRECT', redirect_url);
  }

  if (editor_redirect_url) {
    store.commit('SET_EDITOR_REDIRECT', editor_redirect_url);
    if (!store.getters.isAuthenticated) return nextGuard!({from, to, next});
    return store.dispatch('getEditorAuthCode', editor_redirect_url)
      .then((authorization_code) => {
        next(false);
        window.location.href = `${editor_redirect_url}?authorization_code=${authorization_code}`;
        return false;
      })
      .catch(() => {
        return store.dispatch('setTokenData', null)
          .then(() => nextGuard!({from, to, next}));
      });
  }

  return nextGuard ? nextGuard({from, to, next}) : next();
};
