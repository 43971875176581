import { getField, updateField } from 'vuex-map-fields';
import { ActionTree, GetterTree } from 'vuex';
import store, {RootState} from '@/store';
import router from '@/router';
import { RawLocation, Route } from 'vue-router';
import {
  decodeNumberParameter,
  decodeStringParameter,
  getStringFromArrayParameter,
  isEmptyParameter,
  isObjectsEqual
} from '@/utilities';
import SearchApiService, {
  ISearchResponse,
  ISearchParameters,
  IDefaultValueDictionary,
  ISearchFilter,
  SearchFiltersObject,
  IBubbleResponse,
  IBubbleSubject,
  IReportError, ISendContactForm, ISearchRequestParams,
} from '@/services/api/search.service';
import {
  IDictionary,
  SearchSortType,
  SearchSort,
  FilterType,
  FilterGroup, IMedia,
} from '@/interfaces/interfaces';
import { getLearnResourceTypeByTitle } from '@/dictionaries/titles/titles.learnResourceType.dictionary';
import UserApiService from '@/services/api/user.service';
import { getLanguageNameByValue } from '@/dictionaries/titles/titles.language.dictionary';

export interface ISearchState {
  search: string;

  size: number;
  page: number;
  sort: SearchSortType;
  user?: string;

  filter: ISearchFilter[];

  searchParameters: null | ISearchParameters;
  searchData: null | ISearchResponse;

  isActiveSidebar: boolean;
  isActiveModalSucces: boolean;
  detailId: null | string;

  bubble: {
    subjectList: IBubbleSubject[],
    total: null | number
  };
  redirect: object;
  approvedMediaList: IMedia[];
  searchTotalElements: number | null;
  author?: string;
}

export const defaultValueDictionary: IDefaultValueDictionary = {
  search: '',
  size: 24,
  page: 0,
  sort: SearchSort.RELEVANCE,
  filter: [],
  user: undefined
};

const getQuery = (data: ISearchParameters) => {
  return (Object.keys(data) as Array<(keyof ISearchParameters)>)
    .reduce(
      (result: IDictionary<string>, key: keyof ISearchParameters) => {
        const item: undefined
          | string
          | number
          | boolean
          | number[]
          | ISearchFilter[]
          | SearchFiltersObject = data[key];

        if (isEmptyParameter(item)) {
          return result;
        }
        if (Array.isArray(item)) {
          const arr = item.map((item) => item['group'] ? `${item.group}.${item.name}` : item);
          result[key] = getStringFromArrayParameter(arr);
        } else {
          result[key] = item!.toString();
        }
        return result;
      },
      {}
    );
};

const redirect = (parameters: ISearchParameters): Promise<Route> => {
  const name = store.getters.isLtiViewMode
    ? 'lti-search'
    : store.getters.isExportViewMode
      ? 'export-search'
      : 'search';
  const query = getQuery(parameters);
  const location = {
    name,
    query
  } as RawLocation;

  return router.push(location as RawLocation).catch((err) => (err));
};

export const getSearchRequestParametersFromSearchQueryParameters
  = (query: ISearchParameters): ISearchParameters => {
  const {
    search,
    sort,
    page,
    size,
    user
  } = query;

  return {
    search: search ? search : '',
    sort: sort ? sort : defaultValueDictionary.sort,
    size: size ? size : defaultValueDictionary.size,
    ...(page && {page}),
    user
  };
};

const initialState = (): ISearchState => ({
  search: defaultValueDictionary.search,
  approvedMediaList: [],
  size: defaultValueDictionary.size,
  page: defaultValueDictionary.page,
  sort: defaultValueDictionary.sort,
  user: defaultValueDictionary.user,

  filter: [],

  searchParameters: null,
  searchData: null,

  isActiveSidebar: false,
  detailId: null,

  isActiveModalSucces: false,

  bubble: {
    subjectList: [],
    total: null
  },

  redirect: {},
  searchTotalElements: null,

  author: undefined
});

const state = initialState();

const getters: GetterTree<ISearchState, RootState> = {
  getSearchField: (state: ISearchState) => getField(state),

  search: (state: ISearchState): string => state.search,
  searchPage: (state: ISearchState): number => state.page,
  getTotalPages: (state: ISearchState): number => state.searchData
    ? state.searchData.totalPages
    : 0,

  searchParameters: (state: ISearchState): null | ISearchParameters => state.searchParameters,
  getSearchQueryRootParameters: (state: ISearchState) => {
    const parameters: ISearchParameters = {};
    if (state.search !== defaultValueDictionary.search) {
      parameters.search = state.search;
    }
    if (state.size !== defaultValueDictionary.size) {
      parameters.size = state.size;
    }
    if (state.page !== defaultValueDictionary.page) {
      parameters.page = state.page;
    }
    if (state.sort !== defaultValueDictionary.sort) {
      parameters.sort = state.sort;
    }
    if (state.user !== defaultValueDictionary.user) {
      parameters.user = state.user;
    }
    return parameters;
  },
  getSearchQueryFiltersParameters: (_state, _getters, rootState) => {
    const filter = [...rootState.filters.filters];
    return { filter };
  },
  getSearchQueryParameters: (_state: ISearchState, getters) => {
    return {
      ...getters.getSearchQueryRootParameters,
      ...getters.getSearchQueryFiltersParameters,
    };
  },
  getSearchParameters: (state: ISearchState) => {
    const parameters: ISearchParameters = {
      search: state.search,
      sort: state.sort,
      size: state.size,
      user: state.user,
      filter: [],
    };

    if (state.page !== defaultValueDictionary.page) {
      parameters.page = state.page;
    }
    if (state.sort !== defaultValueDictionary.sort) {
      parameters.sort = state.sort;
    }
    if (state.filter.length !== defaultValueDictionary.filter.length) {
      parameters.filter = state.filter.map((filter) => filter);
    }
    if (state.user !== defaultValueDictionary.user) {
      parameters.user = state.user;
    }
    return parameters;
  },

  getSearchResultList: (state: ISearchState) => state.searchData ? state.searchData.tiles : [],
  getSearchResultItem: (state: ISearchState, getters) => {
    return getters.getSearchResultList
      .filter((item: any) => item.id === state.detailId)[0];
  },

  getSearchFacet: (state: ISearchState) => state.searchData ? state.searchData.facet : [],

  detailId: (state: ISearchState) => state.detailId,
  isActiveSidebar: (state: ISearchState) => state.isActiveSidebar,
  isActiveModalSucces: (state: ISearchState) => state.isActiveModalSucces,
  isCollection: () => (type: string): boolean => type === 'sammlung',
  isSearchFiltersChanged: (state: ISearchState, _getters, rootState) => {
    return state.filter.length
      !== rootState.filters.filters.length;
  },

  bubbleSubjectList: (state: ISearchState) => state.bubble.subjectList.length
    ? state.bubble.subjectList
      .sort((a: IBubbleSubject, b: IBubbleSubject) => b.value - a.value)
    : [],
  bubbleTotal: (state: ISearchState) => state.bubble.total
    ? state.bubble.total
    : null,
  getSearchTotalElements: (state: ISearchState) => state.searchTotalElements
    ? state.searchTotalElements
    : null,
  getSearchFilterName: (state: ISearchState) => (filter: string) =>
    state.user && state.user === filter
      ? `Autorenschaft von ${state.author}`
      : filter,
};

const mutations = {
  updateSearchField(state: ISearchState, field: string) {
    return updateField(state, field);
  },
  ['SET_SEARCH'](state: ISearchState, payload: string) {
    state.search = payload;
  },
  ['RESET_SEARCH'](state: ISearchState) {
    state.search = defaultValueDictionary.search;
  },
  ['SET_SIZE'](state: ISearchState, payload: number) {
    state.size = payload;
  },
  ['SET_SEARCH_USER'](state: ISearchState, payload: string) {
    state.user = payload;
  },
  ['SET_SEARCH_AUTHOR'](state: ISearchState, payload: string) {
    state.author = payload;
  },
  ['SET_SEARCH_PAGE'](state: ISearchState, payload: number) {
    state.page = payload;
  },
  ['RESET_SEARCH_PAGE'](state: ISearchState) {
    state.page = defaultValueDictionary.page;
  },
  ['SET_SORT'](state: ISearchState, payload: SearchSortType) {
    state.sort = payload;
  },
  ['SET_FILTER'](state: ISearchState, payload: ISearchFilter[]) {
    state.filter = payload;
  },
  ['SET_SEARCH_DATA'](state: ISearchState, payload: ISearchResponse) {
    state.searchData = payload;
  },
  ['SET_SEARCH_PARAMETERS'](state: ISearchState, payload: ISearchParameters) {
    state.searchParameters = payload;
  },
  ['SET_IS_ACTIVE_SIDEBAR'](state: ISearchState, payload: boolean) {
    state.isActiveSidebar = payload;
  },
  ['SET_BUBBLE'](state: ISearchState, payload: IBubbleResponse) {
    state.bubble = payload;
  },
  ['SET_SEARCH_REDIRECT'](state: ISearchState, payload: object) {
    state.redirect = payload;
  },
  ['SET_SEARCH_TOTAL_ELEMENTS'](state: ISearchState, payload: number) {
    state.searchTotalElements = payload;
  },
  ['SET_USER_APPROVED_MEDIA_LIST'](state: ISearchState, payload: IMedia[]) {
    state.approvedMediaList = payload;
  },
};

const actions: ActionTree<ISearchState, RootState> = {
  parseSearchParameters({commit, getters, dispatch}, parameters): Promise<void> {
    commit('SET_LOADING', {search: true});

    return Promise.all([
      dispatch('parseSearch', parameters.search),
      dispatch('parseSize', parameters.size),
      dispatch('parsePage', parameters.page),
      dispatch('parseFilter', parameters.filter),
      dispatch('parseSort', parameters.sort),
      dispatch('parseSearchUser', parameters.user)
    ]).then(() => {
      const searchParameters = getters.getSearchParameters;
      commit('SET_SEARCH_PARAMETERS', searchParameters);
      commit('SET_SEARCH_REDIRECT', getQuery(getters.getSearchQueryParameters));

      const filtersRequestParameters = state.filter.reduce((acc, curr) => {
          acc[curr.group as FilterGroup] = [
            ...acc[curr.group as FilterGroup] || [],
            curr.group === 'mediaTypes'
              ? getLearnResourceTypeByTitle(curr.name as string) as FilterType
              : curr.group === 'languages'
                ? getLanguageNameByValue(curr.name as string) as FilterType
                : curr.name as FilterType
          ];
          return acc;
        }, {} as SearchFiltersObject);

      const { filter: _, ...restSearchParameters } = searchParameters;
      const requestParameters = {
        ...restSearchParameters,
        page: searchParameters.page - 1,
        filters: {
          ...filtersRequestParameters,
          authorId: searchParameters.user
        }
      };
      return dispatch('getSearchData', requestParameters);
    });
  },
  parseSearch: ({commit}, parameters?: string): void => {
    commit('SET_SEARCH', decodeStringParameter(parameters, ''));
  },
  parseSize: ({commit}, parameters?: string): void => {
    commit('SET_SIZE', decodeNumberParameter(parameters, defaultValueDictionary.size));
  },
  parsePage: ({commit}, parameters?: string): void => {
    commit('SET_SEARCH_PAGE', decodeNumberParameter(parameters, defaultValueDictionary.page)) ;
  },
  parseSort: ({commit}, parameters?: string): void => {
    const sortParameter = decodeStringParameter(parameters, defaultValueDictionary.sort) as SearchSortType;
    const sort = typeof SearchSort[sortParameter] === 'undefined'
      ? defaultValueDictionary.sort
      : SearchSort[sortParameter];
    commit('SET_SORT', sort);
  },

  parseSearchUser: ({state, commit}, parameters?: string) => {
    const userParameter = decodeStringParameter(parameters, '');
    if (userParameter && state.user !== userParameter) {
      return UserApiService.getUserPublicProfile(userParameter)
        .then((data) => {
          commit('SET_SEARCH_USER', userParameter);
          commit('SET_SEARCH_AUTHOR', `${data.firstName} ${data.lastName}`);
        });
    }
    commit('SET_SEARCH_USER', userParameter);
  },

  parseFilter: ({commit}, parameters): void => {
    let result = defaultValueDictionary.filter;
    if (parameters) {
      result = parameters
        .replace(', ', '__')
        .split(',')
        .map((name: FilterType) => {
          const replaced = name.replace('__', ', ') as FilterType;
          let id: string = replaced;
          const itemGroup = replaced.split('.')[0];
          const itemName = replaced.split('.')[1];
          if (itemGroup === 'mediaTypes') {
            id = `${itemGroup}.${getLearnResourceTypeByTitle(itemName)}`;
          }
          return {
            name: itemName,
            group: itemGroup,
            id: id,
          };
        });
    }

    result && result.length
      ? commit('SET_LAST_APPLIED_FILTER', result[result.length - 1])
      : commit('SET_LAST_APPLIED_FILTER', null);

    commit('SET_FILTER', result);
    commit('SET_SEARCH_FILTERS', result);
  },

  getUserApprovedMediaList: ({commit}) => {
    return SearchApiService.getUserApprovedMediaList({page: 0, size: 100})
      .then((data) => {
        commit('SET_USER_APPROVED_MEDIA_LIST', data.tiles);
      });
  },

  getSearchData: ({state, commit, dispatch, rootGetters}, payload: ISearchRequestParams) => {
    const apiMethod = rootGetters.isExportViewMode
      ? SearchApiService.exportSearch : SearchApiService.search;
    return apiMethod(payload)
      .then((data: ISearchResponse) => {
        if (state.isActiveSidebar) {
          commit('SET_IS_ACTIVE_SIDEBAR', false);
        }
        commit('SET_SEARCH_DATA', data);
        dispatch('setSearchFacet', data.facet);
      })
      .finally(() => commit('SET_LOADING', {search: false}));
  },
  redirectToSearch: ({getters}, query: ISearchParameters): Promise<void | Route> => {
    const searchPageRoutes = ['search', 'export-search', 'lti-search'];
    const isSearchPage = searchPageRoutes.includes(router.currentRoute.name!);

    if (
      isSearchPage &&
        getters.searchParameters !== null &&
      isObjectsEqual(
        getters.searchParameters,
        getSearchRequestParametersFromSearchQueryParameters(query)
      )
    ) {
      return Promise.resolve();
    }

    return redirect(query);
  },

  onChangeSearch: ({commit, getters, dispatch}): Promise<void | Route> => {
    commit('RESET_SEARCH_PAGE');
    return dispatch('redirectToSearch', getters.getSearchQueryParameters)
      .then(() => dispatch('checkFiltersBinding'));
  },
  onChangeSort: ({commit, dispatch, getters}, sort: SearchSortType): Promise<void | Route> => {
    commit('RESET_SEARCH_PAGE');
    commit('SET_SORT', sort);
    return dispatch('redirectToSearch', getters.getSearchQueryParameters);
  },
  onChangePage: ({commit, dispatch, getters}, page: number): Promise<void | Route> => {
    commit('SET_SEARCH_PAGE', page);
    return dispatch('redirectToSearch', getters.getSearchQueryParameters);
  },

  getBubble: ({commit}) => {
    commit('SET_LOADING', {bubble: true});
    return SearchApiService.bubble()
      .then((data: IBubbleResponse) => {
        commit('SET_BUBBLE', data);
      })
      .catch((error) => console.error(error))
      .finally(() => commit('SET_LOADING', {bubble: false}));
  },
  reportError: (_, payload: IReportError) => {
    return SearchApiService.reportError(payload);
  },
  sendContactForm: ({commit}, payload: ISendContactForm) => {
    commit('SET_LOADING', {contactForm: true});

    return SearchApiService.sendContactForm(payload)
      .finally(() => commit('SET_LOADING', {contactForm: false}));
  },
  receiveSearchTotalElements: ({commit, getters}) => {
    if (getters.getSearchTotalElements !== null) { return; }
    commit('SET_LOADING', {totalElements: true});
    return SearchApiService.receiveSearchTotalElements()
      .then((data: number) => commit('SET_SEARCH_TOTAL_ELEMENTS', data))
      .finally(() => commit('SET_LOADING', {totalElements: false}));
  },
  resetSearch: ({commit}) => {
    commit('RESET_SEARCH');
  },
  requestSuggest: (_, query) => {
    const encodedQuery = encodeURI(query);
    return SearchApiService.suggest(encodedQuery);
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
