import { getField, updateField } from 'vuex-map-fields';
import { ActionTree, GetterTree } from 'vuex';
import { RootState } from '@/store';
import { RouteQueryParams, ISource, ISourceData } from '@/interfaces/interfaces';
import SourceApiService from '@/services/api/source.service';
import queryString from 'query-string';
import router from '@/router';

export interface ISourceState {
  source: ISource | null;
  sourceData: ISourceData | null;
  sourceParams: Record<string, any>;
  latestSources: ISource[];
}

const defaultParams = {
  page: 1,
  size: 24
};

const queryParams = (): RouteQueryParams => router.currentRoute.query;

const queryParamsToParams = (queryParams: RouteQueryParams) => {
  const numericKeys = ['page', 'size'];
  return Object.keys(queryParams)
    .reduce((acc, curr) => {
      acc[curr] = numericKeys.includes(curr)
        ? Number(queryParams[curr])
        : queryParams[curr];
      return acc;
    }, {} as Record<string, any>);
};

const initialState = (): ISourceState => ({
  source: null,
  sourceData: null,
  sourceParams: {},
  latestSources: []
});

const state = initialState();

const getters: GetterTree<ISourceState, RootState> = {
  getSourceField: (state: ISourceState) => getField(state),
  getSourceTitle: (state: ISourceState) => state.source ? state.source.name : '',
};

const mutations = {
  ['SET_SOURCE'](state: ISourceState, payload: ISource) {
    state.source = payload;
  },
  ['SET_SOURCE_DATA'](state: ISourceState, payload: ISourceData | null) {
    state.sourceData = payload;
  },
  ['SET_SOURCE_PARAMS'](state: ISourceState, payload: RouteQueryParams) {
    state.sourceParams = {...state.sourceParams, ...payload};
  },
  ['RESET_SOURCE_PARAMS'](state: ISourceState) {
    state.sourceParams = {};
  },
  ['SET_LATEST_SOURCES'](state: ISourceState, payload: ISource[]) {
    state.latestSources = payload;
  },

  updateSourceField(state: ISourceState, field: string) {
    return updateField(state, field);
  }
};

const actions: ActionTree<ISourceState, RootState> = {
  async getSourceById({commit}, payload: { id: string, loader?: boolean }) {
    const { id, loader } = payload;

    commit('SET_SOURCE', null);
    if (loader) {
      commit('SET_LOADING', {source: true});
    }

    return SourceApiService.getSourceById(id)
      .then((data) => {
        commit('SET_SOURCE', data);
        return data;
      })
      .catch((error) => console.error(error))
      .finally(() => {
        commit('SET_LOADING', {source: false});
      });
  },
  async getLatestSources({commit}) {
    commit('SET_LATEST_SOURCES', []);

    return SourceApiService.getLatestSources()
      .then((data) => {
        commit('SET_LATEST_SOURCES', data);
        return data;
      })
      .catch((error) => console.error(error));
  },

  async getSourceData({commit, state}) {
    commit('SET_LOADING', {source: true});
    const params = {
      ...defaultParams,
      ...state.sourceParams
    };
    const query = queryString.stringify({
      ...params,
      page: params.page - 1 // Fix request page parameter
    });

    return SourceApiService.getSourceData(router.currentRoute.params.id, query)
      .then((data) => {
        commit('SET_SOURCE_DATA', data);
      })
      .finally(() => {
        commit('SET_LOADING', {source: false});
      });
  },
  async parseSourceParams({commit, dispatch}) {
    if (!queryString.stringify(queryParams())
      || queryString.stringify(queryParams())
      === queryString.stringify(defaultParams)) {
      commit('RESET_SOURCE_PARAMS');
    } else {
      commit('SET_SOURCE_PARAMS', queryParamsToParams(queryParams()));
    }

    return dispatch('getSourceData');
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
