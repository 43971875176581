import { VueConstructor } from 'vue';
import { LearnResourceType } from '@/interfaces/interfaces';

import AppIcon from '@/assets/icons/types/app.svg';
import CollectionIcon from '@/assets/icons/types/collection.svg';
import InteractionIcon from '@/assets/icons/types/interaction.svg';
import AudioIcon from '@/assets/icons/types/audio.svg';
import CourseIcon from '@/assets/icons/types/course.svg';
import ExperimentIcon from '@/assets/icons/types/experiment.svg';
import ImageIcon from '@/assets/icons/types/image.svg';
import LessonModuleIcon from '@/assets/icons/types/lesson-module.svg';
import MapIcon from '@/assets/icons/types/map.svg';
import OtherIcon from '@/assets/icons/types/other.svg';
import SimulationIcon from '@/assets/icons/types/simulation.svg';
import TestIcon from '@/assets/icons/types/test.svg';
import TextIcon from '@/assets/icons/types/text.svg';
import ToolIcon from '@/assets/icons/types/tool.svg';
import VideoIcon from '@/assets/icons/types/video.svg';
import WebsiteIcon from '@/assets/icons/types/website.svg';
import WorksheetIcon from '@/assets/icons/types/worksheet.svg';
import PackageIcon from '@/assets/icons/card/package.svg';


export type LearnResourceTypeIcon = {
  [key in LearnResourceType | string]: VueConstructor;
};

export const IconsLearnResourceTypeDictionary: LearnResourceTypeIcon = {
  app: AppIcon,
  arbeitsblatt: WorksheetIcon,
  audio: AudioIcon,
  audiovisuelles: OtherIcon,
  bild: ImageIcon,
  broschüre: OtherIcon,
  daten: OtherIcon,
  entdeckendes: OtherIcon,
  experiment: ExperimentIcon,
  fallstudie: OtherIcon,
  glossar: OtherIcon,
  handbuch: OtherIcon,
  interaktion: InteractionIcon,
  karte: MapIcon,
  kurs: CourseIcon,
  lernkontrolle: OtherIcon,
  lernspiel: OtherIcon,
  modell: OtherIcon,
  offene: OtherIcon,
  project: OtherIcon,
  quelle: OtherIcon,
  radio: OtherIcon,
  recherche: OtherIcon,
  ressourcentyp: OtherIcon,
  rollenspiel: OtherIcon,
  sammlung: CollectionIcon,
  simulation: SimulationIcon,
  software: OtherIcon,
  sonstiges: OtherIcon,
  test: TestIcon,
  text: TextIcon,
  ubung: OtherIcon,
  unterrichtsbaustein: LessonModuleIcon,
  unterrichtsplanung: OtherIcon,
  veranschaulichung: OtherIcon,
  video: VideoIcon,
  webseite: WebsiteIcon,
  webtool: ToolIcon,
  präsentation: OtherIcon,
  collection: PackageIcon,
};

export const getIconByLearnResourceType = (type: LearnResourceType | string) => {
  return IconsLearnResourceTypeDictionary[type] || OtherIcon;
};
