



































































import {Vue, Component, Watch} from 'vue-property-decorator';
import clickOutside from '@/directives/click-outside';

import HeaderSearchManager from './HeaderSearchManager.vue';
import HeaderUserMenu from '@/components/Layout/Header/HeaderUserMenu.vue';
import HeaderMenu from '@/components/Layout/Header/HeaderMenu.vue';

import LogoIcon from '@/assets/icons/logo.svg';
import MerklisteIcon from '@/assets/icons/merkliste.svg';
import UserIcon from '@/assets/icons/user.svg';
import SearchIcon from '@/assets/icons/search.svg';

@Component({
  components: {
    LogoIcon,
    HeaderSearchManager,
    HeaderUserMenu,
    HeaderMenu,
  },
  directives: { clickOutside },
})
export default class Header extends Vue {
  private MerklisteIcon = MerklisteIcon;
  private UserIcon = UserIcon;
  private SearchIcon = SearchIcon;

  @Watch('$route.name')
  private onRouteChange(): void {
    this.$store.dispatch('showOverlay', false);
  }

  private get classObject(): object {
    return {
      'header-bubble': !this.$store.getters.allowSearch && this.$route.name
    };
  }

  private get isAuthenticated() {
    return this.$store.getters.isAuthenticated;
  }

  private get isSearchButtonVisible() {
    return this.$route.name === 'search'
      || this.$route.name === 'lti-search'
      || this.$route.name === 'export-search'
      ? false
      : this.$store.getters.isMobileAdaptiveMode
        && !this.$store.getters.getFiltersVisibility
        && this.$store.getters.allowSearch;
  }

  private get showMobileFilters() {
    switch (this.$route.name) {
      case 'main':
        return false;
      case 'search':
        return this.$store.getters.isMobileAdaptiveMode;
      case 'lti-search':
        return this.$store.getters.isMobileAdaptiveMode;
      default:
        return this.$store.getters.getFiltersVisibility;
    }
  }
}
