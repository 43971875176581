







import { Vue, Component, Prop } from 'vue-property-decorator';
import { UISizeType } from '@/interfaces/interfaces';

@Component({})
export default class FloatBadge extends Vue {
  @Prop({
    type: String,
    required: false,
  })
  private size!: UISizeType;

  @Prop({
    type: String,
    required: false,
    default: 'white'
  })
  private color!: string;

  @Prop({
    type: String,
    required: false,
    default: 'gray'
  })
  private variant!: string;

  private get classObject(): object {
    return {
      [`text-${this.color}`]: true,
      [`bg-${this.variant}`]: true,
      'px-2 py-1': !this.size,
      'p-1 small line-height-xs': this.size === 'sm'
    };
  }
}
