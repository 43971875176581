import { IMedia, IPublisher, IPublishersData, IPublisherStat, ISource } from '@/interfaces/interfaces';
import { api } from '@/services/api/api.service';
import { AxiosResponse } from 'axios';

export interface IPublisherApiService {
  getPublisherById: (id: string) => Promise<IPublisher>;
  getPublisherStat: (id: string) => Promise<IPublisherStat>;
  getPublisherSources: (id: string) => Promise<ISource[]>;
  getPublisherPopularMedia: (id: string) => Promise<IMedia[]>;
  getLatestPublishers: () => Promise<IPublisher[]>;
  getPublishersData: (query: string) => Promise<IPublishersData>;
}

const getPublisherById = (id: string): Promise<IPublisher> => api
  .get(`mundo/publisher/${id}`)
  .then((response: AxiosResponse<IPublisher>) => response.data)
  .catch((error) => Promise.reject(error));

const getPublisherStat = (id: string): Promise<IPublisherStat> => api
  .get(`mundo/bubble/publisher/${id}`)
  .then((response: AxiosResponse<IPublisherStat>) => response.data)
  .catch((error) => Promise.reject(error));

const getPublisherSources = (id: string): Promise<ISource[]> => api
  .get(`mundo/source/publisher/${id}`)
  .then((response: AxiosResponse<Promise<ISource[]>>) => response.data)
  .catch((error) => Promise.reject(error));

const getPublisherPopularMedia = (id: string): Promise<IMedia[]> => api
  .get(`mundo/publisher/top20/${id}`)
  .then((response: AxiosResponse<Promise<IMedia[]>>) => response.data)
  .catch((error) => Promise.reject(error));

const getLatestPublishers = (): Promise<IPublisher[]> => api
  .get('mundo/publisher/newest')
  .then((response: AxiosResponse<IPublisher[]>) => response.data)
  .catch((error) => Promise.reject(error));

const getPublishersData = (query: string): Promise<IPublishersData> => api
  .get(`mundo/publisher?${query}`)
  .then((response: AxiosResponse<IPublishersData>) => response.data)
  .catch((error) => Promise.reject(error));

const SourceApiService: IPublisherApiService = {
  getPublisherById,
  getPublisherStat,
  getPublisherSources,
  getPublisherPopularMedia,
  getLatestPublishers,
  getPublishersData
};

export default SourceApiService;
