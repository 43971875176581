
















import { Vue, Component, Prop } from 'vue-property-decorator';
import { ICardInfo } from '@/interfaces/interfaces';

@Component( {} )
export default class CardInfo extends Vue {
  @Prop( {
    type: Array,
    required: true
  })
  private infoList!: ICardInfo[];

  @Prop( {
    type: String,
    required: false
  })
  private mode!: 'collection' | 'thumbnail';

  @Prop({
    type: Boolean,
    required: false
  })
  private inline!: boolean;

  private get classObject(): object {
    return {
      [`info-${this.mode}`]: !!this.mode,
      'info-inline': this.inline
    };
  }
}
