















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class CardCover extends Vue {
  @Prop({
    type: String,
    required: false
  })
  private coverLink!: string;

  @Prop({
    type: Boolean,
    required: false
  })
  private inline!: boolean;

  @Prop({
    type: String,
    required: false
  })
  private identifier!: string;

  @Prop({
    type: Boolean,
    required: false
  })
  private active!: boolean;

  @Prop({
    type: Boolean,
    required: false
  })
  private coverStripped!: boolean;

  @Prop({
    type: String,
    required: true
  })
  private alt!: string;

  @Prop({
    type: String,
    required: false
  })
  private coverImagePreviewClass!: string;

  private get cardCoverImage() {
    return this.coverLink.replace(/256\/256\/ds|480\/360\/ds/, '64/64/b(1,95)ds') || this.coverLink;
  }

  private get classObject(): object {
    return {
      'cover-inline': this.inline,
      'cover-active': this.active
    };
  }
}
