export type ValidationCodeType =
  | 101 // FIELD_INVALID_VALUE
  | 102 // FIELD_INVALID_LICENSE
  | 103 // FIELD_MUST_BE_NOT_NULL_AND_NOT_EMPTY
  | 104 // FIELD_MUST_BE_EMPTY
  | 105 // FIELD_MUST_BE_UNIQUE
  | 106 // FIELD_VALUE_TOO_LONG
  | 107 // FIELD_DATE_CONFLICT
  | 201 // OPERATION_REMOVE_SOURCE
  | 202 // OPERATION_REMOVE_PUBLISHER
  | 203 // OPERATION_USER_HAVE_NO_RIGHTS_TO_CREATE
  | 204 // OPERATION_USER_HAVE_NO_RIGHTS_TO_UPDATE
  | 205 // OPERATION_INVALID_SUGGESTION_OPERATION
  | 301 // PASSWORD_INVALID_PASSWORD
  | 302 // PASSWORD_WEAK_PASSWORD_SHORT
  | 303 // PASSWORD_WEAK_PASSWORD_NO_LETTERS
  | 304 // PASSWORD_WEAK_PASSWORD_NO_NUMBERS
  | 305 // PASSWORD_WEAK_PASSWORD_NO_SYMBOLS
  | 401 // RELATION_INCORRECT_OBJECTS_RELATION
  | 402 // RELATION_INVALID_EDUCATIONAL_FIELDS
  | 501 // EMAIL_NOT_EXIST
  | 502 // EMAIL_NOT_CONFIRMED
  | 601 // DEFAULT_VALIDATION
  | 602; // OBJECT_NOT_EXIST

export interface ValidationCodeMessages {
  de?: string;
  en?: string;
}

export const validationMessageDefaultLanguage = 'de';

// Common validation messages

export const validationMessageDictionary: Record<ValidationCodeType, ValidationCodeMessages> = {
  101: {
    de: 'Ungültiger Wert',
    en: 'Invalid value'
  },
  102: {
    de: 'Ungültige Lizenz',
    en: 'Invalid license'
  },
  103: {
    de: 'Feld darf nicht leer und nicht null sein',
    en: 'Field must not empty and not null'
  },
  104: {
    de: 'Feld muss null oder leer sein',
    en: 'Field must be null or empty'
  },
  105: {
    de: 'Wert muss eindeutig sein',
    en: 'Value must be unique'
  },
  106: {
    de: 'Feldwert zu lang',
    en: 'Field value too long'
  },
  107: {
    de: 'Invalid date fields',
    en: 'Ungültige Datumsfelder'
  },
  201: {
    de: 'Quelle kann nicht entfernt werden',
    en: 'Unable to remove source'
  },
  202: {
    de: 'Herausgeber kann nicht entfernt werden',
    en: 'Unable to remove publisher'
  },
  203: {
    de: 'Der Benutzer hat keine Rechte zum Erstellen',
    en: 'User have no rights to create'
  },
  204: {
    de: 'Der Benutzer hat keine Rechte zum Aktualisieren',
    en: 'User have no rights to update'
  },
  205: {
    de: 'Ungültiger Vorschlagsvorgang',
    en: 'Invalid suggestion operation'
  },
  301: {
    de: 'Ungültiges Passwort',
    en: 'Invalid password'
  },
  302: {
    de: 'Die Passwortlänge sollte mindestens 8 Zeichen betragen',
    en: 'The password length should be at least 8 characters'
  },
  303: {
    de: 'Das Passwort sollte mindestens einen Buchstaben enthalten',
    en: 'The password should contain at least one letter'
  },
  304: {
    de: 'Das Passwort sollte mindestens eine Zahl enthalten',
    en: 'The password should contain at least one number'
  },
  305: {
    de: 'Das Passwort sollte mindestens ein Sonderzeichen enthalten',
    en: 'The password should contain at least one special character'
  },
  401: {
    de: 'Ungültige Objektbeziehung',
    en: 'Invalid objects relation'
  },
  402: {
    de: 'Ungültige Felder für Bildungsbeziehungen',
    en: 'Invalid educational relation fields'
  },
  501: {
    de: 'Benutzer mit E-Mail existiert nicht',
    en: 'User with email does not exist'
  },
  502: {
    de: 'E-Mail nicht bestätigt',
    en: 'Email not confirmed'
  },
  601: {
    de: 'Validierungsfehler',
    en: 'Validation error'
  },
  602: {
    de: 'Objekt nicht vorhanden',
    en: 'Object does not exist'
  }
};

// Custom validation messages

export const validationFieldMessageDictionary: 
  Record<string, Partial<Record<ValidationCodeType, ValidationCodeMessages>>> = 
{
  email: {
    105: {
      de: 'Diese E-Mail wird bereits verwendet',
      en: 'This E-mail is already used'
    }
  },
  username: {
    105: {
      de: 'Benutzername bereits vorhanden',
      en: 'Username already exists'
    }
  }
};
