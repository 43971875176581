





import Vue from 'vue';
import Component from 'vue-class-component';
@Component({})
export default class UIPage extends Vue {}
