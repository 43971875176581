
import { UserRole } from '@/interfaces/interfaces';
import { Ability, AbilityBuilder } from '@casl/ability';

const abilityRules = (roles: Array<keyof typeof UserRole>) => {
  const { can, rules } = new AbilityBuilder<Ability>(Ability);

  // Admin

  if (roles && roles.includes(UserRole.ROLE_USER)) {
    can('manage', 'all');
  }

  return rules;
};

export default abilityRules;
