import {api} from '@/services/api/api.service';

export interface IPlayoutResponse {
  playoutUrl: string;
}

interface IPlayoutApiService {
  getPlayout: (id: string) => Promise<IPlayoutResponse>;
}

const getPlayout = (id: string): Promise<IPlayoutResponse> => api
  .get(`mundo/playout-window?mediaId=${id}`)
  .then((response) => response.data)
  .catch((error) => Promise.reject(error));

const PlayoutApiService: IPlayoutApiService = {
  getPlayout
};

export default PlayoutApiService;
