


































import { Vue, Component } from 'vue-property-decorator';
import clickOutside from '@/directives/click-outside';
import SearchInput from '@/components/Search/SearchInput.vue';
import TrailingIcon from '@/assets/icons/trailing.svg';
import CloseIcon from '@/assets/icons/close.svg';
import ArrowUpIcon from '@/assets/icons/arrow-up.svg';
import { scrollTo } from '@/utilities';

@Component({
  components: { SearchInput },
  directives: { clickOutside },
})
export default class HeaderSearchManager extends Vue {
  private TrailingIcon = TrailingIcon;
  private CloseIcon = CloseIcon;
  private ArrowUpIcon = ArrowUpIcon;
  private filtersInViewport: boolean = true;

  private get filtersIcon() {
    return this.filtersInViewport
      ? this.$store.getters.getFiltersVisibility
        ? CloseIcon
        : TrailingIcon
      : ArrowUpIcon;
  }

  private get closeFiltersText() {
    return !this.$store.getters.isTabletAdaptiveMode
      ? this.$store.getters.getFiltersVisibility && !this.filtersInViewport
        ? 'Filtern'
        : 'Schließen'
      : '';
  }

  private closeFilters() {
    if (this.$store.getters.isSmallMobileAdaptiveMode 
      && this.$route.name !== 'search'
      && this.$route.name !== 'lti-search'
      && this.$route.name !== 'export-search') {
      this.$store.dispatch(
        'showFilters',
        false
      );
    }
  }

  private showFilters() {
    if (this.$store.getters.getFiltersVisibility && !this.filtersInViewport) {
      scrollTo('.app-container', 'smooth');
      return;
    }
    this.$store.dispatch(
      'showFilters',
      !this.$store.getters.getFiltersVisibility
    );
  }

  private mounted() {
    this.$root.$on(
      'filtersVisibility',
      (value: boolean) => this.filtersInViewport = value
    );
  }
}
