import {DirectiveOptions, VNode} from 'vue';
import {DirectiveBinding} from 'vue/types/options';
import {Vue} from 'vue/types/vue';
interface INamedVue extends Vue {
  name?: string;
}
interface IClickOutsideHTMLElement extends HTMLElement {
  __vueClickOutside__: EventListenerOrEventListenerObject;
}
interface INoClickOutsideHTMLElement extends HTMLElement {
  __vueClickOutside__: EventListenerOrEventListenerObject | null;
}

const directive: DirectiveOptions = {
  bind(el: HTMLElement, binding: DirectiveBinding, vNode: VNode): void {
    if (typeof binding.value !== 'function') {
      const component: undefined | INamedVue = vNode.context;
      const componentName: undefined | string = component ? component.name : undefined;
      let warn = `[Vue-click-outside:] provided expression '${binding.expression}' is not a function, but has to be`;
      if (componentName) {
        warn += `Found in component '${componentName}'`;
      }
      console.warn(warn);
    }
    const bubble = binding.modifiers.bubble;
    const handler: EventListener = (event: Event): void => {
      if (bubble || (!el.contains((event.target as (Node | null))) && el !== event.target)) {
        binding.value(event);
      }
    };
    (el as IClickOutsideHTMLElement).__vueClickOutside__ = handler;
    document.addEventListener('click', handler);
  },

  unbind(el: HTMLElement): void {
    document.removeEventListener('click', (el as IClickOutsideHTMLElement).__vueClickOutside__);
    (el as INoClickOutsideHTMLElement).__vueClickOutside__ = null;
  },
};

export default directive;
