import Vue from 'vue';
import Vuex from 'vuex';
import ui, {IUiState} from './modules/ui/ui.store';
import dictionary, {IDictionaryState} from './modules/dictionary/dictionary.store';
import search, {ISearchState} from './modules/search.store';
import filters, {ISearchFiltersState} from './modules/search.filters.store';
import media, {IMediaState} from './modules/media.store';
import slider, {ISliderState} from './modules/slider.store';
import publisher, {IPublisherState} from './modules/publisher.store';
import source, {ISourceState} from './modules/source.store';
import analytic, {IAnalyticState} from './modules/analytic.store';
import auth, {IAuthState} from './modules/auth.store';
import user, {IUserState} from './modules/user.store';
import features, {IFeaturesState} from './modules/features.store';
import suggest, {ISuggestState} from './modules/suggest.store';
import userRecord, {IUserRecordState} from './modules/user-record.store';
import bookmark, {IBookmarkState} from './modules/bookmark.store';
import upload, {IUploadState} from './modules/upload.store';

import cms, {ICmsState} from './modules/cms/cms.store';
import cmsMain, {ICmsMainState} from './modules/cms/cms.main.store';
import cmsFaq, {ICmsFAQState} from './modules/cms/cms.faq.store';
import cmsStaticPages, {ICmsStaticPagesState} from './modules/cms/cms.static-pages.store';
import playout, {IPlayoutState} from '@/store/modules/ui/playout.store';

Vue.use(Vuex);

export interface RootState {
  ui: IUiState;
  dictionary: IDictionaryState;
  search: ISearchState;
  filters: ISearchFiltersState;
  media: IMediaState;
  slider: ISliderState;
  publisher: IPublisherState;
  source: ISourceState;
  analytic: IAnalyticState;
  auth: IAuthState;
  user: IUserState;
  features: IFeaturesState;
  suggest: ISuggestState;
  userRecord: IUserRecordState;
  bookmark: IBookmarkState;
  upload: IUploadState;
  playout: IPlayoutState;
  cms: ICmsState;
  cmsMain: ICmsMainState;
  cmsFaq: ICmsFAQState;
  cmsStaticPages: ICmsStaticPagesState;
}

export default new Vuex.Store<RootState>({
  modules: {
    playout,
    ui,
    dictionary,
    search,
    filters,
    media,
    slider,
    publisher,
    source,
    analytic,
    auth,
    user,
    features,
    suggest,
    userRecord,
    bookmark,
    upload,
    cms,
    cmsMain,
    cmsFaq,
    cmsStaticPages,
  }
});
