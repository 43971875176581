import {api} from '@/services/api/api.service';
import {AxiosResponse} from 'axios';
import {DictionaryModuleName, DictionaryModuleType, IResponse, Subject} from '@/interfaces/interfaces';
import queryString from 'query-string';
import { ILicenseDependenciesPayload } from '../dependencies/license.service';

export interface IGlobalDictionary {
  id: string;
  name: string;
  dictionaryValueMap: IGlobalDictionaryValueMap;
}

export interface IGlobalDictionaryValueMap {
  [key: string]: {
    [key: string]: string;
  };
}

export interface IEducationaDictionaryItem {
  educationalLevel: string;
  typeOfSchool: string;
  classLevel: number[];
  ages: number[];
}

interface IDictionaryApiService {
  receiveGlobalDictionary: (dictionary: string) => Promise<IGlobalDictionary>;
  receiveFacetSubjectsDictionary: () => Promise<IFacetSubjectDictionary>;
  receiveFacetSchoolTypesDictionary: () => Promise<Record<string, string>>;
  receiveFacetLicensesDictionary: () => Promise<Record<string, string>>;
  receiveMetadataLicenseDictionary: (
    params: Record<keyof typeof DictionaryModuleName, keyof typeof DictionaryModuleType>
  ) => Promise<IResponse<ILicenseDependenciesPayload[]>>;
  receiveMetadataEducationalDictionary: () => Promise<IResponse<IEducationaDictionaryItem[]>>;
}
export interface IFacetSubject {
  orderIndex?: number;
  category?: string;
  subCategory?: Subject;
  items?: IFacetSubject[];
  $subjectId?: string;
  id?: string;
  name?: Subject;
  subjectName?: Subject;
  level?: number;
  childSubjectMap?: {
    [key: string]: Subject; 
  }
}

export interface IFacetSubjectDictionary {
  [key: string]: IFacetSubject;
}

const receiveGlobalDictionary = (dictionary: string): Promise<IGlobalDictionary> => api
  .get(`mundo/dictionary/${encodeURIComponent(dictionary)}`)
  .then((response: AxiosResponse<IGlobalDictionary>) => response.data)
  .catch((error) => Promise.reject(error));

const receiveFacetSubjectsDictionary = (): Promise<IFacetSubjectDictionary> => api
  .get('mundo/facet/subjects')
  .then((response: AxiosResponse<Promise<IFacetSubjectDictionary>>) => response.data)
  .catch((error) => Promise.reject(error));

const receiveFacetSchoolTypesDictionary = (): Promise<Record<string, string>> => api
  .get('mundo/facet/school-types')
  .then((response: AxiosResponse<Promise<Record<string, string>>>) => response.data)
  .catch((error) => Promise.reject(error));

const receiveFacetLicensesDictionary = (): Promise<Record<string, string>> => api
  .get('mundo/facet/licenses')
  .then((response: AxiosResponse<Promise<Record<string, string>>>) => response.data)
  .catch((error) => Promise.reject(error));
  
const receiveMetadataLicenseDictionary = (
  params: Record<keyof typeof DictionaryModuleName, keyof typeof DictionaryModuleType>
): Promise<IResponse<ILicenseDependenciesPayload[]>> => api
  .get(`mundo/dictionary/licenses?${queryString.stringify(params)}`)
  .then((response: AxiosResponse<IResponse<ILicenseDependenciesPayload[]>>) => response.data)
  .catch((error) => Promise.reject(error));

const receiveMetadataEducationalDictionary = (): Promise<IResponse<IEducationaDictionaryItem[]>> => api
  .get('mundo/dictionary/educational')
  .then((response: AxiosResponse<IResponse<IEducationaDictionaryItem[]>>) => response.data)
  .catch((error) => Promise.reject(error));

const DictionaryApiService: IDictionaryApiService = {
  receiveGlobalDictionary,
  receiveFacetSubjectsDictionary,
  receiveFacetSchoolTypesDictionary,
  receiveFacetLicensesDictionary,
  receiveMetadataLicenseDictionary,
  receiveMetadataEducationalDictionary
};

export default DictionaryApiService;
