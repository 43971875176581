
export const isEmptyParameter = (value: any): boolean => {
  return typeof value === 'object'
    && !Object.keys(value).length
    || Array.isArray(value)
    && !value.length
    || typeof value === 'undefined'
    || value === null
    || value === '';
};
