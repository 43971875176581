import { IUserRecord, IUserRecordsData } from '@/interfaces/interfaces';
import { AxiosResponse } from 'axios';
import { api } from './api.service';
import { IFacetSubjectDictionary } from './dictionary.service';

export interface IUserRecordsDataSearchParams {
  size?: string;
  page?: string;
  title?: string;
}

export interface IUserRecordApiService {
  getUserRecordById: (id: string) => Promise<IUserRecord>;
  getUserRecordsData: (query: string, body?: Record<string, string>) => Promise<IUserRecordsData>;
  saveUserRecord: (record: IUserRecord) => Promise<IUserRecord>;
  submitUserRecord: (id: string) => Promise<IUserRecord>;
  removeUserRecord: (id: string) => Promise<IUserRecord>;
  updateUserRecord: (id: string) => Promise<IUserRecord>;
  getUserRecordSubjectsDictionary: () => Promise<IFacetSubjectDictionary>;
}

const getUserRecordById = (id: string): Promise<IUserRecord> => api
  .get(`mundo/user-suggestion/${id}`)
  .then((response: AxiosResponse<IUserRecord>) => response.data)
  .catch((error) => Promise.reject(error));

const getUserRecordsData = (query: string, body?: Record<string, string>): Promise<IUserRecordsData> => api
  .post(`mundo/user-suggestion/search${query}`, body || {})
  .then((response: AxiosResponse<IUserRecordsData>) => response.data)
  .catch((error) => Promise.reject(error));

const saveUserRecord = (record: IUserRecord): Promise<IUserRecord> => api
  .post('mundo/user-suggestion/draft', record)
  .then((response: AxiosResponse<IUserRecord>) => response.data)
  .catch((error) => Promise.reject(error));

const submitUserRecord = (id: string): Promise<IUserRecord> => api
  .post(`mundo/user-suggestion/submit/${id}`)
  .then((response: AxiosResponse<IUserRecord>) => response.data)
  .catch((error) => Promise.reject(error));

const removeUserRecord = (id: string): Promise<IUserRecord> => api
  .delete(`mundo/user-suggestion/${id}`)
  .then((response: AxiosResponse<IUserRecord>) => response.data)
  .catch((error) => Promise.reject(error));

const updateUserRecord = (id: string): Promise<IUserRecord> => api
  .post(`mundo/user-suggestion/update/${id}`)
  .then((response: AxiosResponse<IUserRecord>) => response.data)
  .catch((error) => Promise.reject(error));

const getUserRecordSubjectsDictionary = (): Promise<IFacetSubjectDictionary> => api
  .get('mundo/user-suggestion/subjects')
  .then((response: AxiosResponse<IFacetSubjectDictionary>) => response.data)
  .catch((error) => Promise.reject(error));

const UserRecordApiService: IUserRecordApiService = {
  getUserRecordById,
  getUserRecordsData,
  saveUserRecord,
  submitUserRecord,
  removeUserRecord,
  updateUserRecord,
  getUserRecordSubjectsDictionary
};

export default UserRecordApiService;
