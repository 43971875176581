




















import { Vue, Component, Prop } from 'vue-property-decorator';
import { UISizeType } from '@/interfaces/interfaces';
import { BButton, BLink } from 'bootstrap-vue';

const defaultSizeIcon = 24;

@Component({
  components: {
    BButton, BLink
  }
})
export default class Button extends Vue {
  @Prop({
    type: Object,
    required: false,
    default: null
  })
  private icon!: string | null;

  @Prop({
    type: String,
    required: false,
    default: null
  })
  private char!: string | null;

  @Prop({
    type: String,
    required: false,
  })
  private size!: UISizeType;

  @Prop({
    type: Number,
    required: false,
    default: defaultSizeIcon
  })
  private widthIcon!: number;

  @Prop({
    type: Number,
    required: false,
    default: defaultSizeIcon
  })
  private heightIcon!: number;

  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  private reverse!: boolean;

  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  private fab!: boolean;

  private get classObject(): object {
    return {
      'btn': this.$attrs.link,
      [`btn-${this.$attrs.variant}`]: !!this.$attrs.variant && this.$attrs.link,
      [`${this.size}`]: !!this.size,
      'reverse': this.reverse,
      'fab': this.fab,
    };
  }

  private get iconStyleObject(): object {
    return {
      'width': `${this.widthIcon}px`,
      'height': `${this.heightIcon}px`,
    };
  }

  private get component() {
    return this.$attrs.link
      ? 'b-link'
      : 'b-button';
  }
}
