import { CookieService } from './cookie.service';
import { set, del} from 'idb-keyval';

export interface ITokenService {
  getToken: () => string | null;
  setToken: (token: string | null) => void;
  resetToken: () => void;
}

const cookieToken = CookieService.get('authorizationToken');
let token: string | null = (!cookieToken === null || cookieToken === 'null')
  ? null
  : cookieToken;

const getToken = (): string | null => token;
const setToken = (value: string | null): void => {
  token = value;
  CookieService.set('authorizationToken', token);
  set('authorizationToken', token);
  
};
const resetToken = (): void => {
  CookieService.remove('authorizationToken', {path: '/'});
  del('authorizationToken');
  token = null;
};

const TokenService: ITokenService = {
  getToken,
  setToken,
  resetToken,
};
export default TokenService;
