import { getField, updateField } from 'vuex-map-fields';
import { ActionTree, GetterTree } from 'vuex';
import { RootState } from '@/store';
import { IExportedMedia, IMedia, LearnResourceType } from '@/interfaces/interfaces';
import MediaApiService from '@/services/api/media.service';
import { truncateString } from '@/utilities';
import {AxiosRequestConfig} from 'axios';
import { TitlesLearnResourceTypeDictionary } from '@/dictionaries/titles/titles.learnResourceType.dictionary';

const recommendedMediaCategorySortMap = [
  'byKeywords', 'bySubject',
  'bySource', 'byMediaType'
];

export interface IMediaState {
  media: IMedia | null;
  relatedMediaList: IMedia[];
  collectedMedias: IMedia[];
  mediaChildren: IMedia[];
  recommendedMedia: Record<string, IMedia[]> | null;
  pdfSourceController: AbortController | null;
}

const initialState = (): IMediaState => ({
  media: null,
  relatedMediaList: [],
  collectedMedias: [],
  mediaChildren: [],
  recommendedMedia: null,
  pdfSourceController: null
});

const state = initialState();

const getters: GetterTree<IMediaState, RootState> = {
  getMediaField: (state: IMediaState) => getField(state),
  getMediaMetaTitle: (state: IMediaState) => state.media ? state.media.title : '',
  getMediaMetaDescription: (state: IMediaState) => state.media && state.media.description
    ? truncateString(state.media.description, 155)
    : '',
  getMediaDetailsUrl: (_state, _getters, _rootState, rootGetters) => (identifier: string) => {
    const path = rootGetters.isLtiViewMode
      ? 'lti-details'
      : rootGetters.isExportViewMode
        ? 'export-details'
        : 'details';
    return `/${path}/${identifier}`;
  }
};

const mutations = {
  ['SET_MEDIA'](state: IMediaState, payload: IMedia) {
    state.media = payload;
  },
  ['SET_COLLECTED_MEDIA'](state: IMediaState, payload: IMedia[]) {
    state.collectedMedias = payload;
  },
  ['SET_RELATED_MEDIA_LIST'](state: IMediaState, payload: IMedia[]) {
    state.relatedMediaList = payload;
  },
  ['SET_RECOMMENDED_MEDIA'](state: IMediaState, payload: Record<string, IMedia[]>) {
    state.recommendedMedia = payload
      ? Object.keys(payload)
        .filter((key) => !!payload[key])
        .sort(
          (a, b) =>
          recommendedMediaCategorySortMap.indexOf(a)
          - recommendedMediaCategorySortMap.indexOf(b)
        )
        .reduce(
          (acc, key) =>
          (payload[key] === null ? acc : {...acc, [key]: payload[key]}),
          {}
        )
      : null;
  },
  ['SET_MEDIA_CHILDREN'](state: IMediaState, payload: IMedia[]) {
    state.mediaChildren = payload;
  },

  updateMediaField(state: IMediaState, field: string) {
    return updateField(state, field);
  }
};

const actions: ActionTree<IMediaState, RootState> = {
  getMediaById({commit}, id: string) {
    commit('SET_MEDIA', null);
    commit('SET_RELATED_MEDIA_LIST', []);
    commit('SET_LOADING', {media: true});

    return MediaApiService.getMediaById(id)
      .then((data) => {
        commit('SET_MEDIA', data);
        return data;
      })
      .catch((error) => console.error(error))
      .finally(() => {
        commit('SET_LOADING', {media: false});
      });
  },
  getMediaPreviewById({commit}, id: string) {
    commit('SET_MEDIA', null);
    commit('SET_LOADING', {media: true});

    return MediaApiService.getMediaPreviewById(id)
      .then((data) => {
        // FIXME Temporary fix for correct LRT displaying. Need to be removed after BE fix.
        const result = {
          ...data,
          learnResourceType: data.learnResourceType?.map(item => {
            return Object.keys(TitlesLearnResourceTypeDictionary)
              .find(key => TitlesLearnResourceTypeDictionary[key as LearnResourceType] === item)!;
          })
        } as IMedia;

        commit('SET_MEDIA', result);
        return result;
      })
      .catch((error) => console.error(error))
      .finally(() => {
        commit('SET_LOADING', {media: false});
      });
  },
  getRelatedMediaList({commit}, idList: string[]): Promise<void> {
    commit('SET_RELATED_MEDIA_LIST', []);
    return MediaApiService.getLinkedMediaByIdList(idList)
      .then((data) => {
        commit('SET_RELATED_MEDIA_LIST', data.linkedObjects);
      })
      .catch((error) => console.error(error));
  },
  getMediaChildren({commit}, id: string): Promise<void> {
    commit('SET_MEDIA_CHILDREN', []);
    return MediaApiService.getMediaById(id)
      .then((data) => {
        commit('SET_MEDIA_CHILDREN', data.children);
      })
      .catch((error) => console.error(error));
  },
  getRecommendedMedia({commit}, id: string) {
    commit('SET_RECOMMENDED_MEDIA', null);
    return MediaApiService.getRecommendedMediaById(id)
      .then((data) => {
        commit('SET_RECOMMENDED_MEDIA', data);
      })
      .catch((error) => console.error(error));
  },
  rateMedia({commit, state}, payload: {id: string, value: number}) {
    return MediaApiService.rateMedia(payload.id, payload.value)
      .then((data) => {
        commit('SET_MEDIA', {...state.media, rating: data, $rated: true});
      })
      .finally(() => {
        commit('SET_MEDIA', {...state.media, $rated: true});
      });
  },
  embedMedia({commit}, id: string) {
    commit('SET_LOADING', {lti: true});

    return MediaApiService.embedMedia(id)
      .then(() => {
        commit('SET_LOADING', {lti: false});
      })
      .catch(() => {
        commit('SET_LOADING', {lti: false});
      });
  },
  exportMediaPostMessage(_ctx, media: IMedia) {
    const mediaLink =
      !media.dataType ||
      media.dataType === '' ||
      media.dataType === 'text/html'
      ? media.url || media.originalUrl || media.downloadUrl
      : media.downloadUrl;

    const exportedMedia: IExportedMedia = {
      type: 'SODIX',
      mediaId: media.identifier!,
      title: media.title || '',
      description: media.description || '',
      media: mediaLink,
      dataType: media.dataType || '',
      thumbnail: media.thumbDetails || '',
      license: {
        name: media.license?.name || '',
        version: media.license?.version || '',
        country: media.license?.country || '',
        url: media.license?.url || '',
        text: media.license?.text || ''
      }
    };
    parent.postMessage({type: 'exportMedia', identifier: media.identifier, media: exportedMedia}, '*');
    console.warn(`Media ${media.identifier} successfully exported!`);
  },
  getMediaByUrl({commit, state}, url: string) {
    commit('SET_LOADING', {mediaFile: true});
    state.pdfSourceController = new AbortController();
    const pdfSource = state.pdfSourceController.signal;
    const config: AxiosRequestConfig = {
      signal: pdfSource,
      responseType: 'arraybuffer'
    };
    return MediaApiService.getMediaByUrl(url, config)
      .then((data) => data)
      .finally(() => {
        commit('SET_LOADING', {mediaFile: false});
      });
  },
  closePdfLoading({commit, state}) {
    if (state.pdfSourceController) {
      state.pdfSourceController.abort();
      state.pdfSourceController = null;
    }
    commit('SET_LOADING', {mediaFile: false});
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
