






















import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { debounce } from '@/utilities';
import clickOutside from '@/directives/click-outside';
import { UISizeType } from '@/interfaces/interfaces';
import SearchIcon from '@/assets/icons/search.svg';

import { createHelpers } from 'vuex-map-fields';
const { mapFields } = createHelpers({
  getterType: 'getSearchField',
  mutationType: 'updateSearchField',
});

@Component({
  computed: { ...mapFields([ 'search' ]) },
  components: { SearchIcon },
  directives: { clickOutside },
})
export default class SearchInput extends Vue {
  public search!: string;
  public suggestList: string[] = [];
  public copiedSuggestList: string[] = [];

  @Prop({
    type: String,
    required: false
  })
  public size!: UISizeType;

  @Prop({
    type: String,
    required: false
  })
  public placeholder!: string;

  public get isMainPage() {
    return this.$route.name === 'main';
  }

  public asyncSearch = debounce((query: string) => this.request(query), 300);

  public request(query: string): void {
    if (query.length) {
      this.$store.dispatch('requestSuggest', query)
        .then((data) => this.suggestList = data.options);
    }
  }

  public onChange(value: string) {
    this.asyncSearch(value);
    if (!value) {
      this.resetSuggestList(true);
    }
  }

  public onSearch(): void {
    this.$store.dispatch( 'onChangeSearch' );
    this.resetSuggestList(true);
  }

  public setSearch(value: string): void {
    this.search = value;
    this.onSearch();
    this.resetSuggestList(true);
  }

  public onFocus() {
    this.$emit('focused', true);
    if (this.copiedSuggestList.length) {
      this.suggestList = [ ...this.copiedSuggestList ];
    }
  }

  public onBlur(): void {
    this.$emit('focused', false);
  }

  public resetSuggestList(isResetCopiedList: boolean = false) {
    if (this.suggestList.length) {
      this.copiedSuggestList = [ ...this.suggestList ];
    }
    if (isResetCopiedList) {
      this.copiedSuggestList = [];
    }

    this.suggestList = [];
  }

  @Watch('suggestList', { immediate: true })
  public onSuggestListChanged(): void {
    if (!this.isMainPage) {
      this.$store.dispatch(
        'showOverlay',
        !!this.suggestList.length
      );
    }
  }
}
