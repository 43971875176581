


























import { Vue, Component } from 'vue-property-decorator';
import CloseIcon from '@/assets/icons/close.svg';

@Component
export default class Menu extends Vue {
  private opened: boolean = false;
  private CloseIcon = CloseIcon;

  private mounted() {
    (this.$el as any).focus();
  }

  private close() {
    this.opened = false;
  }

  private get isMainPage(): boolean {
    return this.$route.name === 'main';
  }

  private showOverlay(input: boolean): void {
    if (input) {
      (document.activeElement as HTMLElement).blur();
      document.body.addEventListener('keydown', (event: KeyboardEvent) => {
        if (event.keyCode === 27) {
          this.close();
        }
      });
    } 
    this.$store.dispatch('showOverlay', [
      input,
      this.isMainPage ? input : false
    ]);
  }
}
