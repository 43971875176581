import { RouteConfig } from 'vue-router';

const developmentRoutes: RouteConfig[] = [];

if (process.env.NODE_ENV !== 'production') {
  developmentRoutes.push({
    path: '/ui',
    name: 'ui',
    component: () => import(/* webpackChunkName: "UI" */ '@/pages/UI/UI.vue'),
    meta: {
      layout: 'ui',
      analyticsIgnore: true
    },
    children: [
      {
        name: 'ui-colors',
        component: () => import(/* webpackChunkName: "UI" */ '@/pages/UI/UI.colors.vue'),
        path: 'colors',
        meta: {
          layout: 'ui',
          analyticsIgnore: true
        },
      },
      {
        name: 'ui-typography',
        component: () => import(/* webpackChunkName: "UI" */ '@/pages/UI/UI.typography.vue'),
        path: 'typography',
        meta: {
          layout: 'ui',
          analyticsIgnore: true
        },
      },
      {
        name: 'ui-buttons',
        component: () => import(/* webpackChunkName: "UI" */ '@/pages/UI/UI.buttons.vue'),
        path: 'buttons',
        meta: {
          layout: 'ui',
          analyticsIgnore: true
        },
      },
      {
        name: 'ui-badges',
        component: () => import(/* webpackChunkName: "UI" */ '@/pages/UI/UI.badges.vue'),
        path: 'badges',
        meta: {
          layout: 'ui',
          analyticsIgnore: true
        },
      },
      {
        name: 'ui-dropdowns',
        component: () => import(/* webpackChunkName: "UI" */ '@/pages/UI/UI.dropdowns.vue'),
        path: 'dropdowns',
        meta: {
          layout: 'ui',
          analyticsIgnore: true
        },
      },
      {
        name: 'ui-chips',
        component: () => import(/* webpackChunkName: "UI" */ '@/pages/UI/UI.chips.vue'),
        path: 'chips',
        meta: {
          layout: 'ui',
          analyticsIgnore: true
        },
      },
      {
        name: 'ui-inputs',
        component: () => import(/* webpackChunkName: "UI" */ '@/pages/UI/UI.inputs.vue'),
        path: 'inputs',
        meta: {
          layout: 'ui',
          analyticsIgnore: true
        },
      },
      {
        name: 'ui-controls',
        component: () => import(/* webpackChunkName: "UI" */ '@/pages/UI/UI.controls.vue'),
        path: 'controls',
        meta: {
          layout: 'ui',
          analyticsIgnore: true
        },
      },
      {
        name: 'ui-tabs',
        component: () => import(/* webpackChunkName: "UI" */ '@/pages/UI/UI.tabs.vue'),
        path: 'tabs',
        meta: {
          layout: 'ui',
          analyticsIgnore: true
        },
      },
      {
        name: 'ui-cards',
        component: () => import(/* webpackChunkName: "UI" */ '@/pages/UI/UI.cards.vue'),
        path: 'cards',
        meta: {
          layout: 'ui',
          analyticsIgnore: true
        },
      },
      {
        name: 'ui-pagination',
        component: () => import(/* webpackChunkName: "UI" */ '@/pages/UI/UI.pagination.vue'),
        path: 'pagination',
        meta: {
          layout: 'ui',
          analyticsIgnore: true
        },
      },
      {
        name: 'ui-carousel',
        component: () => import(/* webpackChunkName: "UI" */ '@/pages/UI/UI.carousel.vue'),
        path: 'carousel',
        meta: {
          layout: 'ui',
          analyticsIgnore: true
        },
      },
      {
        name: 'ui-modals',
        component: () => import(/* webpackChunkName: "UI" */ '@/pages/UI/UI.modals.vue'),
        path: 'modals',
        meta: {
          layout: 'ui',
          analyticsIgnore: true
        },
      },
      {
        name: 'ui-panel',
        component: () => import(/* webpackChunkName: "UI" */ '@/pages/UI/UI.panel.vue'),
        path: 'panel',
        meta: {
          layout: 'ui',
          analyticsIgnore: true
        },
      },
      {
        name: 'ui-rating',
        component: () => import(/* webpackChunkName: "UI" */ '@/pages/UI/UI.rating.vue'),
        path: 'rating',
        meta: {
          layout: 'ui',
          analyticsIgnore: true
        },
      },
      {
        name: 'ui-uploaders',
        component: () => import(/* webpackChunkName: "UI" */ '@/pages/UI/UI.uploaders.vue'),
        path: 'uploaders',
        meta: {
          layout: 'ui',
          analyticsIgnore: true
        },
      },
    ]
  });
}

export default developmentRoutes;
