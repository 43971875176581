






import Vue, { VueConstructor } from 'vue';
import { IUILayout } from '@/interfaces/interfaces';
import Component from 'vue-class-component';
import { AdaptiveMode } from '@/store/modules/ui/ui.store';
import { debounce } from '@/utilities/debounce';
import { uiLayoutsDictionary } from '@/dictionaries/ui/ui.layouts.dictionary';

@Component({
  metaInfo: { titleTemplate: '%s - MUNDO' },
})
export default class AppComponent extends Vue {
  public get layout(): VueConstructor | null {
    if (this.$route.name) {
      const name = this.$route.meta.layout || 'default';
      return uiLayoutsDictionary.filter((item: IUILayout) => item.name === name)[0].component;
    } else {
      return uiLayoutsDictionary[0].component;
    }
  }

  private resizeDebounceTimeout = 25;
  public created() {
    this.resizeHandler();
  }
  public mounted() {
    this.vhUnitsFix();
    window.addEventListener(
      'resize',
      debounce(this.resizeHandler, this.resizeDebounceTimeout),
      {passive: true}
    );
    this.$store.commit('SET_IS_BLUR_DISABLED');
  }
  private beforeDestroy() {
    window.removeEventListener(
      'resize',
      this.resizeHandler,
    );
  }
  private vhUnitsFix() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }
  private resizeHandler() {
    this.vhUnitsFix();
    const viewPort = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    this.$store.commit('SET_VIEW_PORT', viewPort);
    this.$root.$emit('setViewPort', viewPort);

    if (viewPort < 576) {
      if (this.$store.state.ui.adaptiveMode !== AdaptiveMode.XS) {
        this.$store.commit('SET_ADAPTIVE_MODE', AdaptiveMode.XS);
      }
    } else if (viewPort < 768) {
      if (this.$store.state.ui.adaptiveMode !== AdaptiveMode.SM) {
        this.$store.commit('SET_ADAPTIVE_MODE', AdaptiveMode.SM);
      }
    } else if (viewPort < 1024) {
      if (this.$store.state.ui.adaptiveMode !== AdaptiveMode.MD) {
        this.$store.commit('SET_ADAPTIVE_MODE', AdaptiveMode.MD);
      }
    } else if (viewPort < 1200) {
      if (this.$store.state.ui.adaptiveMode !== AdaptiveMode.LG) {
        this.$store.commit('SET_ADAPTIVE_MODE', AdaptiveMode.LG);
      }
    } else {
      if (this.$store.state.ui.adaptiveMode !== AdaptiveMode.XLG) {
        this.$store.commit('SET_ADAPTIVE_MODE', AdaptiveMode.XLG);
      }
    }
  }
}
