import { Language } from '@/interfaces/interfaces';
export const LanguageTitlesDictionary: Record<Language, string> = {
  GERMAN: 'Deutsch',
  ENGLISH: 'Englisch',
  FRENCH: 'Französisch',
  SIGN_LANGUAGE: 'Gebärdensprache',
};
export const getLanguageNameByValue = (value: string): Language | undefined => {
  return Object.keys(LanguageTitlesDictionary).find(key => LanguageTitlesDictionary[key as Language] === value) as Language | undefined;
};


