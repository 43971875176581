









import Vue, { VueConstructor } from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class CardType extends Vue {
  @Prop({
    type: Object,
    required: true
  })
  private icon!: VueConstructor;

  @Prop({
    type: String,
    required: true
  })
  private alt!: string;
}
