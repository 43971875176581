import { ActionTree, GetterTree } from 'vuex';
import { RootState } from '@/store';
import {ISearchData, IUser} from '@/interfaces/interfaces';
import {getField, updateField} from 'vuex-map-fields';
import UserApiService, {IUserProfile} from '@/services/api/user.service';
import {cloneObject} from '@/utilities';
import SearchApiService from '@/services/api/search.service';

const defaultUserDictionary: IUser = {
  id: null,
  email: '',
  password: '',
  username: '',
  phone: null,
  roles: null,
  enabled: null
};

const defaultUserProfileDictionary: IUserProfile = {
  firstName: '',
  lastName: '',
  aboutMe: '',
  disciplines: [],
  externalId: '',
  id: '',
  land: '',
  pictureId: null,
  position: '',
  publicInfo: {
    disciplines: false,
    land: false,
    position: false,
    schoolTypes: false,
  },
  schoolTypes: []
};

export interface IUserState {
  user: IUser;
  initialUserProfile: IUserProfile|null;
  userProfile: IUserProfile;
  publicProfile: IUserProfile;
  publicMediaData?: ISearchData;
}

const initialState = (): IUserState => ({
  user: { ...defaultUserDictionary },
  initialUserProfile: null,
  userProfile: cloneObject(defaultUserProfileDictionary),
  publicProfile: cloneObject(defaultUserProfileDictionary),
  publicMediaData: undefined
});

const state = initialState();

const getters: GetterTree<IUserState, RootState> = {
  getUserField: (state: IUserState) => getField(state),
  user: (state: IUserState) => state.user,
  userProfile: (state: IUserState) => state.userProfile,
  userFullName: (state: IUserState) => `${state.userProfile.firstName} ${state.userProfile.lastName}`,
  initialUserProfile: (state: IUserState) => state.initialUserProfile,
  userProfilePhoto: (state: IUserState) => state.initialUserProfile!.pictureId || null
};

const mutations = {
  updateUserField(state: IUserState, field: string) {
    return updateField(state, field);
  },
  ['SET_USER'](state: IUserState, payload: IUser) {
    state.user = payload;
  },
  ['RESET_USER'](state: IUserState) {
    state.user = { ...defaultUserDictionary };
  },
  ['SET_INITIAL_USER_PROFILE'](state: IUserState, payload: IUserProfile) {
    state.initialUserProfile = cloneObject(payload);
  },
  ['SET_USER_PROFILE'](state: IUserState, payload: IUserProfile) {
    state.userProfile = payload;
  },
  ['SET_PUBLIC_PROFILE'](state: IUserState, payload: IUserProfile) {
    state.publicProfile = payload;
  },
  ['SET_PUBLIC_MEDIA'](state: IUserState, payload: ISearchData) {
    state.publicMediaData = payload;
  },
  ['RESET_USER_PROFILE'](state: IUserState) {
    state.userProfile = cloneObject(
      state.initialUserProfile
        ? state.initialUserProfile
        : defaultUserProfileDictionary
    );
  },
};

const actions: ActionTree<IUserState, RootState> = {
  receiveUserProfile: ({commit}) => {
    return UserApiService.receiveUserProfile()
      .then((data: IUserProfile) => {
        commit('SET_INITIAL_USER_PROFILE', data);
        commit('SET_USER_PROFILE', data);
        return data;
      });
  },
  resetUserProfile: ({commit}) => {
    commit('RESET_USER_PROFILE');
    return Promise.resolve();
  },
  updateUserProfile: ({commit, getters}) => {
    commit('SET_LOADING', {updateUserProfile: true});
    return UserApiService.updateUserProfile(getters.userProfile)
      .then((data: IUserProfile) => {
        commit('SET_INITIAL_USER_PROFILE', data);
        commit('SET_USER_PROFILE', data);
        return data;
      })
      .finally(() => commit('SET_LOADING', {updateUserProfile: false}));
  },
  deleteUserProfile: ({commit, dispatch}) => {
    commit('SET_LOADING', {deleteUserProfile: true});
    return UserApiService.deleteUserProfile()
      .then(() => dispatch('logout'))
      .finally(() => commit('SET_LOADING', {deleteUserProfile: false}));
  },

  // Public

  getUserPublicProfile: ({commit}, externalId: string) => {
    commit('SET_LOADING', {getUserPublicProfile: true});
    return UserApiService.getUserPublicProfile(externalId)
      .then((data: IUserProfile) => {
        const profile = {
          ...data,
          schoolTypes: data.schoolTypes || []
        };
        commit('SET_PUBLIC_PROFILE', profile);
        return profile;
      })
      .finally(() => commit('SET_LOADING', {getUserPublicProfile: false}));
  },
  getUserPublicMedia({commit}, authorId: string) {
    commit('SET_LOADING', { userRecord: true });
    return SearchApiService.search({
      size: 10,
      filters: { authorId }
    })
      .then((data) => {
        commit('SET_PUBLIC_MEDIA', data);
      })
      .finally(() => commit('SET_LOADING', { userRecord: false }));
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
