import { render, staticRenderFns } from "./Badge.default.vue?vue&type=template&id=1988a810&scoped=true&lang=pug&"
import script from "./Badge.default.vue?vue&type=script&lang=ts&"
export * from "./Badge.default.vue?vue&type=script&lang=ts&"
import style0 from "./Badge.default.vue?vue&type=style&index=0&id=1988a810&scoped=true&lang=stylus&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1988a810",
  null
  
)

export default component.exports