


































import { Vue, Component } from 'vue-property-decorator';
import Menu from '@/components/UI/Menu.vue';
import UserAvatar from '@/pages/User/User.avatar.vue';
import EditorApiService from '@/services/api/editor.service';

interface IMenuLink {
  title: string;
  path: string;
  className?: string;
  hidden?: boolean,
  action?: () => void;
}

@Component({
  components: { Menu, UserAvatar }
})
export default class HeaderUserMenu extends Vue {
  private get userProfile() {
    return this.$store.getters.userProfile;
  }

  private get listItem(): IMenuLink[] {
    return [
      {
        title: 'Übersicht',
        path: '/user',
        className: 'secondary',
      },
      {
        title: 'Editor',
        path: '',
        className: 'secondary',
        action: this.openEditor,
        hidden: !this.$store.getters.featureEnabled('editor')
      },
      {
        title: 'Abmelden',
        path: '/',
        className: 'secondary',
        action: this.logout,
      }
    ];
  }

  private get monogram() {
    return this.userProfile.firstName && this.userProfile.lastName
      ? (this.userProfile.firstName.charAt(0) + this.userProfile.lastName.charAt(0)).toUpperCase()
      : null;
  }

  private logout(): void {
    this.$store.dispatch('logout');
    this.$store.dispatch('showOverlay', false);
  }

  private openEditor() {
    const editorUrl = process.env.VUE_APP_BUILD_MODE === 'PROD' 
      ? process.env.VUE_APP_EDITOR_PROD_URL 
      : process.env.VUE_APP_EDITOR_DEV_URL;
    return EditorApiService.getEditorAuthCode(editorUrl!)
      .then(({authorization_code}) => 
        window.location.href = `${editorUrl}?authorization_code=${authorization_code}`
      );
  }
}
