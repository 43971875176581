
















import { VueConstructor } from 'vue';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { ErrorCode } from '@/store/modules/ui/ui.store';

import Error404Icon from '@/assets/icons/errors/404.svg';
import Error500Icon from '@/assets/icons/errors/500.svg';
import ErrorMaintenanceIcon from '@/assets/icons/errors/maintenance.svg';
import SearchInput from '@/components/Search/SearchInput.vue';

interface IErrorItem {
  title: string;
  component: VueConstructor;
}

type IErrorDictionary = {
  [key in ErrorCode]: IErrorItem
};

@Component({
  metaInfo() {
    return {
      title: this.$data.dictionary[this.$props.errorCode] ? this.$props.errorCode : 'Wartung',
    };
  },
  components: { SearchInput },
})
export default class ErrorPage extends Vue {
  @Prop({
    required: true,
    type: [Number, String],
    default: 500
  })
  public errorCode!: ErrorCode;

  public dictionary: IErrorDictionary = {
    404: {
      title: 'Es tut uns leid, aber dieser Inhalt existiert nicht (mehr). Wir können Dir aber zum Trost ein Suchfeld anbieten:',
      component: Error404Icon
    },
    500: {
      title: 'Es tut uns leid, aber ein interner Fehler ist aufgetreten. Du könntest nun ein Glas Wasser trinken und es zu einem späteren Zeitpunkt noch einmal versuchen.',
      component: Error500Icon
    },
    'maintenance': {
      title: 'Die MUNDO-Mediathek wird im Moment gerade aktualisiert. Du könntest nun einen Spaziergang machen und es zu einem späteren Zeitpunkt noch einmal versuchen.',
      component: ErrorMaintenanceIcon
    }
  };

  public created(): void {
    this.$store.dispatch('receiveSearchTotalElements');
  }

  public get error(): IErrorItem {
    return this.dictionary[this.errorCode] || this.dictionary['maintenance'];
  }

  public get allowSearch(): boolean {
    return Number(this.errorCode) === 404;
  }

  public get placeholder(): string {
    return this.$store.getters.getSearchTotalElements
      ? `In ${this.$store.getters.getSearchTotalElements} Medien finden...`
      : '';
  }
}
