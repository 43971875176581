import { ActionTree, GetterTree } from 'vuex';
import { RootState } from '@/store';
import CmsApiService, {
  ICmsQueryParameters,
  ICmsResponse
} from '@/services/api/cms.service';

export interface IFAQSection extends ICmsResponse {
  title: string;
  subtitle: string;
  text: string;
  visible: boolean;
}

const defaultFAQQueryParameters: ICmsQueryParameters = {
  _sort: 'ASC',
  _sortByName: 'id'
};

export interface ICmsFAQState {
  faqSections: IFAQSection[];
  faqSectionLength: number | null;
  faqQueryParameters: ICmsQueryParameters;
  faqPage: number;
}

const initialState = (): ICmsFAQState => ({
  faqSections: [],
  faqSectionLength: null,
  faqQueryParameters: { ...defaultFAQQueryParameters },
  faqPage: 1,
});

const state = initialState();

const getters: GetterTree<ICmsFAQState, RootState> = {
  getFAQSection: (state: ICmsFAQState) => state.faqSections.length ? state.faqSections : [],
  getFAQPage: (state: ICmsFAQState) => state.faqPage,
};

const mutations = {
  ['SET_FAQ_SECTIONS'](state: ICmsFAQState, payload: IFAQSection[]) {
    state.faqSections = payload;
  },
  ['SET_FAQ_SECTION_LENGTH'](state: ICmsFAQState, payload: number) {
    state.faqSectionLength = payload;
  },
  ['SET_FAQ_PAGE'](state: ICmsFAQState, payload: number) {
    state.faqPage = payload;
  },
};

const actions: ActionTree<ICmsFAQState, RootState> = {
  getCmsFAQSections: ({commit}) => {
    commit('SET_LOADING', {faq: true});

    return CmsApiService.getCmsFAQSections()
      .then((data: IFAQSection[]) => {
        commit('SET_FAQ_SECTIONS', data);
      })
      .finally(() => commit('SET_LOADING', {faq: false}));
  },
};

export default {
  state,
  getters,
  mutations,
  actions
};
