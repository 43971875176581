import { featureGuard } from '@/router/guards/feature.guard';
import { viewModeGuard } from '@/router/guards/viewMode.guard';
import { authGuard } from '@/router/guards/auth.guard';
import { abilityGuard } from '@/router/guards/ability.guard';
import { redirectGuard } from '@/router/guards/redirect.guard';
import { NavigationGuardNext, Route } from 'vue-router';

const globalGuards = (to: Route, from: Route, next: NavigationGuardNext) => {
  const params = { to, from, next };

  return redirectGuard({
    ...params,
    nextGuard: (redirectGuardParams) => featureGuard({
      ...redirectGuardParams!,
      nextGuard: (featureGuardParams) => authGuard({
        ...featureGuardParams!,
        nextGuard: (authGuardParams) => abilityGuard({
          ...authGuardParams!,
          nextGuard: (abilityGuardParams) => viewModeGuard({
            ...abilityGuardParams!
          })
        })
      })
    })
  });
  
};

export default globalGuards;
