












import { Vue, Component, Prop } from 'vue-property-decorator';
import { ISuggest } from '@/services/api/search.service';
import { UISizeType } from '@/interfaces/interfaces';

@Component({})
export default class InputSuggest extends Vue {
  @Prop({
    type: Array,
    required: false,
    default: () => []
  })
  private suggestList!: ISuggest[];

  @Prop({
    type: String,
    required: false
  })
  private size!: UISizeType;

  private get classObject(): object {
    return {
      [`suggest-list-${this.size}`]: !!this.size,
    };
  }
}
