

























import { Vue, Component, Watch } from 'vue-property-decorator';
import {createHelpers} from 'vuex-map-fields';

import ErrorPage from '@/pages/Error/Error.page.vue';
import Loading from '@/components/Loading.vue';
import Header from '@/components/Layout/Header/Header.vue';
import Overlay from '@/components/Overlay.vue';
import Footer from '@/components/Layout/Footer/Footer.vue';
import Lightbox from '@/components/UI/Lightbox/Lightbox.vue';
import { IOverlayScrollbarInstance } from '@/interfaces/interfaces';
import { ErrorCode } from '@/store/modules/ui/ui.store';
import CookieBanner from '@/components/UI/CookieBanner.vue';
import { CookieService } from '@/services/data/cookie.service';
import UserBookmarksSetMediaDialog from '@/pages/UserBookmarks/User.bookmarks.setMediaDialog.vue';

const { mapFields } = createHelpers({
  getterType: 'getUiField',
  mutationType: 'updateUiField',
});

@Component({
  components: {
    ErrorPage,
    Loading,
    Header,
    Overlay,
    Footer,
    Lightbox,
    CookieBanner,
    UserBookmarksSetMediaDialog
  },
  computed: {...mapFields([ 'errorCode' ])},
})
export default class DefaultLayout extends Vue {
  private errorCode!: null | ErrorCode;

  public created(): void {
    const cookieConsentStatus = CookieService.get('cookieConsentStatus');
    if (!cookieConsentStatus) {
      setTimeout(() => {
        this.$store.dispatch('showCookieBanner', true);
      }, 500);
    }
  }

  public mounted() {
    this.$root.$on('scrollPage', (id: string) => this.scrollPage(id));
    this.$root.$on('scrollTo', (selector: string) => this.scrollTo(selector));
    this.$root.$on('scrollPageTop', this.scrollTop);
    this.setAllowHeaderSearch();

    if (process.env.NODE_ENV === 'production' && this.$store.getters.isConsentMatomo) {
      this.handleConsent();
    }
  }

  @Watch('$route.name')
  public onRouteChanged() {
    this.setAllowHeaderSearch();
  }

  @Watch('this.errorCode')
  public onErrorCodeChanged() {
    this.setAllowHeaderSearch();
  }

  public setAllowHeaderSearch() {
    this.$store.dispatch(
      'allowHeaderSearch',
      this.isAllowHeaderSearch
    );
  }

  public get isAllowHeaderSearch(): boolean {
    const disallowRoutes = ['not-found', 'main'];

    return this.errorCode == null 
      && !!this.$route.name 
      && !disallowRoutes.includes(this.$route.name);
  }

  public scrollPage(id: string) {
    const element = document.querySelector(`#${id}`) as HTMLElement || null;
    if (element) {
      this.$nextTick(() => {
        (this.$refs.pageScroller as IOverlayScrollbarInstance).osInstance().scroll({
          el: element,
          scroll: 'ifneeded',
          block: 'begin',
          margin: {top: 178, bottom: 178}
        }, 330);
      });
    }
  }

  public scrollTo(selector: string) {
    const el = document.querySelector(selector) as HTMLElement || null;
    if (el) {
      this.$nextTick(() => {
        (this.$refs.pageScroller as IOverlayScrollbarInstance).osInstance().scroll({
          el,
          scroll: 'always',
          block: 'begin',
          margin: {top: 96}
        }, 330);
      });
    }
  }

  public scrollTop() {
    (this.$refs.pageScroller as IOverlayScrollbarInstance).osInstance().scroll(0);
  }

  public get scrollbarOptions() {
    return {
      className: 'os-theme-primary',
      overflowBehavior: {
        x: 'hidden',
        y: this.$store.getters.getAppScrollStatus
      },
    };
  }

  public handleConsent(): void {
    // @ts-ignore
    return this.$matomo && this.$matomo.rememberCookieConsentGiven();
  }
}
