import TokenService from '@/services/data/token.service';
import {ActionTree} from 'vuex';
import {RootState} from '@/store';
import AuthService, {IUserRegister, IResetPassword, ICredentials, IUpdatePassword, IUpdateEmail, IUpdateUsername} from '@/services/api/auth.service';
import {getField, updateField} from 'vuex-map-fields';
import router from '@/router';
import { IUser, UserRole } from '@/interfaces/interfaces';
import jwt_decode from 'jwt-decode';
import { Ability } from '@casl/ability';
import { AbilityAction, AbilitySubject } from '@/interfaces/interfaces';
import abilityRules from '@/config/abilityRules';
import EditorApiService from '@/services/api/editor.service';

export interface ITokenData {
  user_name: string;
  scope: string[];
  exp: number;
  user: IUser;
  authorities: Array<keyof typeof UserRole>;
  jti: string;
  client_id: string;
}

export interface IAuthState {
  tokenData: null | ITokenData;
  token: null | string;
  isAuthenticated: boolean;
  redirect?: string;
  editorRedirect?: string;
}

const initialState = (): IAuthState => ({
  token: null,
  tokenData: null,
  isAuthenticated: false,
});
const state = initialState();

const getters = {
  getAuthField: (state: IAuthState) => getField(state),
  isAuthenticated: (state: IAuthState) => state.isAuthenticated,
  ability: () => new Ability<[AbilityAction, AbilitySubject]>(),
  tokenData: (state: IAuthState) => state.tokenData,
  redirectUrl: (state: IAuthState) => state.redirect,
  editorRedirectUrl: (state: IAuthState) => {
    const defaultUrl = process.env.VUE_APP_BUILD_MODE === 'PROD' 
      ? process.env.VUE_APP_EDITOR_PROD_URL 
      : process.env.VUE_APP_EDITOR_DEV_URL;
    return state.editorRedirect || defaultUrl;
  }
};
const mutations = {
  updateAuthField(state: IAuthState, field: string) {
    return updateField(state, field);
  },
  ['SET_TOKEN'](state: IAuthState, payload: null | string) {
    state.token = payload;
  },
  ['SET_TOKEN_DATA'](state: IAuthState, payload: ITokenData | null) {
    state.tokenData = payload;
  },
  ['RESET_TOKEN_DATA'](state: IAuthState) {
    state.tokenData = null;
  },
  ['SET_IS_AUTHENTICATED'](state: IAuthState, payload: boolean) {
    state.isAuthenticated = payload;
  },
  ['SET_REDIRECT'](state: IAuthState, payload?: string) {
    state.redirect = payload;
  },
  ['SET_EDITOR_REDIRECT'](state: IAuthState, payload?: string) {
    state.editorRedirect = payload;
  }
};
const actions: ActionTree<IAuthState, RootState> = {
  setTokenData: ({commit, dispatch}, token: null | string) => {
    commit('SET_TOKEN', token);
    if (token) {
      const tokenData: ITokenData = jwt_decode(token);
      TokenService.setToken(token);
      commit('SET_TOKEN_DATA', tokenData);
  
      if (tokenData.user) {
        commit('SET_USER', tokenData.user);
      }
    }
    if (!token) {
      TokenService.resetToken();
      commit('RESET_TOKEN_DATA');
    }
    return dispatch('updateAbilities');
  },
  updateAbilities: ({state, getters}) => {
    const roles = state.tokenData ? state.tokenData.user.roles! : [];
    const rules = abilityRules(roles);

    getters.ability.update(rules);
  },
  authenticate: ({commit, dispatch}) => {
    const token = TokenService.getToken() || null;
    if (!token) { return; }
    commit('SET_IS_AUTHENTICATED', true);
    dispatch('setTokenData', token);
    dispatch('receiveUserProfile');
  },
  login: ({commit, dispatch, getters}, credentials: ICredentials) => {
    commit('SET_LOADING', {login: true});
    dispatch('setTokenData', null);
    return AuthService.login(credentials)
      .then(({access_token}) => {
        commit('SET_IS_AUTHENTICATED', true);
        dispatch('setTokenData', access_token)
          .then(() => {
            if (state.editorRedirect) {
              dispatch('getEditorAuthCode', getters.editorRedirectUrl)
                .then((authorization_code) => {
                  window.location.href = `${getters.editorRedirectUrl}?authorization_code=${authorization_code}`;
                  return false;
                });
              return;
            }
            return dispatch('receiveUserProfile')
              .then(() => {
                router.push(state.redirect || '/user')
                  .then(() => commit('SET_REDIRECT', undefined));
              }
            );
          }
        );
      })
      .finally(() => commit('SET_LOADING', {login: false}));
  },
  logout: ({commit, getters, dispatch}) => {
    if (getters.isAuthenticated) {
      commit('SET_LOADING', {logout: true});
  
      dispatch('setTokenData', null);
      commit('SET_IS_AUTHENTICATED', false);
      commit('RESET_USER');
      commit('RESET_USER_PROFILE');
      commit('SET_LOADING', {logout: false});
      return Promise.resolve();
    }
    return Promise.reject();
  },
  register: ({commit}, user: IUserRegister) => {
    commit('SET_LOADING', {register: true});
    return AuthService.register(user)
      .finally(() => commit('SET_LOADING', {register: false}));
  },
  confirmEmail: ({commit, dispatch}, token: string) => {
    dispatch('setTokenData', null);
    return AuthService.confirmEmail(token)
      .then(({access_token, redirect_url}) => {
        dispatch('setTokenData', access_token);
        dispatch('receiveUserProfile');
        commit('SET_IS_AUTHENTICATED', true);
        return Promise.resolve(redirect_url);
      })
      .then((redirectUrl) => {
        if (redirectUrl) {
          window.location.href = redirectUrl;
          return;
        }
        return router.push({name: 'user-welcome'});
      })
      .catch(() => router.push({name: 'not-found'}))
      .finally(() => commit('SET_LOADING', {confirmEmail: false}));
  },
  confirmChangeEmail: ({commit, dispatch}, token: string) => {
    dispatch('setTokenData', null);
    return AuthService.confirmChangeEmail(token)
      .then(({access_token}) => {
        dispatch('setTokenData', access_token);
        dispatch('receiveUserProfile');
        commit('SET_IS_AUTHENTICATED', true);
        return Promise.resolve();
      })
      .then(() => router.push({name: 'user-welcome'}))
      .catch(() => router.push({name: 'not-found'}))
      .finally(() => commit('SET_LOADING', {confirmEmail: false}));
  },
  checkEmail: ({commit}, email: string) => {
    commit('SET_LOADING', {checkEmail: true});
    return AuthService.checkEmail(email)
      .finally(() => commit('SET_LOADING', {checkEmail: false}));
  },
  forgetPassword: ({commit}, email: string) => {
    commit('SET_LOADING', {forgetPassword: true});
    return AuthService.forgetPassword(email)
      .finally(() => commit('SET_LOADING', {forgetPassword: false}));
  },
  resetPassword: ({commit}, data: IResetPassword) => {
    commit('SET_LOADING', {resetPassword: true});
    return AuthService.resetPassword(data)
      .finally(() => commit('SET_LOADING', {resetPassword: false}));
  },
  updatePassword: ({commit}, data: IUpdatePassword) => {
    commit('SET_LOADING', {updatePassword: true});
    return AuthService.updatePassword(data)
      .finally(() => commit('SET_LOADING', {updatePassword: false}));
  },
  checkTokenValidity: (_ctx, token: String) => {
    return AuthService.checkTokenValidity(token);
  },
  updateEmail: ({commit}, data: IUpdateEmail) => {
    commit('SET_LOADING', {updateEmail: true});
    return AuthService.updateEmail(data)
      .finally(() => commit('SET_LOADING', {updateEmail: false}));
  },
  updateUsername: ({commit}, data: IUpdateUsername) => {
    commit('SET_LOADING', {updateUsername: true});
    return AuthService.updateUsername(data)
      .finally(() => commit('SET_LOADING', {updateUsername: false}));
  },
  getCurrentUser: ({commit}) => {
    commit('SET_LOADING', {currentUser: true});
    return AuthService.getCurrentUser()
      .then((user: IUser) => {
        commit('SET_USER', user);
        return user;
      })
      .finally(() => commit('SET_LOADING', {currentUser: false}));
  },
  setUser: ({commit}, user: IUser) => {
    commit('SET_LOADING', {user: true});
    return AuthService.setUser(user)
      .then((data: IUser) => {
        return data;
      })
      .finally(() => commit('SET_LOADING', {user: false}));
  },
  getEditorAuthCode: (_ctx, redirectUrl) => {
    return EditorApiService.getEditorAuthCode(redirectUrl)
      .then(({authorization_code}) => {
        return authorization_code;
      });
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
