import { render, staticRenderFns } from "./CardBorderBottom.vue?vue&type=template&id=3a63d756&scoped=true&lang=pug&"
import script from "./CardBorderBottom.vue?vue&type=script&lang=ts&"
export * from "./CardBorderBottom.vue?vue&type=script&lang=ts&"
import style0 from "./CardBorderBottom.vue?vue&type=style&index=0&id=3a63d756&scoped=true&lang=stylus&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a63d756",
  null
  
)

export default component.exports