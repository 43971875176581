import { getField, updateField } from 'vuex-map-fields';
import { ActionTree, GetterTree } from 'vuex';
import { RootState } from '@/store';
import { ISearchFilter,
    ISearchFacet,
    ISearchFacetItem} from '@/services/api/search.service';
import { FilterGroup, FilterType } from '@/interfaces/interfaces';

const schoolTypeSortMap = [
  'Kindergarten', 'Grundschule', 'Mittel- / Hauptschule',
  'Realschule', 'Gymnasium', 'Förderschule', 'Berufsschule'
];

const licenseSortMap = [
  'CC0', 'Gemeinfrei / Public Domain', 'CC BY',
  'CC BY-SA', 'CC BY-NC', 'CC BY-ND', 'CC BY-NC-SA',
  'CC BY-NC-ND', 'Copyright, freier Zugang', 'Copyright, lizenzpflichtig',
  'freie Lizenz', 'keine Angaben (gesetzliche Regelung)', 'Schulfunk (§47)'
];

const bindedFiltersMap: Array<Record<string, FilterType[]>> = [
  {
    schoolLevels: [],
    schoolTypes: ['Kindergarten']
  },
  {
    schoolLevels: ['1-4'],
    schoolTypes: ['Grundschule']
  },
  {
    schoolLevels: ['5-10'],
    schoolTypes: ['Mittel- / Hauptschule', 'Realschule', 'Gymnasium', 'Förderschule']
  },
  {
    schoolLevels: ['11-13'],
    schoolTypes: ['Gymnasium', 'Berufsschule']
  }
];

export interface ISearchFiltersState {
  filters: ISearchFilter[];
  lastAppliedFilter: ISearchFilter | null;
  facet: ISearchFacet;
  lastUsedFilters: ISearchFilter[];
  quickFilters: ISearchFilter[];
}

const initialState = (): ISearchFiltersState => ({
  filters: [],
  lastAppliedFilter: null,
  facet: {
    mediaTypes: [],
    subjects: [],
    schoolLevels: [],
    licensees: [],
    schoolTypes: [],
    languages: [],
  },
  lastUsedFilters: [],
  quickFilters: [
    // { name: 'Berufsschule', group: 'schoolTypes'},
  ]
});

const state = initialState();

const getters: GetterTree<ISearchFiltersState, RootState> = {
  getSearchFiltersField: (state: ISearchFiltersState) => getField(state),

  getSearchFacetGroup: (state: ISearchFiltersState) => (group: FilterGroup) => {
    if (!Array.isArray(state.facet[group]) || !state.facet[group].length) { return []; }
    const value = state.facet[group].reduce((acc, curr) => {
      acc[curr.category as string] = [...acc[curr.category as string] || [], curr];
      return acc;
    }, {} as Record<string, ISearchFacetItem[]>);
    return Object.keys(value).map((key: string , index: number) => {
      return { category: key, items: Object.values(value)[index]};
    });
  },
  getSearchFiltersFacet: (state: ISearchFiltersState) => state.facet,
  getSearchFiltersFreezedGroup: (state: ISearchFiltersState) => state.lastAppliedFilter
    ? state.lastAppliedFilter.group
    : ''
};

const mutations = {
  updateSearchFiltersField(state: ISearchFiltersState, field: string) {
    return updateField(state, field);
  },

  ['SET_SEARCH_FILTERS'](state: ISearchFiltersState, payload: ISearchFilter[]) {
    state.filters = payload;
  },
  ['SET_LAST_APPLIED_FILTER'](state: ISearchFiltersState, payload: ISearchFilter) {
    state.lastAppliedFilter = payload;
  },
  ['SET_SEARCH_FACET'](state: ISearchFiltersState, payload: ISearchFacet) {
    const schoolTypes = payload.schoolTypes
      .sort((a, b) => schoolTypeSortMap.indexOf(a.name) - schoolTypeSortMap.indexOf(b.name));
    const licensees = payload.licensees
      .sort((a, b) => licenseSortMap.indexOf(a.name) - licenseSortMap.indexOf(b.name));
    const mediaTypes = payload.mediaTypes
      .sort((a, b) => a.name.localeCompare(b.name));
    const languages = payload.languages
      .sort((a, b) => a.name.localeCompare(b.name));

    const freezedGroup = state.lastAppliedFilter ? state.lastAppliedFilter.group : '';
    const sortedGroups = { schoolTypes, licensees, mediaTypes, languages } as Record<FilterGroup, ISearchFacetItem[]>;

    const getGroupFilters = (group: FilterGroup) => {
      return freezedGroup !== group || state.facet[group].length === 0
        ? sortedGroups[group]
          ? sortedGroups[group]
          : payload[group]
        : state.facet[group];
    };

    state.facet = {
      subjects: getGroupFilters('subjects'),
      schoolTypes: getGroupFilters('schoolTypes'),
      schoolLevels: getGroupFilters('schoolLevels'),
      licensees: getGroupFilters('licensees'),
      mediaTypes: getGroupFilters('mediaTypes'),
      languages: getGroupFilters('languages'),
    };
  },
};

const actions: ActionTree<ISearchFiltersState, RootState> = {
  setSearchFacet: ({commit}, payload: ISearchFacet) => {
    const result = Object.keys(payload).reduce((acc, curr) => {
      acc[curr as FilterGroup] = payload[curr as FilterGroup]
        .map((item) => Object.assign(item, { group: curr, id: `${curr}.${item.name}`}));
      return acc;
    }, {} as ISearchFacet);
    commit('SET_SEARCH_FACET', result);
  },
  addSearchFilter({state, commit}, filter: ISearchFilter) {
    if (state.filters.map((item) => item.id).includes(filter.id)) { return; }
    commit(
      'SET_SEARCH_FILTERS',
      [...state.filters, filter]
    );
  },
  removeSearchFilter({state, commit}, id: string) {
    commit(
      'SET_SEARCH_FILTERS',
      state.filters.filter((item: ISearchFilter) => item.id !== id)
    );
  },
  resetSearchFilters({commit, dispatch}) {
    commit('SET_SEARCH_FILTERS', []);
    dispatch('onChangeSearch');
  },
  removeFilter: ({dispatch, commit, rootState}, item: ISearchFilter) => {
    if (item.name === rootState.search.user) {
      commit('SET_SEARCH_USER', undefined);
      commit('SET_SEARCH_AUTHOR', undefined);
    }
    else if (item.name === rootState.search.search) {
      commit('SET_SEARCH', '');
    }
    else {
      dispatch('removeSearchFilter', item.id);
    }
    dispatch('onChangeSearch');
  },
  addFilter: ({dispatch}, name: string) => {
    dispatch('addSearchFilter', { name });
    dispatch('onChangeSearch');
  },
  checkFiltersBinding: ({state, commit}) => {
    const bindedList = bindedFiltersMap
      .map((item) => Object.values(item).flat())
      .flat();

    state.filters.forEach((payload) => {
      if (bindedList.includes(payload.name)) {
        const bindedObjects = bindedFiltersMap.filter(
          (object) => Object.values(object)
            .some((item) => item.includes(payload.name))
        );

        bindedObjects.forEach((bindedObject) => {
          const targetGroup = Object.keys(bindedObject)
            .filter((item) => !bindedObject[item].includes(payload.name))[0];
          const resultFilters = state.facet[targetGroup as FilterGroup]
            .map(
              (item) => bindedObject[targetGroup].includes(item.name)
                ? {...item, $isDisabled: false, $binded: true}
                : {...item, $isDisabled: item.$binded ? false : true}
            );

          commit('SET_SEARCH_FACET', {...state.facet, [targetGroup]: resultFilters});
        });
      }
    });
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
