import { render, staticRenderFns } from "./Chip.vue?vue&type=template&id=f2827230&scoped=true&lang=pug&"
import script from "./Chip.vue?vue&type=script&lang=ts&"
export * from "./Chip.vue?vue&type=script&lang=ts&"
import style0 from "./Chip.vue?vue&type=style&index=0&id=f2827230&scoped=true&lang=stylus&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f2827230",
  null
  
)

export default component.exports