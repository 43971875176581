









import { Vue, Component, Prop } from 'vue-property-decorator';

import BadgeDefault from '@/components/UI/Badge/Badge.default.vue';
import BadgeFloat from '@/components/UI/Badge/Badge.float.vue';

@Component({
  components: {
    BadgeDefault,
    BadgeFloat
  }
})
export default class Badge extends Vue {
  @Prop({
    type: String,
    required: false,
    default: null
  })
  private mode!: 'float' | null;

  private get component() {
    return this.mode === 'float'
      ? BadgeFloat
      : BadgeDefault;
  }
}
