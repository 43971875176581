import { LearnResourceType } from '@/interfaces/interfaces';

export const TitlesLearnResourceTypeDictionary: Record<LearnResourceType, string> = {
  app: 'App',
  arbeitsblatt: 'Arbeitsblatt',
  audio: 'Audio',
  audiovisuelles: 'Audiovisuelles Medium',
  bild: 'Bild',
  broschüre: 'Broschüre',
  daten: 'Daten',
  entdeckendes: 'Entdeckendes Lernen',
  experiment: 'Experiment',
  fallstudie: 'Fallstudie',
  glossar: 'Glossar',
  handbuch: 'Handbuch',
  interaktion: 'Interaktion',
  karte: 'Karte',
  kurs: 'Kurs',
  lernkontrolle: 'Lernkontrolle',
  lernspiel: 'Lernspiel',
  modell: 'Modell',
  offene: 'Offene Aktivität',
  project: 'Projekt',
  quelle: 'Primärmaterial/Quelle',
  radio: 'Radio/TV',
  recherche: 'Recherche-Auftrag',
  ressourcentyp: 'Anderer Ressourcentyp',
  rollenspiel: 'Rollenspiel/Planspiel',
  sammlung: 'Sammlung',
  simulation: 'Simulation',
  software: 'Software',
  sonstiges: 'Sonstiges',
  test: 'Test/Prüfung',
  text: 'Text',
  ubung: 'Übung',
  unterrichtsbaustein: 'Unterrichtsbaustein',
  unterrichtsplanung: 'Unterrichtsplanung',
  veranschaulichung: 'Veranschaulichung',
  video: 'Video',
  webseite: 'Webseite',
  webtool: 'Webtool',
  präsentation: 'Präsentation',
  collection: 'Dossiers'
};

export const getLearnResourceTypeByTitle = (title: string) => {
  return Object.keys(TitlesLearnResourceTypeDictionary)
    .find((key) => TitlesLearnResourceTypeDictionary[key as LearnResourceType] === title)!;
};

export const getTitleByLearnResourceType = (type: LearnResourceType) => {
  return TitlesLearnResourceTypeDictionary[type] || '';
};
