









import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class CardThumbnailCover extends Vue {
  @Prop({
    type: String,
    required: false
  })
  private coverLink!: string;

  @Prop({
    type: String,
    required: false
  })
  private alt!: string;
}
