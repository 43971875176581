import { AxiosRequestConfig } from 'axios';
import TokenService from '@/services/data/token.service';
import store from '@/store';

const requestInterceptor = (config: AxiosRequestConfig) => {
  store.commit('SET_ERROR_CODE', null);
  const token = TokenService.getToken();
  if (token) {
    config.headers!.Authorization = `Bearer ${token}`;
  }
  return config;
};

export default requestInterceptor;
