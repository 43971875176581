

























import { Vue, Component, Watch } from 'vue-property-decorator';
import developmentRoutes from '@/router/routes/routes.development';
import Input from '@/components/UI/Input/Input.vue';

@Component({
  components: {
    Input
  }
})
export default class UISidebar extends Vue {
  private search: string = '';

  private get adaptiveModeColor() {
    const mode = this.$store.state.ui.adaptiveMode;
    switch (mode) {
      case 'XLG':
        return 'palette-11';
      case 'LG':
        return 'palette-10';
      case 'MD':
        return 'palette-7';
      case 'SM':
        return 'palette-5';
      default:
        return 'palette-4';
    }
  }

  private get routes() {
    const list = developmentRoutes
      .filter((item) => item.name === 'ui')[0].children!
      .map((child) => child.path);
    return !this.search.length
      ? [...list]
      : list.filter((item) => item.includes(this.search));
  }

  private mounted() {
    //
  }

  @Watch('$route.name')
  private onRouteChanged(value: string) {
    this.search = '';
    const target = document.querySelector(`#${value}-link`)!;
    target.scrollIntoView({block: 'start', behavior: 'smooth'});
  }
}
