import {IMainSection} from '@/store/modules/cms/cms.main.store';
import {ICmsButton} from '@/components/Cms/CmsButton.vue';

export const retrieveCmsButtonFields = (section: IMainSection): ICmsButton => {
  const {
    buttonTitle: title = null,
    buttonLink: link = null,
    buttonVisible: visible = false
  } = section;
  return {
    title,
    link,
    visible
  };
};
