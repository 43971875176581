import './router/componentHooks';
import Vue from 'vue';
import { VNode } from 'vue/types/vnode';
import router from './router';
import store from './store';

import App from './App.vue';

import {
  LayoutPlugin,
  ImagePlugin,
  TabsPlugin,
  FormCheckboxPlugin,
  FormRadioPlugin,
  FormGroupPlugin,
  FormFilePlugin
} from 'bootstrap-vue';

// @ts-ignore
import { Fragment } from 'vue-fragment';
import { ObserveVisibility } from 'vue-observe-visibility';
import { OverlayScrollbarsPlugin } from 'overlayscrollbars-vue';
import 'overlayscrollbars/css/OverlayScrollbars.css';
// @ts-ignore
import Vuebar from 'vuebar';
import VueScrollTo from 'vue-scrollto';

import VTooltip from 'v-tooltip';
import PortalVue from 'portal-vue';
import VueMeta from 'vue-meta';
// @ts-ignore
import VueMatomo from 'vue-matomo';
// @ts-ignore
import { analytic } from '../analytic.js';
import { Can, abilitiesPlugin } from '@casl/vue';

import Button from '@/components/UI/Button.vue';
import Badge from '@/components/UI/Badge/Badge.vue';
import Input from '@/components/UI/Input/Input.vue';
import Textarea from '@/components/UI/Textarea/Textarea.vue';
import Chip from '@/components/UI/Chip.vue';
import Card from '@/components/UI/Card/Card.vue';
import CardCollection from '@/components/UI/CardCollection/CardCollection.vue';
import CardThumbnail from '@/components/UI/CardThumbnail/CardThumbnail.vue';
import FeatureToggle from '@/components/FeatureToggle.vue';

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/sw.js').then(() => {
    console.warn('[ServiceWorker]: Registration successful');
  }, (err) => {
    console.warn('[ServiceWorker]: Registration failed');
    console.warn(err);
  });
  navigator.serviceWorker.getRegistration().then(function(reg) {
    // There's an active SW, but no controller for this tab.
    if (reg?.active && !navigator.serviceWorker.controller) {
      // Perform a soft reload to load everything from the SW and get
      // a consistent set of resources.
      window.location.reload();
    }
  });
}

Vue.config.productionTip = false;

Vue.use(LayoutPlugin);
Vue.use(ImagePlugin);
Vue.use(TabsPlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(FormRadioPlugin);
Vue.use(FormGroupPlugin);
Vue.use(FormFilePlugin);
Vue.use(OverlayScrollbarsPlugin);
Vue.use(Vuebar);
Vue.use(VueScrollTo);
Vue.use(VTooltip);
Vue.use(PortalVue);
Vue.use(VueMeta, {
  keyName: 'metaInfo',
  refreshOnceOnNavigation: true,
});
Vue.use(abilitiesPlugin, store.getters.ability);

if (process.env.NODE_ENV === 'production') {
  Vue.use(VueMatomo, {
    host: analytic.host,
    siteId: analytic.id,
    trackerFileName: 'matomo',
    router,
    enableLinkTracking: true,
    requireConsent: false,
    requireCookieConsent: true,
    trackInitialView: false,
  });
}

Vue.component('Button', Button);
Vue.component('Badge', Badge);
Vue.component('Input', Input);
Vue.component('Textarea', Textarea);
Vue.component('Card', Card);
Vue.component('CardCollection', CardCollection);
Vue.component('CardThumbnail', CardThumbnail);
Vue.component('Chip', Chip);
Vue.component('FeatureToggle', FeatureToggle);
Vue.component('Can', Can);
// @ts-ignore
Vue.component('Fragment', Fragment as Vue);

Vue.directive('observe-visibility', ObserveVisibility);

Vue.directive('view-mode', {
  // IMPORTANT! No possibility to switch view mode dynamically using directive
  inserted(el, binding, vnode) {
    const store = vnode!.context!.$store;
    const mode = store.getters.viewMode;
    
    if (binding.arg === 'not') {
      if (binding.value.includes(mode)) {
        el.style.display = 'none';
      }
    } else {
      if (!binding.value.includes(mode)) {
        el.style.display = 'none';
      }
    }
  }
});

const render = (createElement: typeof Vue.prototype.$createElement): VNode => createElement(App);
new Vue({
  router,
  store,
  render
}).$mount('#app');
