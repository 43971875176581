


















































import Vue, { VueConstructor } from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { UISizeType, ICardInfo, IMedia, LearnResourceType } from '@/interfaces/interfaces';
import { getIconByLearnResourceType } from '@/dictionaries/icons/icons.learnResourceType.dictionary';
import { TitlesLearnResourceTypeDictionary } from '@/dictionaries/titles/titles.learnResourceType.dictionary';
import clickOutside from '@/directives/click-outside';
import capitalize from '@/filters/capitalize';

import Chevron from '@/components/UI/Chevron.vue';
import CardCover from './CardCover.vue';
import CardType from './CardType.vue';
import CardActive from './CardActive.vue';
import CardInfo from './CardInfo.vue';
import CardBorderBottom from '@/components/UI/Card/CardBorderBottom.vue';

import EducationLevelIcon from '@/assets/icons/card/people.svg';
import ProviderIcon from '@/assets/icons/card/provider.svg';

@Component({
  components: {
    Chevron,
    CardCover,
    CardInfo,
    CardType,
    CardActive,
    CardBorderBottom
  },
  directives: { clickOutside },
  filters: { capitalize }
})
export default class Card extends Vue {
  private getIconByLearnResourceType = getIconByLearnResourceType;
  private defaultCoverImage ='/images/card-cover.svg';
  private isInlineActive = false;
  private focus: boolean = false;

  @Prop({
    type: Object,
    required: true
  })
  private item!: IMedia;

  @Prop({
    type: String,
    required: false
  })
  private size!: UISizeType;

  @Prop({
    type: Boolean,
    required: false
  })
  private inline!: boolean;

  @Prop({
    type: Boolean,
    required: false
  })
  private active!: boolean;

  @Prop({
    type: Boolean,
    required: false
  })
  private disabled!: boolean;

  @Prop({
    type: Boolean,
    required: false
  })
  private readonly!: boolean;

  @Prop({
    type: Boolean,
    required: false
  })
  private isRelationParent!: boolean;

  @Prop({
    type: Boolean,
    required: false
  })
  private statused!: boolean;

  @Prop({
    type: Boolean,
    required: false
  })
  private inProcess!: boolean;

  @Prop({
    type: Boolean,
    required: false
  })
  private coverStripped!: boolean;

  @Prop({
    type: Boolean,
    required: false
  })
  private noFocus!: boolean;

  @Prop({
    type: String,
    required: false,
    default: 'primary'
  })
  private activeVariant!: string;

  @Prop({
    type: String,
    required: false
  })
  private coverImagePreviewClass!: string;

  private get learnResourceTypeIcon(): VueConstructor {
    return getIconByLearnResourceType(this.item.learnResourceType![0]);
  }

  private get infoList(): ICardInfo[] {
    return [
      ...(
        this.item.learnResourceType && this.item.learnResourceType.length
          ? [{
              title: TitlesLearnResourceTypeDictionary[this.item.learnResourceType![0] as LearnResourceType],
              icon: this.learnResourceTypeIcon,
              alt: 'Icon: ' + this.$options.filters!.capitalize(this.item.learnResourceType[0])
            } as ICardInfo]
          : []
      ),
      ...(
        this.item.schoolLevel
          ? [{
              title: 'Kl. ' + this.item.schoolLevel,
              icon: EducationLevelIcon,
              alt: 'Icon: Klassenstufe'
            } as ICardInfo]
          : []
      ),
      ...(
        this.item.publishers
          ? [{
              title: this.item.publishers.map((item) => item.name).join(' / '),
              icon: ProviderIcon,
              alt: 'Icon: Herausgeber'
            } as ICardInfo]
          : []
      ),
    ];
  }

  private get classObject(): object {
    return {
      active: this.active,
      focused: this.focus && !this.active && !this.noFocus,
      disabled: this.disabled,
      readonly: this.readonly,
      'in-process': this.inProcess,
      'card-inline': this.inline,
      [`card-${this.size}`]: !!this.size
    };
  }

  private action() {
    this.setFocus(true);
    this.$emit('action');
  }

  private setFocus(value: boolean) {
    this.focus = value;
  }
}
