import Vue from 'vue';
import VueRouter, { RawLocation, Route } from 'vue-router';
import globalGuards from '@/router/guards';
import {scrollTo} from '@/utilities';
import productionRoutes from '@/router/routes/routes.production';
import developmentRoutes from '@/router/routes/routes.development';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [...productionRoutes, ...developmentRoutes],
  scrollBehavior(to, from) {
    const positionPreservingPages = ['publishers'];
    if (positionPreservingPages.includes(to.name!)
      && positionPreservingPages.includes(from.name!)) {
      return;
    }

    const isScrollToHashPermitted = to.name === 'main' && from.name !== 'main';
    if (to.hash) {
      if (isScrollToHashPermitted) {
        return scrollTo(to.hash);
      }
      return;
    }
    return scrollTo('.app-container');
  },
});

router.beforeEach((to, from, next) => globalGuards(to, from, next));

// Handle navigation duplication for router push (Globally)
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location: RawLocation): Promise<Route> {
  return new Promise((resolve, reject) => {
    originalPush.call(this, location, () => {
      resolve(this.currentRoute);
    }, (error) => {
      if (error.name === 'NavigationDuplicated' || error.message.includes('Redirected when going from')) {
        resolve(this.currentRoute);
      } else {
        reject(error);
      }
    });
  });
};

export default router;
