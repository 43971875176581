import { ActionTree, GetterTree } from 'vuex';
import { getField, updateField } from 'vuex-map-fields';
import { RootState } from '@/store';
import BookmarkApiService, { IBookmark, ISetBookmarksMediaParams } from '@/services/api/bookmark.service';
import { ActionType, IBookmarkParams, IMedia, LearnResourceType, RouteQueryParams } from '@/interfaces/interfaces';
import { getAvailableFilters, getComputedMediaList } from '@/workers/bookmark.worker';
import router from '@/router';
import { stringToDateTime } from '@/utilities';
import { CookieService } from '@/services/data/cookie.service';

export interface IBookmarkState {
  bookmark: null | IBookmark;
  bookmarks: IBookmark[];
  bookmarksStats: IBookmark[];
  bookmarksParams: IBookmarkParams;
  bookmarksResult: IMedia[];
  bookmarksResultSize: number;
  bookmarksResultLoading: boolean;
  bookmarksAvailableFilters: LearnResourceType[];
  bookmarksContainsMediaId: string | null;
  bookmarksMediaAction: keyof typeof ActionType | null;
  selectedBookmarks: string[];
}

const defaultBookmarkParams = (): IBookmarkParams => ({
  filters: [],
  sortBy: 'NEWEST'
});

const initialState = (): IBookmarkState => ({
  bookmark: null,
  bookmarks: [],
  bookmarksStats: [],
  bookmarksParams: defaultBookmarkParams(),
  bookmarksResult: [],
  bookmarksResultSize: 20,
  bookmarksResultLoading: false,
  bookmarksAvailableFilters: [],
  bookmarksContainsMediaId: null,
  bookmarksMediaAction: null,
  selectedBookmarks: []
});

const state = initialState();

const getters: GetterTree<IBookmarkState, RootState> = {
  getDefaultBookmarkName: (_state: IBookmarkState) => (list: IBookmark[]) => {
    const defaultName = `Unbenannte Liste ${stringToDateTime(Date.now())}`;
    const repeatCount = list.filter((bookmark) => bookmark.name.includes(defaultName)).length;
    return `${defaultName}${repeatCount ? ' (' + repeatCount + ')' : ''}`;
  },
  isBookmarkEmpty: (state: IBookmarkState) => !state.bookmark || !state.bookmark.medias.length,
  bookmarksMetaTitle: (state: IBookmarkState) => (edited: boolean) => {
    return edited
      ? state.bookmark && !state.bookmark.userDefault
          ? `Merkliste bearbeiten ${state.bookmark!.name}`
          : null
      : state.bookmark && !state.bookmark.userDefault
          ? `Meine Merkliste ${state.bookmark!.name}`
          : 'Meine Merkliste';
  },
  bookmarksMetaDescription: (state: IBookmarkState) => (edited: boolean) => {
    return state.bookmark && state.bookmark.description
      ? state.bookmark.description
      : edited
        ? 'Bearbeite deine Merklisten für eine bessere Übersichtlichkeit: Lege einen Ordnernamen fest und verfasse eine Beschreibung.'
        : 'Eigene Ordner erstellen und Merklisten auf MUNDO anlegen - damit interessante Bildungsmedien nicht verloren gehen.';
  },
  getBookmarkField: (state: IBookmarkState) => getField(state)
};

const mutations = {
  ['SET_BOOKMARK'](state: IBookmarkState, payload: IBookmark) {
    state.bookmark = payload;
  },
  ['SET_BOOKMARKS'](state: IBookmarkState, payload: IBookmark[]) {
    state.bookmarks = payload;
  },
  ['SET_BOOKMARKS_STATS'](state: IBookmarkState, payload: IBookmark[]) {
    state.bookmarksStats = payload;
  },
  ['SET_BOOKMARKS_PARAMS'](state: IBookmarkState, payload: RouteQueryParams) {
    state.bookmarksParams = {...state.bookmarksParams, ...payload};
  },
  ['SET_BOOKMARKS_RESULT'](state: IBookmarkState, payload: IMedia[]) {
    state.bookmarksResult = payload;
  },
  ['SET_BOOKMARKS_RESULT_SIZE'](state: IBookmarkState, payload: IMedia[]) {
    state.bookmarksResult = payload;
  },
  ['SET_BOOKMARKS_RESULT_LOADING'](state: IBookmarkState, payload: boolean) {
    state.bookmarksResultLoading = payload;
  },
  ['SET_BOOKMARKS_AVAILABLE_FILTERS'](state: IBookmarkState, payload: LearnResourceType[]) {
    state.bookmarksAvailableFilters = payload;
  },
  ['SET_BOOKMARKS_CONTAINS_MEDIA_ID'](state: IBookmarkState, payload: string | null) {
    state.bookmarksContainsMediaId = payload;
  },
  ['SET_BOOKMARKS_MEDIA_ACTION'](state: IBookmarkState, payload: keyof typeof ActionType | null) {
    state.bookmarksMediaAction = payload;
  },
  ['SET_SELECTED_BOOKMARKS'](state: IBookmarkState, payload: string[]) {
    state.selectedBookmarks = payload;
  },
  updateBookmarkField(state: IBookmarkState, field: string) {
    return updateField(state, field);
  }
};

const actions: ActionTree<IBookmarkState, RootState> = {
  // View

  getBookmarks({commit}) {
    commit('SET_LOADING', {bookmark: true});
    return BookmarkApiService.getBookmarks()
      .then((data) => {
        commit('SET_BOOKMARKS', data.bookmarks);
      })
      .finally(() => commit('SET_LOADING', {bookmark: false}));
  },
  getBookmarksChart({commit}) {
    commit('SET_LOADING', {bookmark: true});
    return BookmarkApiService.getBookmarks('size=10')
      .then((data) => {
        const [firstBookmark, ...rest] = data.bookmarks;
        data.bookmarks = [firstBookmark, ...rest.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())];
        commit('SET_BOOKMARKS', data.bookmarks);
      })
      .finally(() => commit('SET_LOADING', {bookmark: false}));
  },
  setBookmarksData({dispatch}, bookmarkId?: string) {
    return dispatch('getBookmarks')
      .then(() => dispatch('setCurrentBookmarkData', bookmarkId));
  },
  setCurrentBookmarkData({dispatch}, bookmarkId?: string) {
    dispatch('setCurrentBookmark', bookmarkId)
      .then(() => dispatch('setBookmarksAvailableFilters')
        .then(() => dispatch('setBookmarksResult'))
      );
  },
  setCurrentBookmark({commit}, bookmarkId: string) {
    const currentBookmark = state.bookmarks
      .filter((bookmark) => bookmarkId
        ? bookmark.id === bookmarkId
        : bookmark.userDefault)[0];
    commit('SET_BOOKMARK', {...currentBookmark});
  },
  setBookmarksResult({dispatch}) {
    dispatch('parseBookmarksQueryParams')
      .then(() => dispatch('setBookmarksMedias'));
  },
  parseBookmarksQueryParams({commit}) {
    const {
      sortBy,
      filters
    } = router.currentRoute.query;

    commit('SET_BOOKMARKS_PARAMS', {
      sortBy: sortBy
        ? sortBy
        : defaultBookmarkParams().sortBy,
      filters: filters && filters.length
        ? (filters as string)
          .split(',')
        : defaultBookmarkParams().filters
    });
  },
  pushBookmarksQueryParams(_ctx, params: IBookmarkParams) {
    const {sortBy, filters} = params;

    const query = {
      sortBy: sortBy && sortBy !== defaultBookmarkParams().sortBy
        ? sortBy
        : undefined,
      filters: filters && filters.length
        ? (filters as []).join(',')
        : undefined
    };

    return router.push({query});
  },
  getBookmarksContainsMedia({commit, dispatch}, mediaId: string) {
    commit('SET_LOADING', {bookmark: true});
    return BookmarkApiService.getBookmarksContainsMedia(mediaId)
      .then((data) => {
        commit('SET_BOOKMARKS_STATS', data.containsResult);
        commit('SET_BOOKMARKS_CONTAINS_MEDIA_ID', mediaId);
        dispatch('applySelectedBookmarks');
      })
      .finally(() => commit('SET_LOADING', {bookmark: false}));
  },
  setSharedBookmark({dispatch, commit}, shareId: string) {
    commit('SET_LOADING', {bookmark: true});
    return dispatch('getSharedBookmark', shareId)
      .then(() => dispatch('setSharedBookmarkData'))
      .finally(() => commit('SET_LOADING', {bookmark: false}));
  },
  setSharedBookmarkData({dispatch}) {
    dispatch('setBookmarksAvailableFilters')
      .then(() => dispatch('setBookmarksResult'));
  },

  // Workers

  async setBookmarksMedias({state, commit}) {
    commit('SET_BOOKMARKS_RESULT_LOADING', true);

    try {
      const list = await getComputedMediaList(
        state.bookmark!.medias,
        state.bookmark!.id,
        state.bookmarksParams
      );
      commit('SET_BOOKMARKS_RESULT_SIZE', initialState().bookmarksResultSize);
      commit('SET_BOOKMARKS_RESULT', list);
      commit('SET_BOOKMARKS_RESULT_LOADING', false);
    } catch (error) {
      commit('SET_BOOKMARKS_RESULT_LOADING', false);
      console.warn('Bookmarks worker failed', error);
    }
  },
  async setBookmarksAvailableFilters({state, commit}) {
    try {
      const list = await getAvailableFilters(state.bookmark!.medias);
      commit('SET_BOOKMARKS_AVAILABLE_FILTERS', list);
    } catch (error) {
      console.warn('Bookmarks worker failed', error);
    }
  },

  // Actions

  setBookmark({commit, dispatch}, bookmark: IBookmark) {
    commit('SET_LOADING', {bookmark: true});
    return BookmarkApiService.setBookmark(bookmark)
      .then((bookmark) => dispatch('getBookmarks')
        .then(() => {
          router.push({name: 'user-bookmarks', params: {id: bookmark.id}});
          return bookmark;
        })
      )
      .finally(() => commit('SET_LOADING', {bookmark: false}));
  },
  removeBookmark({commit, dispatch}, bookmarkId: string) {
    commit('SET_LOADING', {bookmark: true});
    return BookmarkApiService.removeBookmark(bookmarkId)
      .then(() => dispatch('getBookmarks')
        .then(() => router.push({name: 'user-bookmarks', params: {}}))
      )
      .finally(() => commit('SET_LOADING', {bookmark: false}));
  },

  addMediaToBookmarks({commit}, params: ISetBookmarksMediaParams) {
    commit('SET_LOADING', {bookmark: true});
    return BookmarkApiService.addMediaToBookmarks(params)
      .finally(() => commit('SET_LOADING', {bookmark: false}));
  },
  removeMediaFromBookmarks({commit, dispatch}, params: ISetBookmarksMediaParams) {
    commit('SET_LOADING', {bookmark: true});
    return BookmarkApiService.removeMediaFromBookmarks(params)
      .then(() => dispatch('setBookmarksData', params.bookmarksIds[0])
        .finally(() => commit('SET_LOADING', {bookmark: false}))
      )
      .catch(() => commit('SET_LOADING', {bookmark: false}));
  },
  moveMediaToBookmarks({state, commit, dispatch}, params: ISetBookmarksMediaParams) {
    commit('SET_LOADING', {bookmark: true});
    return BookmarkApiService.addMediaToBookmarks(params)
      .then(() => BookmarkApiService.removeMediaFromBookmarks({
        mediaId: params.mediaId,
        bookmarksIds: [state.bookmark!.id]
      })
        .then(() => dispatch('setBookmarksData', state.bookmark!.id))
        .finally(() => commit('SET_LOADING', {bookmark: false})
      ))
      .finally(() => commit('SET_LOADING', {bookmark: false}));
  },
  shareBookmark({commit}, bookmarkId: string) {
    commit('SET_LOADING', {bookmark: true});
    return BookmarkApiService.shareBookmark(bookmarkId)
      .finally(() => commit('SET_LOADING', {bookmark: false}));
  },
  getSharedBookmark({commit}, shareId: string) {
    commit('SET_LOADING', {bookmark: true});
    return BookmarkApiService.getSharedBookmark(shareId)
      .then((data) => commit('SET_BOOKMARK', data))
      .finally(() => commit('SET_LOADING', {bookmark: false}));
  },

  // Save data on client

  applySelectedBookmarks({state, commit}) {
    const idList = CookieService.get('selectedBookmarks') || [];
    const data = state.bookmarksStats
      .filter((item: IBookmark) => item.userDefault && !idList.length
        ? !item.containsMedia
        : !item.containsMedia && idList.includes(item.id)
      )
      .map((item: IBookmark) => item.id);
    commit('SET_SELECTED_BOOKMARKS', data);
  },
  saveSelectedBookmarks({state}) {
    const expires = new Date();
    expires.setHours(23, 59, 59, 0);
    return CookieService.set('selectedBookmarks', state.selectedBookmarks, { expires });
  },
};

export default {
  state,
  getters,
  mutations,
  actions
};
