import { api } from '@/services/api/api.service';
import { AxiosResponse } from 'axios';
import { FilterType, FilterGroup, SearchSort, SearchSortType, IMedia } from '@/interfaces/interfaces';

export interface ISuggest {
  value: string;
  text: string;
}

export interface ISearchFacet extends Record<FilterGroup, ISearchFacetItem[]> {
  schoolLevels: ISearchFacetItem[];
  mediaTypes: ISearchFacetItem[];
  subjects: ISearchFacetItem[];
  licensees: ISearchFacetItem[];
  schoolTypes: ISearchFacetItem[];
  languages: ISearchFacetItem[];
}

export interface ISearchFilter {
  name: FilterType;
  group: FilterGroup;
  id: string;
}

export interface ISearchFacetItem {
  name: FilterType;
  group: FilterGroup;
  value?: number;
  $isDisabled?: boolean;
  $binded?: boolean;
  category?: string;
}

export interface ISearchResponse {
  tiles: IMedia[];
  facet: ISearchFacet;
  page: number;
  pageSize: number;
  totalPages: number;
  totalHits: number;
}
export interface IBubbleResponse {
  subjectList: IBubbleSubject[];
  total: number;
}

export interface IBubbleSubject {
  name: string;
  value: number;
}

export interface IDefaultValueDictionary {
  search: string;
  page: number;
  size: number;
  sort: SearchSort;
  filter: [];
  user?: string
}

export interface ISearchParameters {
  search?: string;
  page?: number;
  size?: number;
  sort?: SearchSortType;
  filter?: ISearchFilter[];
  filters?: SearchFiltersObject;
  user?: string;
}

export interface ISearchRequestParams {
  search?: string;
  page?: number;
  size?: number;
  sort?: SearchSortType;
  filter?: Record<string, string>;
  filters?: SearchFiltersObject | Record<string, string>;
}

export type SearchFiltersObject = Record<FilterGroup, FilterType[]>;

export interface IReportError {
  errorType: string;
  comment: string;
}

export interface ISendContactForm {
  salutation: string;
  name: string;
  surname: string;
  email: string;
  subject: string;
  message: string;
}

export interface ISearchApiService {
  suggest: (query: string) => Promise<ISuggest[]>;
  search: (searchParameters: ISearchRequestParams) => Promise<ISearchResponse>;
  exportSearch: (searchParameters: ISearchRequestParams) => Promise<ISearchResponse>;
  bubble: () => Promise<IBubbleResponse>;
  getRecommendedList: () => Promise<ISearchResponse>;
  reportError: (data: IReportError) => Promise<any>;
  sendContactForm: (data: ISendContactForm) => Promise<any>;
  receiveSearchTotalElements: () => Promise<number>;
  getUserApprovedMediaList: (searchParameters: ISearchRequestParams) => Promise<ISearchResponse>;
}

const suggest = (query: string): Promise<ISuggest[]> => api
  .get(`mundo/autocomplete?q=${query}`)
  .then((response: AxiosResponse<ISuggest[]>) => response.data)
  .catch((error) => Promise.reject(error));

const getUserApprovedMediaList = (searchParameters: ISearchRequestParams): Promise<ISearchResponse> => api
  .get(`mundo/search/my-media?page=${searchParameters.page}&size=${searchParameters.size}`)
  .then((response: AxiosResponse<ISearchResponse>) => response.data)
  .catch((error) => Promise.reject(error));
const search = (searchParameters: ISearchRequestParams): Promise<ISearchResponse> => {
  return api
    .post(
      'mundo/search',
      {...searchParameters}
    )
    .then((response: AxiosResponse<ISearchResponse>) => response.data)
    .catch((error) => Promise.reject(error));
};

const exportSearch = (searchParameters: ISearchRequestParams): Promise<ISearchResponse> => api
  .post(
    'mundo/search/export?licenseMode=EDITOR_UPLOAD',
    {...searchParameters}
  )
  .then((response: AxiosResponse<ISearchResponse>) => response.data)
  .catch((error) => Promise.reject(error));

const bubble = (): Promise<IBubbleResponse> => api
  .get('mundo/bubble')
  .then((response: AxiosResponse<IBubbleResponse>) => response.data)
  .catch((error) => Promise.reject(error));

const getRecommendedList = (): Promise<ISearchResponse> => api
  .get('mundo/recommended')
  .then((response: AxiosResponse<Promise<ISearchResponse>>) => response.data)
  .catch((error) => Promise.reject(error));

const reportError = (data: IReportError): Promise<any> => api
  .post(
    'mundo/send-email-report-media',
    data
  )
  .then((response: AxiosResponse<Promise<any>>) => response.data)
  .catch((error) => Promise.reject(error));

const sendContactForm = (data: ISendContactForm): Promise<any> => api
  .post(
    'mundo/send-email-contact-form',
    data
  )
  .then((response: AxiosResponse<Promise<any>>) => response.data)
  .catch((error) => Promise.reject(error));

const receiveSearchTotalElements = (): Promise<number> => api
  .get('mundo/search/total')
  .then((response: AxiosResponse<Promise<number>>) => response.data)
  .catch((error) => Promise.reject(error));

const SearchApiService: ISearchApiService = {
  suggest,
  search,
  exportSearch,
  bubble,
  getRecommendedList,
  reportError,
  sendContactForm,
  receiveSearchTotalElements,
  getUserApprovedMediaList
};

export default SearchApiService;
