import { ActionTree, GetterTree } from 'vuex';
import { getField, updateField } from 'vuex-map-fields';
import { RootState } from '@/store';
import { IFeature } from '@/interfaces/interfaces';
import FeaturesApiService from '@/services/api/features.service';

export interface IFeaturesState {
  features: IFeature[];
  featuresLoaded: boolean;
}

const initialState = (): IFeaturesState => ({
  features: [],
  featuresLoaded: false
});

const state = initialState();

const getters: GetterTree<IFeaturesState, RootState> = {
  featureEnabled: (state: IFeaturesState) => (feature: IFeature) => state.features.includes(feature),
  getFeaturesField: (state: IFeaturesState) => getField(state)
};

const mutations = {
  ['SET_FEATURES'](state: IFeaturesState, payload: IFeature[]) {
    state.features = payload;
  },
  ['SET_FEATURES_LOADED'](state: IFeaturesState, payload: boolean) {
    state.featuresLoaded = payload;
  },
  updateFeaturesField(state: IFeaturesState, field: string) {
    return updateField(state, field);
  }
};

const actions: ActionTree<IFeaturesState, RootState> = {
  setFeatures({commit}) {
    commit('SET_FEATURES_LOADED', true);
    commit('SET_FEATURES', []);
    return FeaturesApiService.getFeaturesState()
      .then((data) => {
        const enabledFeatures = Object.keys(data.features)
          .reduce((acc, curr) => 
            data.features[curr as IFeature]
              ? [...acc, curr as IFeature]
              : acc, 
            [] as IFeature[]
          );
        commit('SET_FEATURES', enabledFeatures);
      });
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
