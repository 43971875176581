import { ActionTree, GetterTree } from 'vuex';
import { getField, updateField } from 'vuex-map-fields';
import { RootState } from '@/store';
import UploadApiService from '@/services/api/upload.service';

export interface IUploadState {
  userRecordFileUploadProgress: number;
  userRecordFileUploadAbortController: AbortController | null;
  userRecordFileUploaderVisibility: boolean;
}

const initialState = (): IUploadState => ({
  userRecordFileUploadProgress: 0,
  userRecordFileUploadAbortController: null,
  userRecordFileUploaderVisibility: false
});

const state = initialState();

const getters: GetterTree<IUploadState, RootState> = {
  userRecordFileUploadProcess: (state: IUploadState) => state.userRecordFileUploadProgress !== 0
    && state.userRecordFileUploadProgress !== 100,
  userRecordFileUploadProgress: (state: IUploadState) => state.userRecordFileUploadProgress,
  userRecordFileUploaderVisibility: (state: IUploadState) => state.userRecordFileUploaderVisibility,
  getUploadField: (state: IUploadState) => getField(state)
};

const mutations = {
  ['SET_USER_RECORD_FILE_UPLOAD_PROGRESS'](state: IUploadState, payload: number) {
    state.userRecordFileUploadProgress = payload;
  },
  ['SET_USER_RECORD_FILE_UPLOAD_ABORT_CONTROLLER'](state: IUploadState, payload: AbortController | null) {
    state.userRecordFileUploadAbortController = payload;
  },
  ['SET_USER_RECORD_FILE_UPLOADER_VISIBILITY'](state: IUploadState, payload: boolean) {
    state.userRecordFileUploaderVisibility = payload;
  },
  updateFeaturesField(state: IUploadState, field: string) {
    return updateField(state, field);
  }
};

const actions: ActionTree<IUploadState, RootState> = {
  uploadUserRecordFile({commit}, file: File) {
    const formdata = new FormData();
    formdata.append('file', file);
    const controller = new AbortController();
    commit('SET_USER_RECORD_FILE_UPLOAD_ABORT_CONTROLLER', controller);
    return UploadApiService.uploadUserRecordFile(formdata, true, controller)
      .then((fileData) => {
        commit('SET_USER_RECORD_FILE_UPLOAD_PROGRESS', 100);
        return fileData;
      });
  },
  cancelUploadRecordFile({state, commit}) {
    state.userRecordFileUploadAbortController!.abort();
    commit('SET_USER_RECORD_FILE_UPLOAD_PROGRESS', 0);
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
