import { BookmarkColor, IMedia } from '@/interfaces/interfaces';
import {api} from '@/services/api/api.service';
import { AxiosResponse } from 'axios';

export interface IBookmark {
  author: {
    firstName: string,
    lastName: string,
    externalId: string;
    pictureId?: string;
  };
  createdAt: string;
  id: string;
  medias: IMedia[];
  name: string;
  description: string;
  color: keyof typeof BookmarkColor;
  mediaCount?: number;
  userDefault?: boolean;
  containsMedia?: boolean;
  shareId?: string;
}

export interface IBookmarksResponse {
  bookmarks: IBookmark[];
}

export interface IBookmarksContainsResponse {
  containsResult: IBookmark[];
}

export interface ISetBookmarksMediaParams {
  mediaId: string;
  bookmarksIds: string[];
  newBookmarkName?: string;
}

interface IBookmarkApiService {
  getBookmarks: (queryParams?: string) => Promise<IBookmarksResponse>;
  setBookmark: (bookmark: IBookmark) => Promise<IBookmark>;
  removeBookmark: (bookmarkId: string) => Promise<null>;
  getBookmarksContainsMedia: (mediaId: string) => Promise<IBookmarksContainsResponse>;
  addMediaToBookmarks: (params: ISetBookmarksMediaParams) => Promise<IBookmarksResponse>;
  removeMediaFromBookmarks: (params: ISetBookmarksMediaParams) => Promise<IBookmarksResponse>;
  shareBookmark: (bookmarkId: string) => Promise<IBookmark>;
  getSharedBookmark: (shareId: string) => Promise<IBookmark>;
}

const getBookmarks = (queryParams?: string): Promise<IBookmarksResponse> => api
  .get(`mundo/bookmark?${queryParams}`)
  .then((response: AxiosResponse<IBookmarksResponse>) => response.data)
  .catch((error) => Promise.reject(error));

const setBookmark = (bookmark: IBookmark): Promise<IBookmark> => api
  .post('mundo/bookmark', bookmark)
  .then((response: AxiosResponse<IBookmark>) => response.data)
  .catch((error) => Promise.reject(error));

const removeBookmark = (bookmarkId: string): Promise<null> => api
  .delete(`mundo/bookmark/${bookmarkId}`)
  .then((response: AxiosResponse<null>) => response.data)
  .catch((error) => Promise.reject(error));

const getBookmarksContainsMedia = (mediaId: string): Promise<IBookmarksContainsResponse> => api
  .get(`mundo/bookmark/contains?mediaId=${mediaId}`)
  .then((response: AxiosResponse<IBookmarksContainsResponse>) => response.data)
  .catch((error) => Promise.reject(error));

const addMediaToBookmarks = (params: ISetBookmarksMediaParams): Promise<IBookmarksResponse> => api
  .post('mundo/bookmark/add', params)
  .then((response: AxiosResponse<IBookmarksResponse>) => response.data)
  .catch((error) => Promise.reject(error));

const removeMediaFromBookmarks = (params: ISetBookmarksMediaParams): Promise<IBookmarksResponse> => api
  .post('mundo/bookmark/remove', params)
  .then((response: AxiosResponse<IBookmarksResponse>) => response.data)
  .catch((error) => Promise.reject(error));

const shareBookmark = (bookmarkId: string): Promise<IBookmark> => api
  .post(`mundo/bookmark/${bookmarkId}/share`)
  .then((response: AxiosResponse<IBookmark>) => response.data)
  .catch((error) => Promise.reject(error));

const getSharedBookmark = (shareId: string): Promise<IBookmark> => api
  .get(`mundo/bookmark/shared/${shareId}`)
  .then((response: AxiosResponse<IBookmark>) => response.data)
  .catch((error) => Promise.reject(error));

const BookmarkApiService: IBookmarkApiService = {
  getBookmarks,
  getBookmarksContainsMedia,
  setBookmark,
  removeBookmark,
  addMediaToBookmarks,
  removeMediaFromBookmarks,
  shareBookmark,
  getSharedBookmark
};

export default BookmarkApiService;
