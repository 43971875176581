























import { Vue, Component } from 'vue-property-decorator';
import { IDictionary } from '@/interfaces/interfaces';
import MenuIcon from '@/assets/icons/menu.svg';
import Menu from '@/components/UI/Menu.vue';

@Component({
  components: { Menu }
})
export default class HeaderMenu extends Vue {
  private MenuIcon = MenuIcon;

  private listItem: Array<IDictionary<string>> = [
    {
      title: 'Startseite',
      path: '/',
    },
    {
      title: 'Suche',
      path: '/search',
    },
    {
      title: 'Über das Projekt',
      path: '/about',
    },
    {
      title: 'Über unsere Quellen',
      path: '/publishers',
    },
    {
      title: 'Medium vorschlagen',
      path: '/suggest',
    },
    {
      title: 'Hilfe / FAQs',
      path: '/faq',
      className: 'secondary',
    },
    {
      title: 'LTI-Schnittstelle',
      path: '/cms/lti',
      className: 'secondary',
      alt: 'Anleitung für die MUNDO-LTI-Schnittstelle'
    },
    {
      title: 'Kontakt',
      path: '/contact',
      className: 'secondary',
    },
  ];
}
