import { getField, updateField } from 'vuex-map-fields';
import { ActionTree, GetterTree } from 'vuex';
import { RootState } from '@/store';
import { IUserRecord, IUserRecordMetadataDTO, IUserRecordsData, UserRecordStatus } from '@/interfaces/interfaces';
import UserRecordApiService, { IUserRecordsDataSearchParams } from '@/services/api/user-record.service';
import router from '@/router';
import queryString from 'query-string';
import { isObjectsEqual } from '@/utilities';
import EducationalDependenciesService, { IEducationalDependenciesPayload } from '@/services/dependencies/educational.service';
import LicenseDependenciesService, { ILicenseDependenciesData } from '@/services/dependencies/license.service';

export interface IUserRecordState {
  record: IUserRecord;
  recordsData: IUserRecordsData | null;
  initialMetadata: IUserRecordMetadataDTO | null;
  recordsDataSearchParams: IUserRecordsDataSearchParams;
  licenseDepsData: ILicenseDependenciesData;
  educationalData: IEducationalDependenciesPayload;
  educationalDepsData: IEducationalDependenciesPayload;
}

export enum UserRecordMetadataDTORequiredFields { 
  title,
  description,
  thumbnail,
  media,
  learningResourceTypes,
  educationalLevels
}

const defaultUserRecordsDataSearchParams = (): IUserRecordsDataSearchParams => ({
  size: '23',
  page: '1',
  title: ''
});

const defaultUserRecord = (): IUserRecord => ({
  mediaType: '',
  metadata: {
    title: '',
    thumbnail: '',
    media: '',
    mediaStatus: 'CREATED',
    mediaFileStatus: 'NEW_UPLOAD',
    learningResourceTypes: [],
    educationalLevels: [],
    typeOfSchools: [],
    classLevel: [],
    disciplines: [],
    license: {
      useRights: '',
      ccVersion: '',
      ccCountry: ''
    },
    keywords: [],
    author: []
  }
});

const defaultLicenseDepsData = (): ILicenseDependenciesData => ({
  useRights: [],
  ccVersion: [],
  ccCountry: []
});

const defaultEducationaData = (): IEducationalDependenciesPayload => ({
  educationalLevels: [],
  typeOfSchools: [],
  classLevel: []
});

const initialState = (): IUserRecordState => ({
  record: defaultUserRecord(),
  initialMetadata: defaultUserRecord().metadata!,
  recordsData: null,
  recordsDataSearchParams: defaultUserRecordsDataSearchParams(),
  licenseDepsData: defaultLicenseDepsData(),
  educationalData: defaultEducationaData(),
  educationalDepsData: defaultEducationaData()
});

const state = initialState();

const getters: GetterTree<IUserRecordState, RootState> = {
  userRecordMediaType: (state: IUserRecordState) => state.record?.mediaType,
  isUserRecordMetadataChanged: (state: IUserRecordState) => !isObjectsEqual(state.record!.metadata!, state.initialMetadata!),
  userRecordMetaTitle: (state: IUserRecordState) => state.record.id || state.record.mediaType
    ? state.record.status === UserRecordStatus.SUBMITTED
      ? 'Einreichung des Bildungsmedium abgeschlossen - MUNDO'
      : 'Eigene Bildungsmedien einreichen und beschreiben - MUNDO'
    : 'Eigene Bildungsmedien einreichen - MUNDO',
  userRecordMetaDescription: (state: IUserRecordState) => state.record.id || state.record.mediaType
    ? state.record.status === UserRecordStatus.SUBMITTED
      ? 'Erfolgreich eingereicht: Dein Bildungsmedium wurde zur Prüfung an die Redaktion übermittelt.'
      : 'Schulfach, Klassenstufe, Schulart: Hilf uns dein Bildungsmedium einzuordnen und beschreibe dein Unterrichtsmaterial.'
    : 'Jetzt eigene Bildungsmedien über den Upload einreichen und anderen Nutzern zur Verfügung stellen.',
  getUserRecordField: (state: IUserRecordState) => getField(state)
};

const mutations = {
  ['SET_USER_RECORD'](state: IUserRecordState, payload: IUserRecord) {
    state.record = payload;
  },
  ['SET_USER_INITIAL_METADATA'](state: IUserRecordState, payload: IUserRecordMetadataDTO) {
    state.initialMetadata = payload;
  },
  ['RESET_USER_RECORD'](state: IUserRecordState) {
    state.record = defaultUserRecord();
    state.initialMetadata = defaultUserRecord().metadata!;
  },
  ['SET_USER_RECORD_METADATA'](state: IUserRecordState, payload: IUserRecordMetadataDTO) {
    state.record.metadata = payload;
  },
  ['SET_USER_RECORD_MEDIATYPE'](state: IUserRecordState, payload: string) {
    state.record = { ...state.record, mediaType: payload };
  },
  ['SET_USER_RECORDS_DATA'](state: IUserRecordState, payload: IUserRecordsData) {
    state.recordsData = payload;
  },
  ['SET_USER_RECORDS_DATA_SEARCH_PARAMS'](state: IUserRecordState, payload: IUserRecordsDataSearchParams) {
    state.recordsDataSearchParams = payload;
  },
  ['SET_USER_RECORD_LICENSE_DEPS'](state: IUserRecordState, payload: ILicenseDependenciesData) {
    state.licenseDepsData = payload;
  },
  ['SET_USER_RECORD_EDUCATIONAL_DATA'](state: IUserRecordState, payload: IEducationalDependenciesPayload) {
    state.educationalData = payload;
  },
  ['SET_USER_RECORD_EDUCATIONAL_DEPS'](state: IUserRecordState, payload: IEducationalDependenciesPayload) {
    state.educationalDepsData = payload;
  },
  ['SET_USER_RECORD_METADATA_AUTHOR'](state: IUserRecordState, payload: string[]) {
    state.record.metadata!.author = payload;
  },

  updateRecordField(state: IUserRecordState, field: string) {
    return updateField(state, field);
  }
};

const actions: ActionTree<IUserRecordState, RootState> = {
  // Records data

  getUserRecordsData({commit}) {
    commit('SET_LOADING', {userRecord: true});

    const { size, page, title } = state.recordsDataSearchParams;
    const query = queryString.stringify(
      {
        size, 
        page: Number(page) - 1
      }
    );
    return UserRecordApiService.getUserRecordsData(
      query ? `?${query}` : '',
      title ? { title } : {}
    )
      .then((data) => {
        commit('SET_USER_RECORDS_DATA', data);
      })
      .finally(() => {
        commit('SET_LOADING', {userRecord: false});
      });
  },
  parseUserRecordsDataQueryParams({commit, dispatch}, query: Record<string, string>) {
    const {
      size, 
      page,
      title
    } = query;

    commit('SET_USER_RECORDS_DATA_SEARCH_PARAMS', {
      size: size 
        ? size 
        : defaultUserRecordsDataSearchParams().size,
      page: page 
        ? page 
        : defaultUserRecordsDataSearchParams().page,
      title
    });

    return dispatch('getUserRecordsData');
  },
  pushUserRecordsDataQueryParams(_ctx, params: IUserRecordsDataSearchParams) {
    const {
      size, 
      page,
      title
    } = params;

    const query = {
      size: size && size !== defaultUserRecordsDataSearchParams().size
        ? size 
        : undefined,
      page: page && page !== defaultUserRecordsDataSearchParams().page
        ? page 
        : undefined,
      title: title 
        ? title 
        : undefined
    };

    return router.push({query});
  },

  // Single record

  getUserRecordById({commit, dispatch}, id: string) {
    commit('SET_LOADING', {userRecord: true});

    return UserRecordApiService.getUserRecordById(id)
      .then((data) => {
        const record = {...data, metadata: { id: data.id, ...data.metadata }};
        commit('SET_USER_RECORD', record);
        commit('SET_USER_INITIAL_METADATA', record.metadata);
        return dispatch('resolveUserRecordMetadataDeps');
      })
      .finally(() => {
        commit('SET_LOADING', {userRecord: false});
      });
  },
  saveUserRecord({state, commit, dispatch}) {
    commit('SET_LOADING', {userRecord: true});
    return UserRecordApiService.saveUserRecord(state.record)
      .then((data) => {
        commit('SET_USER_RECORD', data);
        commit('SET_USER_INITIAL_METADATA', data.metadata);
        return dispatch('resolveUserRecordMetadataDeps')
          .then(() => router.push({name: 'user-record-edit', params: {id: data.id!}}));
      })
      .finally(() => {
        commit('SET_LOADING', {userRecord: false});
      });
  },
  silentSaveUserRecord({state}) {
    return UserRecordApiService.saveUserRecord(state.record)
      .then((data) => data);
  },
  submitUserRecord({commit, dispatch}, id: string) {
    commit('SET_LOADING', {userRecord: true});
    return UserRecordApiService.submitUserRecord(id)
      .then((data) => {
        commit('SET_USER_RECORD', data);
        commit('SET_USER_INITIAL_METADATA', data.metadata);
        return dispatch('resolveUserRecordMetadataDeps')
          .then(() => router.push({name: 'user-record-edit', params: {id: data.id!}}));
      })
      .finally(() => {
        commit('SET_LOADING', {userRecord: false});
      });
  },
  removeUserRecord({state, commit}) {
    commit('SET_LOADING', {userRecord: true});
    return UserRecordApiService.removeUserRecord(state.record.id!)
      .then(() => {
        return router.push({name: 'user-records'});
      })
      .finally(() => {
        commit('SET_LOADING', {userRecord: false});
      });
  },
  updateUserRecord({state, commit, dispatch}) {
    commit('SET_LOADING', {userRecord: true});
    return UserRecordApiService.updateUserRecord(state.record.id!)
      .then((data) => {
        commit('SET_USER_RECORD', data);
        commit('SET_USER_INITIAL_METADATA', data.metadata);
        dispatch('resolveUserRecordMetadataDeps');
      })
      .finally(() => {
        commit('SET_LOADING', {userRecord: false});
      });
  },

  // Dependencies

  resolveLicenseDeps: ({state, commit, rootState}) => {
    const payload = state.record.metadata!.license! || { useRights: '', ccVersion: '', ccCountry: '' };
    const deps = LicenseDependenciesService.getAvailableData(
      rootState.dictionary.metadataLicenseDictionary,
      payload
    );
    commit('SET_USER_RECORD_LICENSE_DEPS', deps);
    const updatedData = LicenseDependenciesService.getUpdatedData(payload, deps);
    commit('SET_USER_RECORD_METADATA', {
      ...state.record.metadata,
      license: {
        useRights: updatedData.useRights,
        ccVersion: !deps.ccVersion.includes('') ? '4.0' : '',
        ccCountry: ''
      }
    });
  },

  resolveEducationalDeps({state, commit, rootState}) {
    const { educationalLevels, typeOfSchools, classLevel } = state.record.metadata!;
    const deps = EducationalDependenciesService.getAvailableData(
      rootState.dictionary.metadataEducationalDictionary,
      { educationalLevels, typeOfSchools, classLevel }
    );
    const updatedData = EducationalDependenciesService.getUpdatedData(
      { educationalLevels, typeOfSchools, classLevel }, 
      deps
    );
    commit('SET_USER_RECORD_EDUCATIONAL_DEPS', deps);
    commit('SET_USER_RECORD_METADATA', {
      ...state.record.metadata,
      ...updatedData
    });
  },

  resolveUserRecordMetadataDeps({state, commit, dispatch}) {
    return Promise.all([
      dispatch('resolveEducationalDeps'),
      dispatch('resolveLicenseDeps')
    ]).then(() => commit('SET_USER_INITIAL_METADATA', state.record.metadata));
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
