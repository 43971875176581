







import { Vue, Component, Prop } from 'vue-property-decorator';
import { UISizeType } from '@/interfaces/interfaces';

@Component({})
export default class BadgeDefault extends Vue {
  @Prop({
    type: String,
    required: false,
  })
  private size!: UISizeType;

  private get classObject(): object {
    return {
      [`${this.size}`]: !!this.size
    };
  }
}
