




















import Vue, { VueConstructor } from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { IDictionary } from '@/interfaces/interfaces';

import PlusIcon from '@/assets/icons/plus.svg';
import CrossIcon from '@/assets/icons/cross.svg';
import SearchIcon from '@/assets/icons/search.svg';
import ChevronRightIcon from '@/assets/icons/chevron-right.svg';

type Mode = 'add' | 'remove' | 'subject' | 'inverted';

const modeIconDictionary: IDictionary<VueConstructor> = {
  add: PlusIcon,
  remove: CrossIcon,
  subject: SearchIcon,
  inverted: ChevronRightIcon
};

@Component({})
export default class Chip extends Vue {
  @Prop({
    type: String,
    required: false
  })
  public variant!: 'light';

  @Prop({
    type: String,
    required: false
  })
  public mode!: Mode;

  @Prop({
    type: Number,
    required: false
  })
  public count!: number;

  @Prop({
    type: Boolean,
    required: false
  })
  public selected!: boolean;

  @Prop({
    type: Object,
    required: false
  })
  public icon!: VueConstructor;

  private get classObject() {
    return {
      ['chip-light']: this.variant === 'light',
      ['chip-subject']: this.isSubjectMode,
      ['chip-inverted']: this.isInvertedMode,
      ['chip-selected']: this.selected && !this.isSubjectMode
    };
  }

  private get isSubjectMode(): boolean {
    return this.mode === 'subject';
  }

  private get isInvertedMode(): boolean {
    return this.mode === 'inverted';
  }

  private get modeIcon(): VueConstructor {
    return modeIconDictionary[this.mode];
  }
}
