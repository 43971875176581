import { IUILayout } from '@/interfaces/interfaces';

import DefaultLayout from '@/layouts/Default.layout.vue';
import UILayout from '@/layouts/UI.layout.vue';
import FrameLayout from '@/layouts/Frame.layout.vue';

export const uiLayoutsDictionary: IUILayout[] = [
  {
    name: 'default',
    component: DefaultLayout
  },
  {
    name: 'ui',
    component: UILayout
  },
  {
    name: 'frame',
    component: FrameLayout
  }
];
