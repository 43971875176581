import { ActionTree, GetterTree } from 'vuex';
import { RootState } from '@/store';
import SliderApiService from '@/services/api/slider.service';
import { ISearchResponse } from '@/services/api/search.service';
import { IMedia } from '@/interfaces/interfaces';
import {ICmsRecommendedMedia} from '@/store/modules/cms/cms.store';

export interface ISliderState {
  recommendedList: IMedia[];
}

const initialState = (): ISliderState => ({
  recommendedList: [],
});

const state = initialState();

const getters: GetterTree<ISliderState, RootState> = {
  getRecommendedList: (state: ISliderState) => state.recommendedList.length
    ? state.recommendedList
    : [],
};

const mutations = {
  ['SET_RECOMMENDED_LIST'](state: ISliderState, payload: IMedia[]) {
    state.recommendedList = payload;
  },
};

const actions: ActionTree<ISliderState, RootState> = {
  getRecommendedList: ({commit}, payload: ICmsRecommendedMedia[]) => {
    commit('SET_LOADING', {recommendedList: true});
    return SliderApiService.getRecommendedList(payload.map((item) => item.identifier))
      .then((data: ISearchResponse) => {
        commit('SET_RECOMMENDED_LIST',
          payload
            .map(item => data.tiles.find(tile => item.identifier === tile.identifier))
            .filter(Boolean)
            .map(tile => ({
              ...tile,
              order: payload.find(item => item.identifier === tile?.identifier)?.order ?? 0
            }))
            .sort(({order: orderA = 0}, {order: orderB = 0}) => orderA - orderB)
        );
      })
      .catch((error) => Promise.reject(error))
      .finally(() => commit('SET_LOADING', {recommendedList: false}));
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
