import { AbilityAction, AbilitySubject, IRouterGuard } from '@/interfaces/interfaces';
import store from '@/store';
import { RawLocation } from 'vue-router';

export interface IRouteAbilityMeta { 
  action: AbilityAction;
  subject: AbilitySubject;
  redirect?: RawLocation;
}

export const abilityGuard = (params: IRouterGuard) => {
  const {from, to, next, nextGuard} = params;
  if (to.meta.ability) {
    const { action, subject, redirect = {name: 'main'} } = to.meta.ability as IRouteAbilityMeta;

    if (!store.getters.ability.can(action, subject)) {
      return next(redirect);
    }
  }
  return nextGuard ? nextGuard({from, to, next}) : next();
};
