import store from '@/store';
import { IRouterGuard } from '@/interfaces/interfaces';

export const viewModeGuard = (params: IRouterGuard) => {
  const {from, to, next, nextGuard} = params;

  const ltiModeRoutes = ['lti-search', 'lti-details', 'lti-media'];
  if (store.getters.isLtiViewMode && !ltiModeRoutes.includes(to.name as string)) {
    return next({name: 'lti-search'});
  }

  const exportModeRoutes = ['export-search', 'export-details'];
  if (store.getters.isExportViewMode && !exportModeRoutes.includes(to.name as string)) {
    return next({name: 'export-search'});
  }

  return nextGuard ? nextGuard({from, to, next}) : next();
};
