import { ActionTree } from 'vuex';
import { RootState } from '@/store';
import PlayoutApiService from '@/services/api/playout.service';

export interface IPlayoutState {
  url: string;
}

const actions: ActionTree<IPlayoutState, RootState> = {
  getPlayout(_, id: string) {
    return PlayoutApiService.getPlayout(id);
  },
};
export default {
  actions
};
