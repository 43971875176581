import { ActionTree, GetterTree } from 'vuex';
import { RootState } from '@/store';
import CmsApiService from '@/services/api/cms.service';
import router from '@/router';

export interface ICmsStaticPage {
  _id: string; // mongo id
  id: string; // cms id
  identifier: string; // identifier of cms page
  text: string;
  published_at: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
  metaTitle: string;
}

export interface ICmsStaticPagesState {
  staticPage: ICmsStaticPage | null;
}

const initialState = (): ICmsStaticPagesState => ({
  staticPage: null
});

const state = initialState();

const getters: GetterTree<ICmsStaticPagesState, RootState> = {
  getCmsStaticPage: (state: ICmsStaticPagesState) => state.staticPage || null
};

const mutations = {
  ['SET_STATIC_PAGE'](state: ICmsStaticPagesState, payload: ICmsStaticPage) {
    state.staticPage = payload;
  },
};

const actions: ActionTree<ICmsStaticPagesState, RootState> = {
  getCmsStaticPage: ({commit}, payload: string) => {
    commit('SET_LOADING', {staticPage: true});
    return CmsApiService.getCmsStaticPage(payload)
      .then((data: ICmsStaticPage[]) => {
        if (data.length) {
          commit('SET_STATIC_PAGE', data[0]);
          return Promise.resolve();
        }
        return router.push({name: 'main'}).catch((err) => (err));
      })
      .finally(() => commit('SET_LOADING', {staticPage: false}));
  },
};

export default {
  state,
  getters,
  mutations,
  actions
};
