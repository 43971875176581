
































































import { Vue, Component, Prop } from 'vue-property-decorator';
import ChevronRightIcon from '@/assets/icons/chevron-right.svg';
import EmbedIcon from '@/assets/icons/embed.svg';
import AddIcon from '@/assets/icons/add.svg';
import ShareIcon from '@/assets/icons/share.svg';

@Component({})
export default class CardActive extends Vue {
  private ChevronRightIcon = ChevronRightIcon;
  private EmbedIcon = EmbedIcon;
  private ShareIcon = ShareIcon;
  private AddIcon = AddIcon;

  @Prop({
    type: Boolean,
    required: false
  })
  private inline!: boolean;

  @Prop( {
    type: String,
    required: false
  })
  private mode!: 'collection';

  @Prop({
    type: Boolean,
    required: false
  })
  private visible!: boolean;

  @Prop({
    type: Boolean,
    required: false
  })
  private focus!: boolean;

  @Prop({
    type: Boolean,
    required: false
  })
  private statused!: boolean;

  @Prop({
    type: Boolean,
    required: false
  })
  private coverStripped!: boolean;

  @Prop({
    type: Object,
    required: false
  })
  private item!: Record<string, any>;

  @Prop({
    type: String,
    required: false
  })
  private identifier!: string;

  @Prop({
    type: String,
    required: false,
    default: 'primary'
  })
  private activeVariant!: string;

  private get classObject(): object {
    return {
      'visible': this.visible || this.focus,
      'in-process': this.coverStripped,
      'card-active-inline': this.inline,
      [`card-active-${this.mode}`]: !!this.mode,
    };
  }
}
