import { IFeaturesResponse } from '@/interfaces/interfaces';
import { api } from '@/services/api/api.service';
import { AxiosResponse } from 'axios';

export interface IFeaturesApiService {
  getFeaturesState: () => Promise<IFeaturesResponse>;
}

const getFeaturesState = (): Promise<IFeaturesResponse> => api
  .get('mundo/feature')
  .then((response: AxiosResponse<IFeaturesResponse>) => response.data)
  .catch((error) => Promise.reject(error));

const FeaturesApiService: IFeaturesApiService = {
  getFeaturesState,
};

export default FeaturesApiService;
