










import { Vue, Component, Prop } from 'vue-property-decorator';
import { UISizeType } from '@/interfaces/interfaces';

@Component({
  inheritAttrs: false
})

export default class Textarea extends Vue {
  @Prop({
    type: String,
    required: false
  })
  private variant!: 'light';

  @Prop({
    type: String,
    required: false
  })
  private size!: UISizeType;

  @Prop({
    type: Number,
    required: false,
  })
  private height!: number;

  @Prop({
    type: Number,
    required: false,
    default: 30000
  })
  private maxLength!: number;

  private get classObject(): object {
    return {
      [`textarea-${this.variant}`]: !!this.variant,
      [`textarea-${this.size}`]: !!this.size,
      'is-invalid': (this.$attrs.state != null && !this.$attrs.state),
    };
  }

  private get styleObject(): object {
    return {
      '--height': this.height ? `${this.height}px` : '80px',
    };
  }
}
