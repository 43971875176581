




import { Vue, Component } from 'vue-property-decorator';

@Component
export default class Overlay extends Vue {
  private get classObject(): object {
    return {
      active: this.$store.getters.isOverlay[0],
      priority: this.$store.getters.isOverlay[1],
    };
  }
}
