










import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class CardCollectionCover extends Vue {
  @Prop({
    type: Array,
    required: false
  })
  private coverList!: string[];

  @Prop({
    type: Boolean,
    required: false
  })
  private inline!: boolean;

  private get classObject(): object {
    return {
      'cover-inline': this.inline
    };
  }
}
