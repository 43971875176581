
export const decodeNumberParameter = <T>(value: undefined | string, fallback: T): T | number => {
  if (typeof value === 'undefined') {
    return fallback;
  }
  if (isNaN(+value)) {
    return fallback;
  }
  return +value;
};
